import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../state";
import NumberFormat from "react-number-format";
import useAuditSaveSearch from "../../hooks/api/useAuditSaveSearch";
import { EnumPlanType, EnumSearchType } from "../../contracts/types";
import { dispatchDataLayer } from "../../helpers/fnGoogleTag";

interface ITabVoiceProps {}

const TabVoice: React.FC<ITabVoiceProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");
  const [isGoSearch, setIsGoSearch] = useState(false);
  const [numberOfSeats, setNumberOfSeats] = useState(
    iPlanFilterParams.voiceParams.filterNumber
  );

  const { data: dataSearch } = useAuditSaveSearch();

  useEffect(() => {
    if (dataSearch && isGoSearch) {
      window.open(iPlanFilterParams.baseUrl + "search", "_self");
    }
  }, [dataSearch]);

  const onClickSearchVoice = (e: any) => {
    if (!window.location.pathname.includes("search")) {
      setIAuditVisitor({
        ...iAuditVisitor,
        search: {
          ...iAuditVisitor.search,
          id: -1,
          visitorId: iAuditVisitor.id,
          type: EnumSearchType.HOME,
          planTypeCode: iFilters.planTypeCode,
          address: "",
          stateAbbreviation: "",
          city: "",
          zipCode: "",
          seats: iPlanFilterParams.voiceParams.filterNumber,
        },
      });

      const objDatLayer = {
        event: "search_BVP",
        Seats: numberOfSeats.toString(),
      };
      dispatchDataLayer(objDatLayer);
      setIsGoSearch(true);
    }

    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        filterNumber: numberOfSeats,
      },
    });
  };

  const onValueChangeVoiceNumber = (values: any) => {
    const { value: valueNumber } = values;

    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        filterNumber: valueNumber,
      },
    });
  };

  const onClickNumberSeats = (value: number) => {
    let numberOS =
      parseInt(numberOfSeats.toString()) + parseInt(value.toString());

    if (numberOS <= 0) {
      numberOS = 1;
    }

    setNumberOfSeats(numberOS);

    if (window.location.pathname.includes("search")) {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        voiceParams: {
          ...iPlanFilterParams.voiceParams,
          filterNumber: numberOS,
        },
      });
    }
  };

  return (
    <div
      className={`tab-pane fade ${
        iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE &&
        "show active"
      }`}
      id=""
      role="tabpanel"
      aria-labelledby="searchcontrol-bp-tab searchcontrol-bp"
    >
      <div className="row ">
        <div className="col-md-4">
          <p className="m-numberseats">How Many Seats</p>
          <p className="m-numberseats-tagline">
            (Number of people needing an IP phone or needing a unified
            communications application on a device)
          </p>
        </div>
        <div className="col-md-4 voice-phone-seats">
          <div className="bp-sectioninput row">
            <div className="col-4">
              <button
                className="bp-input"
                onClick={() => onClickNumberSeats(-1)}
              >
                <i className="fa fa-minus" aria-hidden="true"></i>
              </button>
            </div>
            <div className="col-4 bp-borderinput">
              <NumberFormat
                name="numberseats"
                className="bp-input bp-inputspace"
                thousandSeparator={true}
                value={numberOfSeats}
                onValueChange={onValueChangeVoiceNumber}
                decimalScale={0}
                allowNegative={false}
                maxLength={5}
              />
            </div>
            <div className="col-4">
              <button
                className="bp-input"
                onClick={() => onClickNumberSeats(1)}
              >
                <i className="fa fa-plus " aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 phone-voice-block right-alignment">
          <button
            type="button"
            className="btn btn-lg searchButton"
            onClick={onClickSearchVoice}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default TabVoice;
