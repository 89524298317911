import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IAuditVisitorSearchCompare } from "../../contracts/types";
import { useGlobalState } from "../../state";

export default function useAuditSaveCompare() {
	const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');
	const api = useServiceApi();

	const doRequest = async (): Promise<IAuditVisitorSearchCompare> => {
		const compare = iAuditVisitor.searchCompare;
		const arrayCompares = iAuditVisitor.search.compares;
		
		const { data } = await api.post<IAuditVisitorSearchCompare>(`${ApiEndPoints.GetAuditSaveCompare}`, compare);

		arrayCompares.push(data);

		setIAuditVisitor(
			{
				...iAuditVisitor,
				"searchCompare": {
					...iAuditVisitor.searchCompare,
					"id": -1,
					"visitorSearchId": -1,
					"learnMore": 0,
					"remove": false
				},
				"search": {
					...iAuditVisitor.search,
					"compares": arrayCompares
				},
			});

		return data;
	};

	return useQuery<IAuditVisitorSearchCompare>(
		['saveAuditCompare'],
		() => doRequest(), {
			enabled: (iAuditVisitor.searchCompare.id === -1 && iAuditVisitor.searchCompare.visitorSearchId !== -1) ||
				(iAuditVisitor.searchCompare.id !== -1 && iAuditVisitor.searchCompare.visitorSearchId !== -1 && iAuditVisitor.searchCompare.remove) ||
				(iAuditVisitor.searchCompare.id !== -1 && iAuditVisitor.searchCompare.visitorSearchId !== -1 && iAuditVisitor.searchCompare.learnMore>0)
		}
	);
}
