import { useServiceApi, ApiEndPoints } from "../../api"
import { ICredentialsRequesDTO } from "../../contracts/types";

export default function useValidCredentials(user: string, password: string) {

	const api = useServiceApi();

	const sendSurveyResponse = async () => {
		const requestCredentials: ICredentialsRequesDTO = {
			"user": user,
			"password": password
		};

		const { data } = await api.post<Boolean>(`${ApiEndPoints.GetValidCredentials}`, requestCredentials);

		return data;
	};

	return sendSurveyResponse;
}
