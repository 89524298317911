export const ApiEndPoints = {
	GetPlans: "api/Plan",
	GetPlansInternet: "api/PlansInternet",
	GetPlansInternetADI: "api/PlansInternetADI",
	GetPlansInternetIPBB: "api/PlansInternetIPBB",
	GetPlansInternetAST: "api/PlansInternetAST",
	GetPlansVoice: "api/PlansVoice",
	GetPlansBundle: "api/PlansBundle",
	GetEquipmentVoice: "api/GetEquipmentVoice",
	GetProductProperties: "api/GetProductProperties",
	GetSubProducts: "api/GetSubProducts",
	GetPlanProperties: "api/GetPlanProperties",
	GetPlanSubProducts: "api/GetPlanSubProducts",
	GetFeatureDetails: "api/GetFeatureDetails",
	GetSurvey: "api/GetSurvey",
	CreateSurvey: "api/CreateSurvey",
	SetSurveyResponse: "api/SetSurveyResponse",
	GetSearchOrder: "api/GetSearchOrder",
	GetSearchSuggestionsSM: "https://us-autocomplete-pro.api.smartystreets.com/lookup?=&auth-id=99690654801299701&_=1611082217",
	GetSearchSuggestionDetailSM: "https://us-street.api.smartystreets.com/street-address?key=99690654801299701",
	GetParameters: "api/GetParameters",
	GetTbiOrders: "api/GetTbiOrders",
	GetTbiHttpMessage: "api/GetTbiHttpMessage",
	GetValidCredentials: "api/ValidCredentials",
	GetAccountForgotPassword: "api/ForgotPassword",
	GetAccountUserRegister: "api/Create",
	GetAccountLogin: "connect/token",
	GetAuditIp: "https://api.ipgeolocation.io/ipgeo?apiKey=88675b7e78614ea1b5612bf4361b0057",
	GetAuditSaveVisitor: "api/SaveVisitor",
	GetAuditSaveSearch: "api/SaveSearch",
	GetAuditSaveFilter: "api/SaveFilter",
	GetAuditSaveSortBy: "api/SaveSortBy",
	GetAuditSaveCompare: "api/SaveCompare",
	GetAuditSaveFeature: "api/SaveFeature",
	GetAuditSaveEquipment: "api/SaveEquipment",
	GetAuditDownloadReport: "api/DownloadReport",
	GetCalculatorSend: "api/GetCalculatorSend",
	GetCalculatorSendOrder: "api/GetCalculatorSendOrder",
	GetCarriersData: "api/GetCarriersData",
	SendOrderClickToDial: "api/SendOrderClickToDial",
	SaveOrder: "api/SaveOrder",
	SendDataTBIClickToDial: "https://api.five9.com/web2campaign/AddToList",
};
