import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IRequestCalculatorOrderDTO } from "../../contracts/types";

export default function useCalculatorSendOrder(requestSend?: IRequestCalculatorOrderDTO) {

	const api = useServiceApi();

	const doRequest = async (): Promise<boolean> => {

		const { data } = await api.post<boolean>(`${ApiEndPoints.GetCalculatorSendOrder}`, requestSend);

		return data;
	};

	return useQuery<boolean>(
		['calculatorSendOrder', requestSend?.requestInternetPlans.streetLine, requestSend?.requestInternetPlans.zipCode],
		() => doRequest(), {
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: requestSend !== undefined && requestSend.requestInternetPlans.streetLine !== ''
	});
}
