import React, { useEffect, useState } from "react";
import useGetParameters from "../../hooks/api/useGetParameters";
import { useGlobalState } from "../../state";

const FindPerfectSpeed: React.FC = () => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [urlFindPerfectSpeed, setUrlFindPerfectSpeed] = useState("");

  const { data: dataParameters } = useGetParameters();

  useEffect(() => {
    if (dataParameters) {
      setUrlFindPerfectSpeed(dataParameters.FindYourPerfectSpeed);
    }
  }, [dataParameters]);

  const onClickFindPerfectSpeed = () => {
    window.open(iPlanFilterParams.baseUrl + urlFindPerfectSpeed, "_self");
  };

  return (
    <>
      {dataParameters && urlFindPerfectSpeed !== "" && (
        <div className="container">
          {/* <div className="button-findspeed">
                        <button type="button" className="btn btn-primary shadow hide-yourperfect" onClick={onClickFindPerfectSpeed}>
                            <a href="javascript:void(0)" onClick={onClickFindPerfectSpeed}><img className="card-find-img" src="/wp-content/wiresresources/images/v2/Filters/find_you_perfect_speed.svg" alt="" /></a>
                        </button>
                    </div> */}
        </div>
      )}
    </>
  );
};

export default FindPerfectSpeed;
