import React from "react";
import {
  IOrderFeatureDTO,
  IOrderVoiceEquipmentDTO,
  ISurveySearchDTO,
} from "../../contracts/types";

interface ISummaryBodyVoiceProps {
  surverSearch: ISurveySearchDTO;
}

export const SummaryBodyVoice: React.FC<ISummaryBodyVoiceProps> = ({
  surverSearch,
}) => {
  return (
    <>
      <div className="row tableInformationOC">
        <div>
          <span>How Many Seats: </span> {surverSearch.planVoice?.numberOfSeats}
        </div>
        <table className="tableOSPlan">
          <tr>
            <th>Type</th>
            <th>Brand</th>
            <th>Attributes</th>
            <th>Payment options</th>
            <th style={{ textAlign: "right" }}>Price</th>
          </tr>
          {surverSearch.planVoice && (
            <tr>
              <td>Business Voice and Phone</td>
              <td>{surverSearch.planVoice.providerName}</td>
              <td>
                <ul>
                  <li>Term: {surverSearch.planVoice.term} Months</li>
                  <li>
                    Usage/Local LD: {surverSearch.planVoice.voiceFeatureName}
                  </li>
                  <li>
                    Chat/IM:{" "}
                    {`${surverSearch.planVoice.chatImPrice ? "Yes" : "No"}`}
                  </li>
                  <li>
                    Meet/Video:{" "}
                    {`${surverSearch.planVoice.meetVideoPrice ? "Yes" : "No"}`}
                  </li>
                </ul>
              </td>
              <td>Monthly</td>
              <td style={{ textAlign: "right" }}>
                $ {surverSearch.planVoice.calculatedPrice.toFixed(2)}
              </td>
            </tr>
          )}
        </table>
      </div>

      <div className="row tableInformationOC">
        <table className="tableOSPhone">
          <tr>
            <th>Phone</th>
            <th>Number</th>
            <th style={{ textAlign: "right" }}>Price</th>
          </tr>
          {surverSearch.equipmentsVoice &&
            surverSearch.equipmentsVoice.map((ev: IOrderVoiceEquipmentDTO) => {
              const price = ev.equipmentNumber * ev.equipmentPrice;

              return (
                <tr>
                  <td>{ev.equipmentName}</td>
                  <td>{ev.equipmentNumber}</td>
                  <td style={{ textAlign: "right" }}>$ {price.toFixed(2)}</td>
                </tr>
              );
            })}
        </table>
      </div>

      <div className="row tableInformationOC">
        <table className="tableOSFeatures">
          <tr>
            <th>Additional Options</th>
            <th>Number</th>
            <th style={{ textAlign: "right" }}>Price</th>
          </tr>
          {surverSearch &&
            surverSearch.featuresPlan &&
            surverSearch.featuresPlan.map((feature: IOrderFeatureDTO) => {
              const price = feature.featureNumber * feature.price;

              return (
                <tr>
                  <td>{feature.description}</td>
                  <td>{feature.featureNumber}</td>
                  <td style={{ textAlign: "right" }}>$ {price.toFixed(2)}</td>
                </tr>
              );
            })}
        </table>
      </div>
    </>
  );
};
