import React, { useEffect, useState } from 'react';

interface ICalculatorProgressBarProps {
    currentPage: number;
}

const CalculatorProgressBar: React.FC<ICalculatorProgressBarProps> = ({ currentPage }) => {
    const [classStart, setClassStart] = useState('cssCalProStartOff');
    const [classEnd, setClassEnd] = useState('cssCalProEndOff');
    const [classCenter2, setClassCenter2] = useState('cssCalProCenterOff');
    const [classCenter3, setClassCenter3] = useState('cssCalProCenterOff');
    const [classCenter4, setClassCenter4] = useState('cssCalProCenterOff');

    useEffect(() => {
        if (currentPage === 1) {
            setClassStart('cssCalProStartOn');
        }
        else if (currentPage === 2) {
            setClassStart('cssCalProStartOn');
            setClassCenter2('cssCalProCenterOn');
        }
        else if (currentPage === 3) {
            setClassStart('cssCalProStartOn');
            setClassCenter2('cssCalProCenterOn');
            setClassCenter3('cssCalProCenterOn');
        }
        else if (currentPage === 4) {
            setClassStart('cssCalProStartOn');
            setClassCenter2('cssCalProCenterOn');
            setClassCenter3('cssCalProCenterOn');
            setClassCenter4('cssCalProCenterOn');
        }
        else if (currentPage === 5) {
            setClassStart('cssCalProStartOn');
            setClassCenter2('cssCalProCenterOn');
            setClassCenter3('cssCalProCenterOn');
            setClassCenter4('cssCalProCenterOn');
            setClassEnd('cssCalProEndOn');
        }
    }, []);

    return <div className="cssCalculatorProgreess">
        <div className={classStart}></div>
        <div className={classCenter2}></div>
        <div className={classCenter3}></div>
        <div className={classCenter4}></div>
        <div className={classEnd}></div>
    </div>
};

export default CalculatorProgressBar;