import React, { useState } from 'react';
import Login from './login.comp';
import ForgotPassword from './forgotPassword.comp';
import RegisterUser from './registerUser.comp';
import { GoogleLogin } from 'react-google-login';
import { useGlobalState } from '../../state';

interface IAccountProps {

}

const Account: React.FC<IAccountProps> = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iSession, setISession] = useGlobalState('session');

    const [viewWindow, setViewWindow] = useState('login');
    const [tokenGoogle, setTokenGoogle] = useState('');

    const onClickViewForgot = () => {
        setViewWindow('forgot');
    };

    const onClickRegister = () => {
        setViewWindow('register');
    };

    const onClickBack = () => {
        setViewWindow('login');
    };

    const successGoogle = (response: any) => {
        setTokenGoogle(response.accessToken);

        setISession(
            {
                ...iSession,
                "access_token": response.Zb.access_token,
                "expires_in": response.Zb.expires_in,
                "refresh_token": response.Zb.id_token,
                "scope": "",
                "token_type": response.Zb.token_type,
                "isGoogle": true
            });

        window.open(iPlanFilterParams.baseUrl + 'test', "_self");
    }

    const failureGoogle = (response: any) => {
        //console.log('Failure', response);
    }

    return (
        <div className="container squareOrderConfirmation">
            <div className="align-middle" style={{ height: "100%" }}>
                {
                    viewWindow && viewWindow === 'login' &&
                    <>
                        <Login />
                        <div>
                            <a href="javascript:void(0)" onClick={onClickViewForgot}>Forgot Password</a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="javascript:void(0)" onClick={onClickRegister}>Register</a>
                            &nbsp;&nbsp;&nbsp;
                        </div>
                        <GoogleLogin
                            clientId="18658352516-9fmbnp9luhqee9nb7c4728es1glslbfb.apps.googleusercontent.com"
                            onSuccess={successGoogle}
                            onFailure={failureGoogle}
                            isSignedIn={true}
                        />
                    </>
                }
                {
                    viewWindow && viewWindow === 'forgot' &&
                    <>
                        <ForgotPassword />
                        <div>
                            <a href="javascript:void(0)" onClick={onClickBack}>Back to login</a>
                        </div>
                    </>
                }
                {
                    viewWindow && viewWindow === 'register' &&
                    <>
                        <RegisterUser />
                        <div>
                            <a href="javascript:void(0)" onClick={onClickBack}>Back to login</a>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Account;