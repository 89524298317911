import React from 'react';
import { IPlanFilterParamsInternet } from '../../../contracts/types';
import { useGlobalState } from '../../../state';

interface IGroupAddressItemProps {
    index: number;
    internetParams: IPlanFilterParamsInternet;
}

const GroupAddressItem: React.FC<IGroupAddressItemProps> = ({ index, internetParams }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');

    const onClickViewPlans = (internetParams: IPlanFilterParamsInternet, openedGroup: boolean) => {
        let planFilterParam = iPlanFilterParams.multipleAddresses?.multipleInternetParams.find(p => p.idMultiple === internetParams.idMultiple);

        if (iPlanFilterParams.multipleAddresses && planFilterParam) {
            planFilterParam.openedGroupAddressPlans = openedGroup;
            let arrayMultipleInternetParams = iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(p => p.idMultiple !== internetParams.idMultiple);
            arrayMultipleInternetParams.push(planFilterParam);

            setIPlanFilterParams({
                ...iPlanFilterParams,
                multipleAddresses: {
                    ...iPlanFilterParams.multipleAddresses,
                    multipleInternetParams: arrayMultipleInternetParams
                }
            });
        }
    }

    return (
        <tr>
            <td colSpan={7}>
                <button
                    className="buttonCollapseGroupAddress"
                    onClick={() => onClickViewPlans(internetParams, (internetParams.openedGroupAddressPlans ? false : true))}>{internetParams.openedGroupAddressPlans ? '-' : '+'}
                </button>
                <span className="groupAddressNumber">Address {index + 1}: </span>
                <span className="groupAddressAddress">{internetParams.streetLine}{internetParams.secondary !== '' ? `, ${internetParams.secondary}` : ''}, {internetParams.city}, {internetParams.state} , {internetParams.zipCode}</span>
                <span className="groupAddressPlan">
                    {
                        internetParams.plan === undefined
                            ?
                            <>
                                {'(Pending)'}
                                <div className="tooltipWire">
                                    <img className="imgPendingMultipleGroup" src="https://wiresurferdev.itroi.co/wp-content/wiresresources/images/multiAlert.png?_t=1625150239" alt="" />
                                    <span className="tooltiptextWire">This address does not have an associated plan. Please choose one.</span>
                                </div>
                            </>
                            :
                            <>
                                {
                                    internetParams.plan
                                        ? internetParams.plan.internalPlanId === -1
                                            ? `(${internetParams.plan.planTypeName})`
                                            : `(
                                                ${internetParams.plan.providerName}
                                                ${internetParams.plan.planDownloadSpeed < 1000 ? internetParams.plan.planDownloadSpeed : internetParams.plan && internetParams.plan.planDownloadSpeed / 1000}${internetParams.plan.planDownloadSpeed < 1000 ? 'Mbps' : 'Gbps'}
                                                ${internetParams.plan.planTechnologyName}
                                                ${internetParams.plan.planTerm}mo:
                                                $${internetParams.plan.planPrice.toFixed(2)}
                                            )`
                                        : <></>
                                }
                            </>
                    }
                </span>
            </td>
        </tr>
    );
};

export default GroupAddressItem;