import React from "react";
import {
  EnumPropertyId,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";

interface IVoicePlanLargeExpandedProps {
  plan: IResponseVoicePlansDTO;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const VoicePlanLargeExpanded: React.FC<IVoicePlanLargeExpandedProps> = ({
  plan,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  const [iGlobals] = useGlobalState("globals");

  return (
    <>
      <div className="row">
        <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th></th>
            </tr>
          </thead>
          <tbody className="card-more-table">
            <tr className="">
              <td>
                <div className="more-card-view-title">Recommended Use For:</div>
              </td>
            </tr>
            <tr className="">
              <td>
                <div className="more-card-view-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
                    )?.value
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="col-md-12">
          <div className="row card-text-center">
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-3">
              {/* <div className="card-center-call">
                <span className="card-view-call">
                  Call to Get Advices <b>1.844.947.7737</b>
                </span>
              </div> */}
            </div>
            <div className="col-md-3 view-more-details">
              <span
                className="card-view-call link-url"
                onClick={onClickShowMoreDetails}
              >
                View less details
                <i
                  className="fa fa-chevron-up view-more-icon"
                  aria-hidden="true"
                />{" "}
              </span>
              {/* <button
                type="button"
                className="btn card-button-quote"
                onClick={onClickOrderNow}
              >
                {isCompare ? "ADD TO COMPARE" : "Order Now"}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VoicePlanLargeExpanded;
