import React, { useEffect, useState } from "react";

import {
  EnumPropertyId,
  IResponseBundlePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IBundlePlanLargeMobileProps {
  plan: IResponseBundlePlansDTO;
  onChangeCompareCheckBox: (planId: number) => void;
}

const BundlePlanLargeMobile: React.FC<IBundlePlanLargeMobileProps> = ({
  plan,
  onChangeCompareCheckBox,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  return (
    <div className="row align-middle">
      <div className="col-12 text-center">
        {plan.providerLogo === "logoWiresurferDummy.png" ? (
          <img
            className="mx-auto d-block"
            src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`}
            alt=""
          />
        ) : (
          <img
            className="mx-auto d-block"
            src={`data:image/jpeg;base64,${
              iGlobals.carriers.find((c) => c.id === plan.providerId)
                ?.imageBase64
            }`}
            alt=""
          />
        )}
      </div>
      <div className="col-12 text-center p-2">
        <span className="service-type-name-mobile">{plan.planName}</span>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-2 m-internet-card-first">
        <div className="text-head">Price</div>
        <div>${plan.planPrice.toFixed(2)} / mo.</div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-2 m-internet-card-first">
        <div className="text-head">Term</div>
        <div>{plan.planTerm} Months</div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-3 m-internet-card-first">
        <div className="text-head">Internet Speed</div>
        <div>
          {plan.planDownloadSpeed >= 1000
            ? (plan.planDownloadSpeed / 1000).toFixed(0)
            : plan.planDownloadSpeed}

          {plan.planDownloadSpeed >= 1000 ? "Gbps" : "Mbps"}
        </div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-2 m-internet-card-first">
        <div className="text-head">Phone Line</div>
        <div>
          {
            iGlobals.productProperties.find(
              (pp) =>
                pp.productId === plan.productId &&
                pp.propertyCode === EnumPropertyId.PHONE_LINE
            )?.value
          }
        </div>
      </div>
    </div>
  );
};

export default BundlePlanLargeMobile;
