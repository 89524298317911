import React, { useEffect, useState } from 'react';
import CalculatorProgressBar from './calculatorProgressBar.comp';

interface ICalculatorHeaderProps {
    currentPage: number;
}

const CalculatorHeader: React.FC<ICalculatorHeaderProps> = ({ currentPage }) => {

    return (
        <div className='cssCalculatorHeader'>
            <div className='cssCalculatorHeaderTitle'>
                Looking for business connectivity?
            </div>
            <div className='cssCalculatorHeaderSubtitle'>
                We’ll help you find the best option!
            </div>
            <CalculatorProgressBar currentPage={currentPage} />
        </div>
    );
};

export default CalculatorHeader;