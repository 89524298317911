import React, { useState } from "react";

import {
  IResponseBundlePlansDTO,
  IPlansCompare,
  IAuditVisitorSearchCompare,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import BundlePlanLargeCollapsed from "./bundlePlanLargeCollapsed.comp";
import BundlePlanLargeExpanded from "./bundlePlanLargeExpanded.comp";
import BundlePlanLargeMobileCollapsed from "./bunblePlanLargeMobileCollapsed.comp";
import BundlePlanLargeMobileExpanded from "./bunblePlanLargeMobileExpanded.comp";
import BundlePlanLargeMobile from "./bunblePlanLargeMobile.comp";
import BundlePlanLargeMobileFoot from "./bunblePlanLargeMobileFoot.comp";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IBundlePlanLargeProps {
  plan: IResponseBundlePlansDTO;
  isCompare?: boolean;
  lastElementRef?: any;
}

const BundlePlanLarge: React.FC<IBundlePlanLargeProps> = ({
  plan,
  lastElementRef,
  isCompare = false,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");
  const [iFilters, setIFilters] = useGlobalState("filters");

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const {} = useAuditSaveCompare();

  const onChangeCompareCheckBox = (planId: number) => {
    let plansCompare: IPlansCompare[] =
      iFilters.plansCompareBundle == null ? [] : iFilters.plansCompareBundle;
    const exists = plansCompare.find(
      (planCompare) => planCompare.idPlan === planId
    );

    if (exists) {
      plansCompare = plansCompare.filter(
        (planCompare) => planCompare.idPlan !== planId
      );
    } else {
      const planCompare: IPlansCompare = {
        idPlan: plan.planId,
        codeProvider: "",
        planName: plan.planName,
        planPrice: plan.planPrice,
      };

      plansCompare.push(planCompare);
    }

    setIFilters({
      ...iFilters,
      plansCompareBundle: plansCompare,
    });

    if (!exists) {
      const searchCompare: IAuditVisitorSearchCompare = {
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        planId: plan.planId,
        internalPlanId: plan.internalPlanId,
        providerId: plan.providerId,
        learnMore: 0,
        remove: false,
        codeProvider: "",
      };

      setIAuditVisitor({
        ...iAuditVisitor,
        searchCompare: searchCompare,
      });
    } else {
      let searchCompare = iAuditVisitor.search.compares.find(
        (sc) => sc.planId === plan.planId
      );

      if (searchCompare) {
        searchCompare.remove = true;

        setIAuditVisitor({
          ...iAuditVisitor,
          searchCompare: searchCompare,
        });
      }
    }
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  // const onClickOrderNow = () => {
  //   if (isCompare) {
  //     onChangeCompareCheckBox(plan.planId);
  //     return;
  //   }

  //   let surveyData = iPlanFilterParams.surveyData;
  //   surveyData.planId = plan.planId;
  //   surveyData.providerId = plan.providerId;
  //   surveyData.internalPlanId = plan.internalPlanId;

  //   surveyData.isOpen = true;
  //   surveyData.orderId = -1;
  //   surveyData.isPageThanks = false;

  //   setIPlanFilterParams({
  //     ...iPlanFilterParams,
  //     bundleParams: {
  //       ...iPlanFilterParams.bundleParams,
  //       plan: plan,
  //     },
  //     surveyData: surveyData,
  //   });

  //   if (!iPlanFilterParams.isLocalhost) {
  //     window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
  //   }
  // };

  const onClickOrderNow = (plan: IResponseBundlePlansDTO) => {
    if (plan) {
      let surveyData = iPlanFilterParams.surveyData;
      surveyData.planId = plan.planId;
      surveyData.providerId = plan.providerId;
      surveyData.internalPlanId = plan.internalPlanId;

      surveyData.isOpen = true;
      surveyData.orderId = -1;
      surveyData.isPageThanks = false;

      setIPlanFilterParams({
        ...iPlanFilterParams,
        bundleParams: {
          ...iPlanFilterParams.bundleParams,
          plan: plan,
        },
        surveyData: surveyData,
      });

      if (!iPlanFilterParams.isLocalhost) {
        window.open(iPlanFilterParams.baseUrl + "ordernow", "_self");
      }
    }
  };

  const onClickShowMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-3364ac2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="3364ac2"
      data-element_type="section"
      ref={lastElementRef}
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-00a0bc9"
          data-id="00a0bc9"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-a493899 elementor-widget elementor-widget-html"
              data-id="a493899"
              data-element_type="widget"
              data-widget_type="html.default"
            >
              <div className="elementor-widget-container">
                <div className="container card-container ocultar-card-deskop">
                  <div className="row">
                    <div className="col-md-4 card-float-int">
                      {plan.providerLogo === "logoWiresurferDummy.png" ? (
                        <img
                          className="card-space-img"
                          src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="card-space-img"
                          src={`data:image/jpeg;base64,${
                            iGlobals.carriers.find(
                              (c) => c.id === plan.providerId
                            )?.imageBase64
                          }`}
                          alt=""
                        />
                      )}
                    </div>
                    {/* <div className="text-center">
                      <div
                        className={`${
                          iFilters.plansCompareBundle.find(
                            (planCompare) => planCompare.idPlan === plan.planId
                          )
                            ? "circle-check-on"
                            : "circle-check"
                        } metrics-SearchComparePlan`}
                        onClick={() => onChangeCompareCheckBox(plan.planId)}
                        data-name={plan.planName}
                        data-Price={plan.planPrice.toFixed(2)}
                        data-speed={plan.planDownloadSpeed}
                        data-linephone={
                          iGlobals.productProperties.find(
                            (pp) =>
                              pp.productId === plan.productId &&
                              pp.propertyCode === EnumPropertyId.PHONE_LINE
                          )?.value
                        }
                        data-term={plan.planTerm}
                      />
                      {iFilters.plansCompareBundle &&
                      iFilters.plansCompareBundle.length >= 1 ? (
                        <span
                          className="card-compareplan link-url"
                          onClick={onClickCompareLink}
                        >
                          Compare Plan
                        </span>
                      ) : (
                        <span className="card-compareplan">Compare Plan</span>
                      )}
                    </div> */}
                    <div className="col-md-8 card-float-int service-type-name-bundle">
                      {plan.planName}
                    </div>
                  </div>
                  <BundlePlanLargeCollapsed
                    plan={plan}
                    showMoreDetails={showMoreDetails}
                    onClickShowMoreDetails={onClickShowMoreDetails}
                    onClickOrderNow={() => onClickOrderNow(plan)}
                    isCompare={isCompare}
                  />

                  {showMoreDetails && (
                    <BundlePlanLargeExpanded
                      plan={plan}
                      onClickShowMoreDetails={onClickShowMoreDetails}
                      onClickOrderNow={() => onClickOrderNow(plan)}
                      isCompare={isCompare}
                    />
                  )}
                </div>
                <div className="container card-container ocultar-card-mobile">
                  <div className="">
                    <BundlePlanLargeMobile
                      plan={plan}
                      onChangeCompareCheckBox={onChangeCompareCheckBox}
                    />

                    <div className="row text-center">
                      {!showMoreDetails ? (
                        <BundlePlanLargeMobileCollapsed />
                      ) : (
                        <BundlePlanLargeMobileExpanded plan={plan} />
                      )}
                      <BundlePlanLargeMobileFoot
                        showMoreDetails={showMoreDetails}
                        onClickShowMoreDetails={onClickShowMoreDetails}
                        onClickOrderNow={() => onClickOrderNow(plan)}
                        isCompare={isCompare}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BundlePlanLarge;
