import React, { useEffect } from "react";
import "./App.css";
import "./css/style.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { IWrSettings } from "./contracts/types";
import { useGlobalState } from "./state";
import SearchPage from "./pages/search.page";

interface AppProps {
  wrSettings: IWrSettings;
}

const queryClient = new QueryClient();

const App: React.FC<AppProps> = ({ wrSettings }) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");
  const [iSession] = useGlobalState("session");
  const [iAuditVisitor] = useGlobalState("auditVisitor");

  useEffect(() => {
    sessionStorage.setItem(
      "data_iPlanFilterParams",
      JSON.stringify(iPlanFilterParams)
    );
  }, [iPlanFilterParams]);

  useEffect(() => {
    sessionStorage.setItem("data_iFilters", JSON.stringify(iFilters));
  }, [iFilters]);

  useEffect(() => {
    sessionStorage.setItem("data_iGlobals", JSON.stringify(iGlobals));
  }, [iGlobals]);

  useEffect(() => {
    localStorage.setItem("data_iSession", JSON.stringify(iSession));
  }, [iSession]);

  useEffect(() => {
    sessionStorage.setItem("data_iAuditVisitor", JSON.stringify(iAuditVisitor));
  }, [iAuditVisitor]);

  return (
    <QueryClientProvider client={queryClient}>
      <SearchPage wrSettings={wrSettings}></SearchPage>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
