import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { ITbiOrderResponseDTO } from "../../contracts/types";

export default function useTbiOrders() {

	const api = useServiceApi();

	const doRequest = async (): Promise<ITbiOrderResponseDTO[]> => {

		const { data } = await api.get(`${ApiEndPoints.GetTbiOrders}`);

		return data;
	};

	return useQuery<ITbiOrderResponseDTO[]>(
		['tbiOrders'],
		() => doRequest(), {
		staleTime: Infinity,
		cacheTime: Infinity,
		enabled: true
	}
	);
}
