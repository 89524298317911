import React from 'react';
import parse from 'html-react-parser';
import { IResponseInternetPlansDTO } from './../../contracts/types';
import { useGlobalState } from './../../state';

interface ICalculatorPlanProps {
    plan: IResponseInternetPlansDTO;
}

const CalculatorPlan: React.FC<ICalculatorPlanProps> = ({ plan }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals] = useGlobalState('globals');

    const onClickGetStarted = () => {
        let surveyData = iPlanFilterParams.surveyData;
        surveyData.planId = plan.planId;
        surveyData.providerId = plan.providerId;
        surveyData.internalPlanId = plan.internalPlanId;
        surveyData.isOpen = true;
        surveyData.orderId = -1;
        surveyData.isPageThanks = false;

        setIPlanFilterParams({
            ...iPlanFilterParams,
            internetParams: {
                ...iPlanFilterParams.internetParams,
                plan: plan,
            },
            'surveyData': surveyData,
        });

        if (!iPlanFilterParams.isLocalhost) {
            window.open(iPlanFilterParams.baseUrl + 'quotenow', '_self');
        }
    };

    return (
        <div className="calculatorPlan col-md-3">
            <div className="calculatorPlanProvider">
                <div className="row firstRowGrid">
                    <div className="col-md-12 text-center">
                        {plan.providerLogo === 'logoWiresurferDummy.png' ? (
                            <img src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`} alt="" />
                        ) : (
                            <img src={`data:image/jpeg;base64,${iGlobals.carriers.find((c) => c.id === plan.providerId)?.imageBase64}`} />
                        )}
                    </div>
                </div>
            </div>
            {plan.internalPlanId !== -1 && <div className="calculatorPlanBusinessInternet">{plan.planTypeName}</div>}
            {plan.internalPlanId !== -1 && (
                <div className="calculatorPlanPrice">
                    <span>{plan.planPrice.toFixed(2)}</span>
                </div>
            )}
            <div className="calculatorPlanDetails">
                {plan.internalPlanId !== -1 ? (
                    <ul>
                        <li>{plan.planTechnologyName}</li>
                        <li>{plan.planDownloadSpeed < 1000 ? `${plan.planDownloadSpeed} Mbps` : `${plan.planDownloadSpeed / 1000} Gbps`}</li>
                        <li>{plan.planTerm} Months</li>
                        {plan.planModemIncluded && <li>Modem included</li>}
                        {plan.planTechnologyName === 'Broadband' && (
                            <>
                                <li>Shared Connection</li>
                                <li>No SLA</li>
                            </>
                        )}
                        {plan.planTechnologyName === 'Fiber' && (
                            <>
                                <li>Dedicated Fiber</li>
                                <li>SLA 99.9% or Higher</li>
                            </>
                        )}
                    </ul>
                ) : (
                    <>
                        <p>{parse(plan.planNote)}</p>
                        <div className="divCardWsAgent">
                            <a className="hrefCardWsAgent" href="javascript:void(0)" onClick={onClickGetStarted}>
                                Connect to a Wiresurfer Telecom Expert Now
                            </a>
                        </div>
                    </>
                )}
            </div>
            {plan.internalPlanId !== -1 && (
                <div className="calculatorPlanOrderNow">
                    <button className="calculatorPlanOrderNowButton" onClick={onClickGetStarted}>
                        Order now
                    </button>
                </div>
            )}
        </div>
    );
};

export default CalculatorPlan;
