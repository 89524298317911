import React from 'react';
import { EnumPlanType } from '../contracts/types';
import InternetPlanTools from './plan/internet/internetPlanTools.comp';
import VoicePlanTools from './plan/voice/voicePlanTools.comp';
import { useGlobalState } from '../state';

interface IPlanToolsProps {}

const PlanTools: React.FC<IPlanToolsProps> = () => {
    const [iFilters] = useGlobalState('filters');

    return <>{iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET ? <InternetPlanTools /> : <VoicePlanTools />}</>;
};

export default PlanTools;
