import React, { useEffect, useState } from "react";
import {
  EnumPropertyId,
  EnumViewPlansType,
  IResponseInternetPlansDTO,
} from "../../../contracts/types";
import useInternetPlans from "../../../hooks/api/useInternetPlans";
import { useGlobalState } from "../../../state";
import Loading from "../../loading.comp";
import ComparePrice from "./comparePrice.com";
import CompareProperty from "./compareProperty.com";
import CompareSpeed from "./compareSpeed.com";
import CompareTerm from "./compareTerm.com";
import CompareTitle from "./compareTitle.com";

interface ICompareInternetProps {}

const CompareInternet: React.FC<ICompareInternetProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");

  const [plansData, setPlansData] = useState<IResponseInternetPlansDTO[]>([]);
  const [plansDataCompare, setPlansDataCompare] =
    useState<IResponseInternetPlansDTO[]>();
  const [countCodeProvider, setCountCodeProvider] = useState(0);

  useEffect(() => {
    if (countCodeProvider >= 3) {
      const arrayPC: IResponseInternetPlansDTO[] = [];

      iFilters.plansCompareInternet.forEach((pc) => {
        const plan = plansData.find(
          (p) => p.codeProvider === pc.codeProvider && p.planId === pc.idPlan
        );
        if (plan) {
          arrayPC.push(plan);
        }
      });

      setPlansDataCompare(arrayPC);
    }
  }, [countCodeProvider, iFilters.plansCompareInternet.length]);

  const { data, isLoading, isError } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "",
    0
  );

  // const { data: data_AST } = useInternetPlans(
  //   EnumViewPlansType.LARGE,
  //   "AST_CB",
  //   0
  // );

  const { data: dataATT_IPBB } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_IPBB",
    0
  );

  const { data: dataATT_ADI_12 } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_ADI",
    12
  );

  const { data: dataATT_ADI_24 } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_ADI",
    24
  );

  const { data: dataATT_ADI_36 } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_ADI",
    36
  );

  useEffect(() => {
    validData();
  }, [
    data,
    // data_AST,
    dataATT_IPBB,
    dataATT_ADI_12,
    dataATT_ADI_24,
    dataATT_ADI_36,
  ]);

  const validData = () => {
    let dataDummy: IResponseInternetPlansDTO[] = [];
    let count = countCodeProvider;

    if (data) {
      dataDummy = data.lstResponseInternetPlansDTO;
      count++;
    }

    // if (data_AST) {
    //   dataDummy = dataDummy.concat(data_AST.lstResponseInternetPlansDTO);
    //   count++;
    // }

    if (dataATT_IPBB) {
      dataDummy = dataDummy.concat(dataATT_IPBB.lstResponseInternetPlansDTO);
      count++;
    }

    if (dataATT_ADI_12) {
      dataDummy = dataDummy.concat(dataATT_ADI_12.lstResponseInternetPlansDTO);
      count++;
    }

    if (dataATT_ADI_24) {
      dataDummy = dataDummy.concat(dataATT_ADI_24.lstResponseInternetPlansDTO);
      count++;
    }

    if (dataATT_ADI_36) {
      dataDummy = dataDummy.concat(dataATT_ADI_36.lstResponseInternetPlansDTO);
      count++;
    }

    setPlansData(dataDummy);
    setCountCodeProvider(count);
  };

  const onClickOrderNow = (plan: IResponseInternetPlansDTO) => {
    let surveyData = iPlanFilterParams.surveyData;
    surveyData.planId = plan.planId;
    surveyData.providerId = plan.providerId;
    surveyData.internalPlanId = plan.internalPlanId;

    if (
      iPlanFilterParams.idAddressMultiple === -1 ||
      iPlanFilterParams.multipleAddresses.multipleInternetParams.length === 1
    ) {
      surveyData.isOpen = true;
      surveyData.orderId = -1;
      surveyData.isPageThanks = false;

      setIPlanFilterParams({
        ...iPlanFilterParams,
        internetParams: {
          ...iPlanFilterParams.internetParams,
          plan: plan,
        },
        surveyData: surveyData,
      });

      if (!iPlanFilterParams.isLocalhost) {
        window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
      }
    } else {
      const arrayMultiple =
        iPlanFilterParams.multipleAddresses.multipleInternetParams;

      let paramsInternetSelect = arrayMultiple.find(
        (paramsInternet) =>
          paramsInternet.idMultiple ===
          iPlanFilterParams.internetParams.idMultiple
      );
      if (paramsInternetSelect) {
        paramsInternetSelect.plan = plan;
      }

      setIPlanFilterParams({
        ...iPlanFilterParams,
        surveyData: surveyData,
        multipleAddresses: {
          ...iPlanFilterParams.multipleAddresses,
          multipleInternetParams: arrayMultiple,
        },
      });

      if (!iPlanFilterParams.isLocalhost) {
        window.open(iPlanFilterParams.baseUrl + "multiple", "_self");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading typeName="Internet providers nationwide, this may take up to 30 seconds!" />
      ) : (
        plansDataCompare && (
          <table className="table table-Compare align-middle">
            <tbody>
              <CompareTitle plansDataCompare={plansDataCompare} />
              <tr>
                <th className="headcol-compare label-compareBi">
                  {" "}
                  SPECIFICATIONS
                </th>
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <img
                      className="compareBI-logo"
                      src={`data:image/jpeg;base64,${
                        iGlobals.carriers.find((c) => c.id === plan.providerId)
                          ?.imageBase64
                      }`}
                      alt=""
                    />
                    <button
                      type="button"
                      className="btn compare-button-connect metrics-SearchRequestNow"
                      onClick={() => onClickOrderNow(plan)}
                      data-name={plan.planName}
                      data-Price={plan.planPrice.toFixed(2)}
                      data-speed={plan.planDownloadSpeed}
                      data-linephone="N/A"
                      data-term={plan.planTerm}
                    >
                      Order Now
                    </button>
                  </td>
                ))}
              </tr>
              <ComparePrice plansDataCompare={plansDataCompare} />
              <CompareSpeed plansDataCompare={plansDataCompare} />
              <CompareProperty
                title="CONNECTED DEVICES"
                propertyCode={EnumPropertyId.CONNECTED_DEVICES}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="RECOMMENDED FOR"
                propertyCode={EnumPropertyId.RECOMMENDED_FOR}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="WHY THIS CARRIER"
                propertyCode={EnumPropertyId.WHYTHISCARRIER}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="SYMMETRICAL // ASYMMETRICAL CONNECTION"
                propertyCode={EnumPropertyId.SYM_ASYM_CONNECTION}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="DEDICATED / SHARED INTERNET"
                propertyCode={EnumPropertyId.DEDICATED_SHARED_INTERNET}
                plansDataCompare={plansDataCompare}
              />
              <CompareTerm plansDataCompare={plansDataCompare} />
              <CompareProperty
                title="TECH SUPPORT"
                propertyCode={EnumPropertyId.TECH_SUPPORT}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="TECHNOLOGY"
                propertyCode={EnumPropertyId.TECHNOLOGY}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="INSTALLATION"
                propertyCode={EnumPropertyId.INSTALLATION}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="STATIC IP"
                propertyCode={EnumPropertyId.FIXED_IP_COST}
                plansDataCompare={plansDataCompare}
              />
              <CompareProperty
                title="PRICE GUARANTEE"
                propertyCode={EnumPropertyId.PRICE_GUARANTEE}
                plansDataCompare={plansDataCompare}
              />
              <tr>
                <th className="headcol-compare" />
                {plansDataCompare.map((plan) => (
                  <td className="compare-plan-space-td">
                    <button
                      type="button"
                      className="btn compare-button-connect metrics-SearchRequestNow"
                      onClick={() => onClickOrderNow(plan)}
                      data-name={plan.planName}
                      data-Price={plan.planPrice.toFixed(2)}
                      data-speed={plan.planDownloadSpeed}
                      data-linephone="N/A"
                      data-term={plan.planTerm}
                    >
                      Order Now
                    </button>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )
      )}
    </>
  );
};

export default CompareInternet;
