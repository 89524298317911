import axios from "axios";
import { useGlobalState } from '../state';

export default function useApiAuth() {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');

    const ws = axios.create({
        baseURL: iPlanFilterParams.wiresurferApi
    });

    return ws;
}