import React, { useState, useRef, useCallback } from "react";
import { IWrSettings, EnumViewPlansType } from "../../../contracts/types";
import useVoicePlansPagination from "../../../hooks/api/useVoicePlansPagination";
import VoicePlanLarge from "./voicePlanLarge.comp";
import VoicePlanShort from "./voicePlanShort.comp";
import PlanTools from "../../planTools.comp";
import { useGlobalState } from "../../../state";
import Loading from "../../loading.comp";
import SeeMorePlans from "../seeMorePlans.comp";
import PlansFilters from "../plansFilters.comp";
import PlanAgentAvailable from "../planAgentAvailable.comp";

interface IVoicePlansProps {
  viewPlansType: EnumViewPlansType;
  wrSettings: IWrSettings;
}

const VoicePlans: React.FC<IVoicePlansProps> = ({
  viewPlansType,
  wrSettings,
}) => {
  const [iGlobals] = useGlobalState("globals");

  const [pageNumber, setPageNumber] = useState(1);
  const [showLinkMorePlans, setShowLinkMorePlans] = useState(true);

  const {
    plans: data,
    hasMore,
    loading: isLoading,
    error: isError,
  } = useVoicePlansPagination(viewPlansType, pageNumber);

  const observer = useRef<any>(null);
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !showLinkMorePlans) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, showLinkMorePlans]
  );

  const onClickShowLinkMorePlans = () => {
    setShowLinkMorePlans(false);
  };

  return (
    <>
      <div className="container">
        {isLoading ? (
          <Loading typeName="Voice providers nationwide!" />
        ) : isError ? (
          <div>Error fetching data</div>
        ) : (
          <>
            {data && viewPlansType === EnumViewPlansType.SHORT && (
              <div className="contentThirdListPrices">
                <div className="contentThirdListPrices">
                  <div className="row ThirdListPrices">
                    {data && data.length === 0 && (
                      <div className="noResultFound">
                        No providers available. But don't fret, we can still
                        help by calling us at (toll-free #). We are busy loading
                        new providers into our platform every week.
                      </div>
                    )}
                    {data.map((plan) => {
                      const providerLogo = iGlobals.carriers.find(
                        (c) => c.id === plan.providerId
                      );
                      plan.providerLogo = providerLogo
                        ? providerLogo.imageBase64
                        : "";

                      return <VoicePlanShort key={plan.planId} plan={plan} />;
                    })}
                  </div>
                </div>
              </div>
            )}
            {data && viewPlansType === EnumViewPlansType.LARGE && (
              <>
                <PlansFilters />
                <section id="productsWS">
                  <div className="container">
                    <section className="mb-3">
                      <PlanTools />
                    </section>
                    {data && data.length === 0 && (
                      <div className="noResultFound">
                        No providers available. But don't fret, we can still
                        help by calling us at (toll-free #). We are busy loading
                        new providers into our platform every week.
                      </div>
                    )}
                    <div className="row resultWSgridVoice">
                      {data &&
                        data.map((plan, index) => {
                          return (
                            <VoicePlanLarge
                              key={plan.planId}
                              plan={plan}
                              lastElementRef={lastElementRef}
                            />
                          );
                        })}
                    </div>
                    {/* {
                                            !hasMore &&
                                            <PlanAgentAvailable />
                                        } */}
                    {data &&
                      data.length > 0 &&
                      hasMore &&
                      pageNumber === 1 &&
                      showLinkMorePlans && (
                        <SeeMorePlans
                          onClickSeeMorePlans={onClickShowLinkMorePlans}
                        />
                      )}
                  </div>
                </section>
              </>
            )}
            <div>{isLoading && "Loading..."}</div>
            <div>{isError && "Error fetching data"}</div>
          </>
        )}
      </div>
    </>
  );
};

export default VoicePlans;
