import axios from 'axios';
import { useQuery } from 'react-query';
import { useServiceApi, ApiEndPoints } from '../../api';
import { EnumViewPlansType, IResponseBundlePlansDTO, IRequestBundlePlansDTO, IResponseParamsBundlePlansDTO, ISelectOption, EnumPlanType } from '../../contracts/types';
import { filtersPlansBundles } from '../../helpers/filtersPlansBundles';
import { useGlobalState } from '../../state';

export default function useBundlePlans(pageNumber: number) {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals, setIGlobals] = useGlobalState('globals');
    const [iFilters, setIFilters] = useGlobalState('filters');

    const api = useServiceApi();

    const doRequest = async (address: string, streetLine: string, city: string, state: string, country: string, zipCode: string, viewPlansType: EnumViewPlansType): Promise<IResponseParamsBundlePlansDTO> => {
        try {
            const responseParams: IResponseParamsBundlePlansDTO = {
                address: address,
                lstResponseBundlePlansDTO: [],
                lstCarriers: [],
                lstProductProperties: [],
            };

            if (country !== 'US') {
                return responseParams;
            }

            if (window.location.href === iPlanFilterParams.baseUrl) {
                zipCode = '-1';
            }

            if (viewPlansType === EnumViewPlansType.SHORT && zipCode === '-1') {
                const path = window.location.pathname;
                streetLine = '';
                city = '';
                address = '';
                state = '';

                if (path.includes('search') || path.includes('business-phone') || path.includes('business-internet')) {
                    return responseParams;
                } else if (path.includes('business-bundle')) {
                    const pathCity = path.replace('/business-bundle/', '').replace('/', '');
                    if (pathCity !== '') {
                        city = pathCity;
                    }
                }
            }

            const requestPlansDTO: IRequestBundlePlansDTO = {
                'streetLine': streetLine,
                'city': city,
                'zipCode': zipCode,
                'address': address,
                'state': state,
                'country': country,
                'viewPlansType': viewPlansType,
                'lstCarriersId': iGlobals.carriers.map((c) => c.id),
                'lstProductsId': iGlobals.productProperties.map((c) => c.productId),
            };

            const { data } = await api.post<IResponseParamsBundlePlansDTO>(`${ApiEndPoints.GetPlansBundle}`, requestPlansDTO);

            let arrayCarriers = iGlobals.carriers;
            data.lstCarriers.forEach((carrierDTO) => {
                const findCarrier = arrayCarriers.find((c) => c.id === carrierDTO.id);
                if (!findCarrier) {
                    arrayCarriers.push(carrierDTO);
                }
            });

            let arrayProPro = iGlobals.productProperties;
            data.lstProductProperties.forEach((productPropertyDTO) => {
                const findProPro = arrayProPro.find((pp) => pp.productId === productPropertyDTO.productId && pp.propertyCode === productPropertyDTO.propertyCode);
                if (!findProPro) {
                    arrayProPro.push(productPropertyDTO);
                }
            });

            const plansCompareProvider = iFilters.plansCompareBundle
                .filter((planCompare) => data.lstResponseBundlePlansDTO.find((e) => e.planId === planCompare.idPlan));
            const plansCompare = iFilters.plansCompareBundle.concat(plansCompareProvider);

            if (data.address === iPlanFilterParams.bundleParams.address && zipCode !== '-1') {
                let bundleParams = iPlanFilterParams.bundleParams;

                let termsData = data.lstResponseBundlePlansDTO.map((item: IResponseBundlePlansDTO) => item.planTerm);
                termsData = termsData.filter((value: any, index: any, self: any) => self.indexOf(value) === index);

                let groupByTerms = iPlanFilterParams.groupByTerms;
                termsData.forEach((term: number) => {
                    if (!groupByTerms.includes(term)) {
                        groupByTerms.push(term);
                    }
                });

                let providersData: ISelectOption[] = [];
                data.lstResponseBundlePlansDTO.forEach((p) => {
                    const findProvider = providersData.find((f) => f.value === p.providerId);
                    if (!findProvider) {
                        const pr: ISelectOption = {
                            value: p.providerId,
                            label: p.providerName,
                        };
                        providersData.push(pr);
                    }
                });

                let groupByProviders = iPlanFilterParams.groupByProviders;
                providersData.forEach((prov: ISelectOption) => {
                    const findProvider = groupByProviders.find((p) => p.value === prov.value);
                    if (!findProvider) {
                        groupByProviders.push(prov);
                    }
                });

                setIPlanFilterParams({
                    ...iPlanFilterParams,
                    'groupByTerms': groupByTerms,
                    'groupByProviders': groupByProviders,
                    'bundleParams': bundleParams,
                });

                setIGlobals({
                    ...iGlobals,
                    'carriers': arrayCarriers,
                    'productProperties': arrayProPro,
                });

                setIFilters({
                    ...iFilters,
                    plansCompareBundle: plansCompare,
                });
            } else if (zipCode === '-1') {
                setIGlobals({
                    ...iGlobals,
                    'carriers': arrayCarriers,
                    'productProperties': arrayProPro,
                });
            }

            return data;
        } catch (err) {
            if (axios.isCancel(err)) {
            }
        }

        const dataReturn: IResponseParamsBundlePlansDTO = {
            address: address,
            lstResponseBundlePlansDTO: [],
            lstCarriers: [],
            lstProductProperties: [],
        };

        return dataReturn;
    };

    const filterData = (data: IResponseParamsBundlePlansDTO) => {
        let result = data.lstResponseBundlePlansDTO;

        if (result && result.length > 0) {
            result = filtersPlansBundles(result, iFilters);
        }

        data.lstResponseBundlePlansDTO = result;

        return data;
    };

    return useQuery<IResponseParamsBundlePlansDTO>(
        ['plansBundle', iPlanFilterParams.bundleParams.streetLine, iPlanFilterParams.bundleParams.city, iPlanFilterParams.bundleParams.zipCode, iPlanFilterParams.bundleParams.secondary, EnumPlanType.BUSINESS_BUNDLES],
        () =>
            doRequest(
                iPlanFilterParams.bundleParams.address,
                iPlanFilterParams.bundleParams.streetLine,
                iPlanFilterParams.bundleParams.city ? iPlanFilterParams.bundleParams.city : '',
                iPlanFilterParams.bundleParams.state ? iPlanFilterParams.bundleParams.state : '',
                iPlanFilterParams.bundleParams.country ? iPlanFilterParams.bundleParams.country : 'US',
                iPlanFilterParams.bundleParams.zipCode,
                iPlanFilterParams.bundleParams.viewPlansType ? iPlanFilterParams.bundleParams.viewPlansType : EnumViewPlansType.SHORT
            ),
        {
            select: filterData,
            staleTime: 60 * 1000 * 60 /*5 minutes*/,
            enabled: iPlanFilterParams.bundleParams.zipCode !== '',
            retry: 1,
            notifyOnChangeProps: ['data', 'error'],
        }
    );
}
