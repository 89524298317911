import React, { useEffect, useState } from "react";
import { IRequestClickToDialTBIDTO } from "../../../contracts/types";
import useClickToDialSendOrder from "../../../hooks/api/useClickToDialSendOrder";
import useClickToDialSendTBI from "../../../hooks/api/useClickToDialSendTBI";
import NumberFormat from "react-number-format";
import { useGlobalState } from "../../../state";

const ClickToDial: React.FC = () => {
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iAuditVisitor] = useGlobalState("auditVisitor");

  const [requestSendOrder, setRequestSendOrder] =
    useState<IRequestClickToDialTBIDTO>();
  const [txtFirstName, setTxtFirstName] = useState("");
  const [txtLastName, setTxtLastName] = useState("");
  const [txtPhone, setTxtPhone] = useState("");
  const [txtEmail, setTxtEmail] = useState("");
  const [showThanks, setShowThanks] = useState(false);

  const { data: dataSendToDial } = useClickToDialSendOrder(requestSendOrder);

  const { data: dataSendToDialTBI } = useClickToDialSendTBI(requestSendOrder);

  useEffect(() => {
    if (dataSendToDial) {
      setShowThanks(true);
    }
  }, [dataSendToDial]);

  const onChangeFirstName = (e: any) => {
    setTxtFirstName(e.currentTarget.value);
  };

  const onChangeLastName = (e: any) => {
    setTxtLastName(e.currentTarget.value);
  };

  const onChangePhone = (e: any) => {
    setTxtPhone(e.currentTarget.value);
  };

  const onChangeEmail = (e: any) => {
    setTxtEmail(e.currentTarget.value);
  };

  const onClickSubmitTBI = (event: any) => {
    const request: IRequestClickToDialTBIDTO = {
      firstName: txtFirstName,
      lastName: txtFirstName,
      phone: txtPhone,
      email: txtEmail,
      queryString: window.location.search,
      referred: iAuditVisitor.referred,
    };

    setRequestSendOrder(request);
  };

  const onClickToCall = (e: any) => {
    const show = !iFilters.showClickToDial;
    setIFilters({
      ...iFilters,
      showClickToDial: show,
    });
  };

  return (
    <div className="divCustomClickToDial">
      {/* <button className="open-button" onClick={onClickToCall}>Click to Call</button> */}
      {iFilters.showClickToDial && (
        <div className="clickToDialPopup" id="clickToDialForm">
          <form action="/action_page.php" className="clickToDial-container">
            {showThanks ? (
              <div className="divClickToDialThanks">Thank you</div>
            ) : (
              <div className="divClickToDial">
                <form
                  className="frmClickToDial"
                  role="form"
                  name="web2campaign"
                  action="https://api.five9.com/web2campaign/AddToList"
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="WireSurferClickToDialWebform"
                    value="TBI"
                  />
                  <input
                    type="hidden"
                    name="F9list"
                    value="WireSurferClickToDialWebform"
                  />
                  <input type="hidden" name="F9CallASAP" value="1" />
                  <input type="hidden" name="F9key" value="number1" />

                  <div className="input-holder field-text">
                    <label
                      className="field-title outside-top label-outside"
                      htmlFor="first_name"
                    >
                      Name
                    </label>
                    <div className="field-element">
                      <input
                        className="shortnice form-input required"
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={txtFirstName}
                        onChange={onChangeFirstName}
                      />
                    </div>
                  </div>
                  <div className="input-holder field-text">
                    <label
                      className="field-title outside-top label-outside"
                      htmlFor="number1"
                    >
                      Phone Number
                    </label>
                    <div className="field-element">
                      <NumberFormat
                        format="+1 (###) ###-####"
                        mask="_"
                        className={`shortnice form-input required checknumericvalue`}
                        value={txtPhone}
                        onChange={onChangePhone}
                        title=""
                        required={true}
                        id="number1"
                        name="number1"
                      />
                    </div>
                  </div>
                  <div className="divButtonSubmitClickToDial">
                    <a
                      className="btn btn-primary buttonSubmitClickToDial metrics-submit"
                      href="javascript:void(0)"
                      onClick={onClickSubmitTBI}
                    >
                      Submit
                    </a>
                  </div>
                </form>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default ClickToDial;
