import React from 'react';
import { IWrSettings } from '../../contracts/types';
import MultipleAddresses from './multipleAddresses';
import BulkEditSection from './bulkEditSection.comp';

interface IMultipleProps {
    wrSettings: IWrSettings;
}

const Multiple: React.FC<IMultipleProps> = ({ wrSettings }) => {

    return (
        <div className="container">
            <BulkEditSection wrSettings={wrSettings} />
            <MultipleAddresses wrSettings={wrSettings} />
        </div>
    );
};

export default Multiple;