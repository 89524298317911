import React, { useState } from "react";
import {
  IResponseVoicePlansDTO,
  IAuditVisitorSearchCompare,
  IOrderSuProductDTO,
} from "../../../contracts/types";
import VoicePlanLargeCollapsed from "./voicePlanLargeCollapsed.comp";
import VoicePlanLargeExpanded from "./voicePlanLargeExpanded.comp";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import VoicePlanLargeMobile from "./voicePlanLargeMobile.comp";
import VoicePlanLargeMobileCollapsed from "./voicePlanLargeMobileCollapsed.comp";
import VoicePlanLargeMobileExpanded from "./voicePlanLargeMobileExpanded.comp";
import VoicePlanLargeMobileFoot from "./voicePlanLargeMobileFoot.comp";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IVoicePlanLargeProps {
  plan: IResponseVoicePlansDTO;
  isCompare?: boolean;
  lastElementRef?: any;
}

const VoicePlanLarge: React.FC<IVoicePlanLargeProps> = ({
  plan,
  lastElementRef,
  isCompare = false,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");

  const [showMoreDetails, setShowMoreDetails] = useState(false);

  const {} = useAuditSaveCompare();

  const onChangeCompareCheckBox = (planId: number) => {
    let idPlansCompare: number[] =
      iFilters.plansCompareVoice == null ? [] : iFilters.plansCompareVoice;
    const exists = idPlansCompare.find((p) => p === planId);

    if (exists) {
      idPlansCompare = idPlansCompare.filter((pc) => pc !== planId);
    } else {
      idPlansCompare.push(planId);
    }

    setIFilters({
      ...iFilters,
      plansCompareVoice: idPlansCompare,
    });

    if (!exists) {
      const searchCompare: IAuditVisitorSearchCompare = {
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        planId: plan.planId,
        internalPlanId: plan.internalPlanId,
        providerId: plan.providerId,
        learnMore: 0,
        remove: false,
        codeProvider: "",
      };

      setIAuditVisitor({
        ...iAuditVisitor,
        searchCompare: searchCompare,
      });
    } else {
      let searchCompare = iAuditVisitor.search.compares.find(
        (sc) => sc.planId === plan.planId
      );

      if (searchCompare) {
        searchCompare.remove = true;

        setIAuditVisitor({
          ...iAuditVisitor,
          searchCompare: searchCompare,
        });
      }
    }
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  const onClickOrderNow = (plan: IResponseVoicePlansDTO) => {
    if (plan) {
      let orderSubProductsPlan: IOrderSuProductDTO[] = [];

      iPlanFilterParams.voiceParams.filterConfigSubProducts.forEach(
        (subPro) => {
          if (subPro.filterConfig > 0) {
            const subProdPlan = subPro.plans.find(
              (sp) => sp.productId === plan.productId
            );
            let unitPrice = 0;

            if (subProdPlan && subProdPlan.values) {
              const subProPlanVal = subProdPlan.values
                .sort((a, b) => {
                  return a.quantity - b.quantity;
                })
                .find((s) => subPro.filterConfig >= s.quantity);
              unitPrice = subProPlanVal ? subProPlanVal.unitPrice : 0;
            }

            const orderSubProductDTO: IOrderSuProductDTO = {
              orderId: iPlanFilterParams.surveyData.orderId,
              subProductId: subPro.id,
              description: subPro.name,
              subProductNumber: subPro.filterConfig,
              unitPrice: unitPrice,
            };

            orderSubProductsPlan.push(orderSubProductDTO);
          }
        }
      );

      setIPlanFilterParams({
        ...iPlanFilterParams,
        voiceParams: {
          ...iPlanFilterParams.voiceParams,
          plan: plan,
        },
        surveyData: {
          ...iPlanFilterParams.surveyData,
          orderId: -1,
          isOpen: true,
          isPageThanks: false,
          planId: plan.planId,
        },
        subProductsPlan: orderSubProductsPlan,
      });

      if (!iPlanFilterParams.isLocalhost) {
        window.open(iPlanFilterParams.baseUrl + "ordernow", "_self");
      }
    }
    // if (isCompare) {
    //   onChangeCompareCheckBox(plan.planId);
    //   return;
    // }

    // let idPlansCompare: number[] =
    //   iFilters.plansCompareVoice == null ? [] : iFilters.plansCompareVoice;
    // const idPlanExists = idPlansCompare.find((p) => p === plan.planId);

    // if (!idPlanExists) {
    //   idPlansCompare.push(plan.planId);
    // }

    // setIFilters({
    //   ...iFilters,
    //   plansCompareVoice: idPlansCompare,
    // });

    // if (!iPlanFilterParams.isLocalhost) {
    //   window.open(iPlanFilterParams.baseUrl + "ordernow", "_self");
    // }
  };

  const onClickShowMoreDetails = () => {
    setShowMoreDetails(!showMoreDetails);
  };

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-b190eef elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="b190eef"
      data-element_type="section"
      ref={lastElementRef}
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a9c2f1"
          data-id="5a9c2f1"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-455583c elementor-widget elementor-widget-html"
              data-id="455583c"
              data-element_type="widget"
              data-widget_type="html.default"
            >
              <div className="elementor-widget-container">
                <div className="container card-container ocultar-card-deskop">
                  <div className="row">
                    <div className="col-md-4 card-float-int">
                      <img
                        className="card-space-img"
                        src={`data:image/jpeg;base64,${
                          iGlobals.carriers.find(
                            (c) => c.id === plan.providerId
                          )?.imageBase64
                        }`}
                        alt=""
                      />
                      {/* <div className="text-center">
                        <div
                          className={`${
                            iFilters.plansCompareVoice.find(
                              (p) => p === plan.planId
                            )
                              ? "circle-check-on"
                              : "circle-check"
                          } metrics-SearchComparePlan`}
                          onClick={() => onChangeCompareCheckBox(plan.planId)}
                          data-name={plan.planName}
                          data-Price={plan.calculatedPrice.toFixed(2)}
                          data-speed="N/A"
                          data-linephone="N/A"
                          data-term={plan.term}
                        />
                        {iFilters.plansCompareVoice &&
                        iFilters.plansCompareVoice.length >= 1 ? (
                          <span
                            className="card-compareplan link-url"
                            onClick={onClickCompareLink}
                          >
                            Compare Planss
                          </span>
                        ) : (
                          <span className="card-compareplan">
                            Compare Planss
                          </span>
                        )}
                      </div> */}
                    </div>
                    <div className="col-md-8 card-float-int service-type-name">
                      {plan.displayName}
                    </div>
                  </div>
                  <VoicePlanLargeCollapsed
                    plan={plan}
                    showMoreDetails={showMoreDetails}
                    onClickShowMoreDetails={onClickShowMoreDetails}
                    onClickOrderNow={() => onClickOrderNow(plan)}
                    isCompare={isCompare}
                  />
                  {showMoreDetails && (
                    <VoicePlanLargeExpanded
                      plan={plan}
                      onClickShowMoreDetails={onClickShowMoreDetails}
                      onClickOrderNow={() => onClickOrderNow(plan)}
                      isCompare={isCompare}
                    />
                  )}
                </div>
                <div className="container card-container ocultar-card-mobile">
                  <div className="">
                    <VoicePlanLargeMobile
                      plan={plan}
                      onChangeCompareCheckBox={onChangeCompareCheckBox}
                    />
                    <div className="row text-center">
                      {!showMoreDetails ? (
                        <VoicePlanLargeMobileCollapsed />
                      ) : (
                        <VoicePlanLargeMobileExpanded plan={plan} />
                      )}
                      <VoicePlanLargeMobileFoot
                        showMoreDetails={showMoreDetails}
                        onClickShowMoreDetails={onClickShowMoreDetails}
                        onClickOrderNow={() => onClickOrderNow(plan)}
                        isCompare={isCompare}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VoicePlanLarge;
