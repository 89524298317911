import React from 'react';
import { ISurveySearchDTO } from '../../contracts/types';

interface ISummaryHeadProps {
    surverSearch: ISurveySearchDTO;
}

export const SummaryHead: React.FC<ISummaryHeadProps> = ({ surverSearch }) => {
    return (
        <>
            <div className="row titleMainOC">
                <h2> Quote Number: # {surverSearch.orderId}</h2>
            </div>
            <div className="row messageThankOC">
                <h5>Thank you for your quote</h5>
                <p>Below you'll find a brief summary of your quote.</p>
            </div>
            <div className="row columnInfomationOC">
                <div className="col-md-6">
                    {surverSearch.surveyQuestionSectionDTO
                        ?.filter((s) => s.position === 1 || s.position === 2)
                        .map((surveySection) => {
                            return (
                                <>
                                    <div className="squareOCcolumn">
                                        <div className="headTitleOC">{surveySection.name} </div>
                                        {surverSearch.surveyQuestionsResponse &&
                                            surverSearch.surveyQuestionsResponse
                                                .filter((q) => q.section === surveySection.id)
                                                .map((questionResponse) => {
                                                    return (
                                                        <>
                                                            {questionResponse.label.replace('Primary Point of Contact: ', '')}: <span> {questionResponse.value}</span>
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                    </div>
                                </>
                            );
                        })}
                </div>
                <div className="col-md-6">
                    {surverSearch.surveyQuestionSectionDTO
                        ?.filter((s) => s.position === 3 || s.position === 4)
                        .map((surveySection) => {
                            return (
                                <>
                                    <div className="squareOCcolumn">
                                        <div className="headTitleOC">{surveySection.name} </div>
                                        {surverSearch.surveyQuestionsResponse &&
                                            surverSearch.surveyQuestionsResponse
                                                .filter((q) => q.section === surveySection.id && q.label !== 'Secondary' && q.label !== 'State')
                                                .map((questionResponse) => {
                                                    let billingSecondary = '';
                                                    if (questionResponse.label === 'Address') {
                                                        const qrSecondary = surverSearch.surveyQuestionsResponse.find((q) => q.questionId === 6 && q.label === 'Secondary');
                                                        if (qrSecondary && qrSecondary.value && qrSecondary.value !== '') {
                                                            billingSecondary = `, ${qrSecondary.value}`;
                                                        }
                                                    }

                                                    return (
                                                        <>
                                                            {questionResponse.label}:{' '}
                                                            <span>
                                                                {' '}
                                                                {questionResponse.value}
                                                                {billingSecondary}
                                                            </span>
                                                            <br />
                                                        </>
                                                    );
                                                })}
                                    </div>
                                </>
                            );
                        })}
                </div>
            </div>
        </>
    );
};
