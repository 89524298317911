import React, { useEffect, useState } from 'react';
import useAccountLogin from '../../hooks/api/useAccountLogin';
import useAuditReport from '../../hooks/api/useAuditReport';
import { useGlobalState } from '../../state';

interface ILoginProps {

}

const Login: React.FC<ILoginProps> = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iSession, setISession] = useGlobalState('session');

    const [valueEmail, setValueEmail] = useState('');
    const [valuePassword, setValuePassword] = useState('');
    const [valueSendEmail, setValueSendEmail] = useState('');
    const [valueSendPassword, setValueSendPassword] = useState('');

    const [reportType, setReportType] = useState(-1);

    const {
        data
    } = useAccountLogin(valueSendEmail, valueSendPassword);

    const {
        data: dataFile
    } = useAuditReport(reportType);

    useEffect(() => {
        if (dataFile) {
            const blob = new Blob([dataFile], { type: "application/vnd.ms-excel" });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'file2.xlsx';
            link.click();

            setReportType(-1);
        }
    }, [dataFile]);

    useEffect(() => {
        if (data && data.access_token && data.access_token !== '') {
            setISession(
                {
                    ...iSession,
                    "access_token": data.access_token,
                    "expires_in": data.expires_in,
                    "refresh_token": data.refresh_token,
                    "scope": data.scope,
                    "token_type": data.token_type,
                    "isGoogle": false
                });

            window.open(iPlanFilterParams.baseUrl + 'test', "_self");

        }
    }, [data]);

    const onChangeEmail = (e: any) => {
        setValueEmail(e.currentTarget.value);
    };

    const onChangePassword = (e: any) => {
        setValuePassword(e.currentTarget.value);
    };

    const onClickLogin = () => {
        if (valueEmail !== '' && valuePassword !== '') {
            setValueSendEmail(valueEmail);
            setValueSendPassword(valuePassword);
        }
    };

    const onClickReport = () => {
        setReportType(0);
    };

    return (
        <>
            <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        id="email"
                        onChange={onChangeEmail}
                        value={valueEmail}
                        className="form-control"
                        placeholder="Email"
                        autoComplete="off"
                    />
                    {/* <span className="labelQuestionFalse">Not a valid email address</span> */}
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                    <input
                        type="password"
                        id="password"
                        onChange={onChangePassword}
                        value={valuePassword}
                        className="form-control"
                        placeholder="***"
                        autoComplete="off"
                    />
                </div>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3" onClick={onClickLogin}>Login</button>
            </div>
            {
                data && !data.isOk && data.message !== '' &&
                <span className="labelQuestionFalse">{data.message}</span>
            }
            <button type="button" className="btn btn-primary mb-3" onClick={onClickReport}>Report</button>
        </>
    );
};

export default Login;