import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IAuditVisitor } from "../../contracts/types";
import { useGlobalState } from '../../state';

export default function useAuditSaveVisitor(parAuditVisitor: IAuditVisitor, saveVisitor: boolean, referred: string | null) {
	const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');
	const api = useServiceApi();

	const doRequest = async (): Promise<IAuditVisitor> => {
		parAuditVisitor.referred = referred ? referred : '';

		const { data } = await api.post<IAuditVisitor>(`${ApiEndPoints.GetAuditSaveVisitor}`, parAuditVisitor);

		setIAuditVisitor(
			{
				...iAuditVisitor,
				id: data.id,
			});

		return iAuditVisitor;
	};

	return useQuery<IAuditVisitor>(
		['saveAuditVisitor', parAuditVisitor.ip],
		() => doRequest(), {
		staleTime: Infinity,
		retry: 1,
		enabled: iAuditVisitor.id === -1 && iAuditVisitor.ip !== '' && saveVisitor
	}
	);
}
