import React from 'react';

interface IInternetPlanLargeMobileCollapsedProps {

}

const InternetPlanLargeMobileCollapsed: React.FC<IInternetPlanLargeMobileCollapsedProps> = () => {

    return (
        <>

        </>
    );
};

export default InternetPlanLargeMobileCollapsed;
