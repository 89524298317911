import React from 'react';
import { EnumPlanType, ISurveySearchDTO } from '../../contracts/types';
import { SummaryBodyInternet } from './summaryBodyInternet';
import { SummaryBodyVoice } from './summaryBodyVoice';

interface ISummaryBodyProps {
    surverSearch: ISurveySearchDTO;
}

export const SummaryBody: React.FC<ISummaryBodyProps> = ({ surverSearch }) => {
    return (
        <>
            {(surverSearch.planTypeCode === EnumPlanType.BUSINESS_BUNDLES || surverSearch.planTypeCode === EnumPlanType.BUSINESS_INTERNET) && (
                <>
                    {surverSearch.orderAddressesDTO.map((orderAddress) => {
                        return <SummaryBodyInternet orderAddress={orderAddress} />;
                    })}
                </>
            )}
            {(surverSearch.planTypeCode === EnumPlanType.BUSINESS_BUNDLES || surverSearch.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE) && <SummaryBodyVoice surverSearch={surverSearch} />}
        </>
    );
};
