import React, { useEffect, useState } from "react";
import {
  EnumSortBy,
  EnumViewPlansType,
  IResponseVoicePlansDTO,
  ISortBy,
} from "../../../contracts/types";
import useVoicePlans from "../../../hooks/api/useVoicePlans";
import { useGlobalState } from "../../../state";
import VoicePlanLarge from "../voice/voicePlanLarge.comp";
import Select from "../../select.comp";

interface ICompareCardsVoiceProps {}

const CompareCardsVoice: React.FC<ICompareCardsVoiceProps> = () => {
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [plansData, setPlansData] = useState<IResponseVoicePlansDTO[]>([]);

  const { data } = useVoicePlans(EnumViewPlansType.LARGE);

  useEffect(() => {
    if (data) {
      setPlansData(data.lstResponseVoicePlansDTO);
    }
  }, [data]);

  const onChangeSort = (e: any) => {
    setIFilters({
      ...iFilters,
      sortByCompare: e.value,
    });

    setPlansData(plansData);
  };

  const VOICE_SORT_BY: ISortBy[] = [
    { value: EnumSortBy.NONE, label: "Select One" },
    { value: EnumSortBy.LOW_TO_HIGH_PRICE, label: "Price: low to high" },
    { value: EnumSortBy.HIGH_TO_LOW_PRICE, label: "Price: high to low" },
  ];

  let count = 1;

  return (
    <>
      {plansData && iFilters.plansCompareVoice.length < plansData.length && (
        <div className="container">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-cd94392 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="cd94392"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5629f7e"
                data-id="5629f7e"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-faf724e elementor-widget elementor-widget-html"
                    data-id="faf724e"
                    data-element_type="widget"
                    data-widget_type="html.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="container text-center">
                        <span className="these-available">
                          THESE PLANS ARE ALSO
                          <br />
                          AVAILABLE IN YOUR AREA
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div
              id="filterby-compare-plan"
              className="col-12 col-md-6 align-items-center"
            >
              <span className="filtersortby">Sort by:</span>
              <Select
                optionsSelect={VOICE_SORT_BY}
                onChange={onChangeSort}
                value={iFilters.sortByCompare.toString()}
              />
            </div>
          </div>
        </div>
      )}
      {plansData &&
        plansData
          .sort((a, b) => {
            switch (iFilters.sortByCompare) {
              case EnumSortBy.LOW_TO_HIGH_PRICE:
                return a.calculatedPrice - b.calculatedPrice;
              case EnumSortBy.HIGH_TO_LOW_PRICE:
                return b.calculatedPrice - a.calculatedPrice;
              default:
                return a.calculatedPrice - b.calculatedPrice;
            }
          })
          .map((plan, index) => {
            const exists = iFilters.plansCompareVoice.find(
              (idPlan) => idPlan === plan.planId
            );

            if (count <= 3 && !exists) {
              count++;
              return (
                <VoicePlanLarge key={index} plan={plan} isCompare={true} />
              );
            }

            return <></>;
          })}
    </>
  );
};

export default CompareCardsVoice;
