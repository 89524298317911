import { useQuery } from "react-query";
import { ApiEndPoints, useServiceApi } from "../../api"
import { IAuditReportDTO } from "../../contracts/types";

export default function useAuditReport(reportType: number) {
	const api = useServiceApi();

	const doRequest = async (): Promise<any> => {
		const auditReportDTO: IAuditReportDTO = {
			reportType: reportType,
			FileName: '',
			FileData: '',
			FileType: ''
		};
		
		const { data } = await api.post<any>(`${ApiEndPoints.GetAuditDownloadReport}`, auditReportDTO, { responseType: 'blob' });

		return data;
	};

	return useQuery<any>(
		['auditDownloadReport'],
		() => doRequest(), {
			retry: 1,
			enabled: reportType!==-1
	});
}
