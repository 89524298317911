import React, { useEffect } from 'react';
import { EnumPlanType, ISurveySearchDTO } from '../../contracts/types';

interface ISummaryFootProps {
    surverSearch: ISurveySearchDTO;
}

export const SummaryFoot: React.FC<ISummaryFootProps> = ({ surverSearch }) => {
    let pricePhone = 0;
    let priceFeatures = 0;

    useEffect(() => {
        surverSearch.equipmentsVoice?.forEach((e) => {
            pricePhone += e.equipmentNumber * e.equipmentPrice;
        });

        surverSearch.featuresPlan?.forEach((f) => {
            priceFeatures += f.featureNumber * f.price;
        });
    }, []);

    return (
        <div className="totalResult">
            <span>Total: </span>$&nbsp;
            {surverSearch.planTypeCode === EnumPlanType.BUSINESS_INTERNET
                ? surverSearch.orderAddressesDTO &&
                  surverSearch.orderAddressesDTO.length > 0 &&
                  surverSearch.orderAddressesDTO
                      .map((address) => address.responseInternetPlans?.planPrice)
                      .reduce(function (a, b) {
                          return (a ? a : 0) + (b ? b : 0);
                      })
                      ?.toFixed(2)
                : ((surverSearch.planVoice ? surverSearch.planVoice.calculatedPrice : 0) + pricePhone + priceFeatures).toFixed(2)}
        </div>
    );
};
