import React from 'react';
import { IQuestionDTO, IQuestionOptionDTO } from '../../../contracts/types';

interface IQuestionButtonProps {
    questionDTO: IQuestionDTO;
    onChange: (value: string, billingSmarty: any) => void;
}

const QuestionButton: React.FC<IQuestionButtonProps> = ({ questionDTO, onChange }) => {

    const onClickQuestion = (value: string) => {
        onChange(value, null);
    };

    return (
        <div className="row buttonWizzardInside">
            <div className="colButtonsInside">
                {
                    questionDTO.questionOptionsDTO.map((option: IQuestionOptionDTO) => (

                        <button className="orderButtonQuestions"
                            onClick={() => onClickQuestion(option.id.toString())}
                        >
                            {option.label}
                        </button>
                    ))
                }
            </div>
        </div>
    );
};

export default QuestionButton;