import React from 'react';
import { EnumPlanType } from '../../../contracts/types';
import { useGlobalState } from '../../../state';
import CompareBundle from './compareBundle.comp';
import CompareInternet from './compareInternet.comp';
import CompareVoice from './compareVoice.comp';

interface ICompareProps {

}

const Compare: React.FC<ICompareProps> = () => {
    const [iFilters] = useGlobalState('filters');

    return (
        <>
            <section className="elementor-section elementor-top-section elementor-element elementor-element-c52c9cf elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c52c9cf" data-element_type="section">
                <div className="elementor-container elementor-column-gap-default">
                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-30aff68" data-id="30aff68" data-element_type="column">
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-3e383e6 elementor-widget elementor-widget-html" data-id="3e383e6" data-element_type="widget" data-widget_type="html.default">
                                <div className="elementor-widget-container">
                                    <div className="table-containerCompare">
                                        {
                                            iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && <CompareInternet />
                                        }
                                        {
                                            iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE && <CompareVoice />
                                        }
                                        {
                                            iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && <CompareBundle />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Compare;
