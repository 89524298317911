import React from 'react';
import { EnumPropertyId, IResponseInternetPlansDTO } from '../../../contracts/types';
import { useGlobalState } from '../../../state';
import LearnMoreProperty from './learnMoreProperty.com';
import LearnMorePropertyValue from './learnMorePropertyValue.com';
import ClickToDialLearnMore from '../../tbi/clickToDIal/clickToDialLearnMore';

interface ILearnMoreInternetProps {
    plan: IResponseInternetPlansDTO;
}

const LearnMoreInternet: React.FC<ILearnMoreInternetProps> = ({ plan }) => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals] = useGlobalState('globals');

    const onClickBwCalculator = () => {
        window.open(iPlanFilterParams.baseUrl + 'bw-calculator', "_self");
    }

    const onClickOpenUrl = (code: EnumPropertyId) => {
        const productProperty = iGlobals.productProperties.find(pp => pp.productId === plan.productId && pp.propertyCode === code);
        const url = productProperty ? productProperty.value : '';

        if (url !== '') {
            window.open(url, "_blank");
        }
    };



    return (
        <div className="container text-center container-size">
            <div className="row">
                <div className="container text-center container-size">
                    <div className="row">
                        <div className="col-md-5 col-5 col-3-size">
                            <div className="col-11-5 my-2 p-2-7  bg-light rounded-3 shadow">
                                <span className="learn-texttitle">{plan.providerName}</span>
                                <br />
                                <span className="learn-textsubtitle">{plan.planTypeName}</span>
                            </div>
                            <div className="col-11-5 my-4 p-2-7  bg-light rounded-3 shadow">
                                <span className="learn-texttitle2">Starting at</span>
                                <br />
                                <span className="learn-numberprice">${plan.planPrice.toFixed(2)}</span>
                                <span className="learn-texttitle2">mo</span>
                            </div>
                            <div className="col-11-5 my-4 p-2-7  bg-light rounded-3 shadow">
                                <span className="learn-texttitle2">MAXIMUM SPEED</span>
                                <div className="size-learnmore">
                                    <div className="card-icon-bolt-learnmore float-start" />
                                    <div>
                                        <div className="card-icon-down float-start" />
                                        <span className="card-max-term">{plan.planDownloadSpeed}</span>
                                        <div className="card-icon-mbps float-end" />
                                    </div>
                                    <hr className="card-hr" />
                                    <div>
                                        <div className="card-icon-up float-start" />
                                        <span className="card-max-term">{plan.planUploadSpeed}</span>
                                        <div className="card-icon-mbps float-end" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7-1 col-7 my-2 ">
                            <img className="rounded image-woman-size rounded-3" src="/wp-content/wiresresources/images/v2/learn-more/imagen-men.jpg" alt='' />
                        </div>
                        <div className="col-md-4-9 my-3 bg-light p-5 rounded-3 shadow">
                            <span className="learn-texttitle2">CONECTED DEVICES</span>
                            <br />
                            <br />
                            <button type="button" className="btn btn-primary" onClick={onClickBwCalculator}>KNOW MORE HERE</button>
                        </div>
                        <LearnMoreProperty title='THIS PLAN<br />WORKS<br />BEST FOR' propertyCode={EnumPropertyId.BEST_FOR} plan={plan} />
                        <div className="col-md-4-9 my-3 bg-light p-5 rounded-3 shadow">
                            <div className="card-icon-asymmetrical float-start" />
                            <div>
                                <span className="learn-texttitle2">SYMMETRICAL/ASYMMETRICAL<br />CONNECTION</span>
                            </div>
                            <div className='space-text-cardlearmore'>
                                <LearnMorePropertyValue propertyCode={EnumPropertyId.SYM_ASYM_CONNECTION} plan={plan} isValueLong={false} />
                            </div>
                        </div>
                        <LearnMoreProperty title='WHY<br />THIS<br />CARRIER' propertyCode={EnumPropertyId.WHYTHISCARRIER} plan={plan} />
                        <div className="col-md-4-9 my-3 bg-light p-5 rounded-3 shadow">
                            <div className="card-icon-learn-dedicated float-start" />
                            <div>
                                <span className="learn-texttitle2">DEDICATED/SHARED<br />INTERNET</span>
                            </div>
                            <div className='space-text-cardlearmore'>
                                <LearnMorePropertyValue propertyCode={EnumPropertyId.DEDICATED_SHARED_INTERNET} plan={plan} isValueLong={false} />
                            </div>
                        </div>
                        <LearnMoreProperty title='INSTALLATION' propertyCode={EnumPropertyId.INSTALLATION} plan={plan} />
                        <div className="col-md-4-9 my-3 bg-light p-5 rounded-3 shadow">
                            <div className="row">
                                <div className="col-5">
                                    <div>
                                        <span className="learn-texttitleterm">TERM</span>
                                        <div className="card-icon-learn-calendar" />
                                    </div>
                                </div>
                                <div className="col-6 float-end">
                                    <div>
                                        <span className="learn-numberprice">{plan.planTerm}</span>
                                        <br />
                                        <span className="learn-textmonths">Months</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LearnMoreProperty title='STATIC IP' propertyCode={EnumPropertyId.STATIC_IP} plan={plan} />
                        <div className="col-md-3-9 my-3 bg-light p-5 rounded-3 shadow">
                            <div className="col-3">
                                <div className="card-icon-learn-techsupport float-start" />
                            </div>
                            <div className="col-9 textalign-tec-price">
                                <span className="learn-texttitle2">TECH<br />SUPPORT</span>
                                <br />
                                <br />
                                <span><LearnMorePropertyValue propertyCode={EnumPropertyId.TECH_SUPPORT} plan={plan} isValueLong={false} /></span>
                            </div>
                        </div>
                        <div className="col-md-3-9 my-3 bg-light p-5 rounded-3 shadow space-col-md-3-9">
                            <div className="col-3">
                                <div className="card-icon-learn-technology float-start" />
                            </div>
                            <div className="col-9 textalign-tec-price">
                                <span className="learn-texttitle2">TECHNOLOGY</span>
                                <br />
                                <br />
                                <span>{plan.planTechnologyName}</span>
                            </div>
                        </div>
                        <div className="col-md-3-9 my-3 bg-light p-5 rounded-3 shadow space-col-md-3-9">
                            <div>
                                <div className="card-icon-learn-priceguarantee float-start" />
                            </div>
                            <div className="col-9 textalign-tec-price">
                                <span className="learn-texttitle2">PRICE<br />GUARANTEE</span>
                                <br />
                                <br />
                                <span><LearnMorePropertyValue propertyCode={EnumPropertyId.PRICE_GUARANTEE} plan={plan} isValueLong={false} /></span>
                            </div>
                        </div>
                        <div className="col-md-7-1 col-7 my-2 col-3-size">
                            <img className="rounded image-woman-size rounded-3" src="/wp-content/wiresresources/images/v2/learn-more/learn-more-two-purple.png" alt='' />
                        </div>
                        <div className="col-md-5 col-5">
                            <div className="col-11-5-1 my-3 p-5 bg-purple rounded-3 shadow">
                                <span className="learn-texttitlepurple">CONTRACT TERMS</span>
                                <div id="learn-contracttextsubtitlepurple" className="learn-textsubtitlepurple-contract">
                                    <span className="learn-textsubtitlepurple-contract-terms">NA</span>
                                </div>
                            </div>
                            <div className="col-11-5-1 my-3 p-5 bg-purple rounded-3 shadow">
                                <span className="learn-texttitlepurple">UNLIMITED<br />INTERNET DATA</span>
                                <br />
                                <br />
                                <span className="learn-textsubtitlepurple"><LearnMorePropertyValue propertyCode={EnumPropertyId.UNLIMITED_INTERNET} plan={plan} isValueLong={false} /></span>
                            </div>
                            <div className="col-11-5-1 my-3 p-5 bg-purple rounded-3 shadow">
                                <span className="learn-texttitlepurple">EQUIPMENT<br />INCLUDED</span>
                                <br />
                                <span className="learn-textsubtitlepurple"><LearnMorePropertyValue propertyCode={EnumPropertyId.EQUIPMENT_INCLUDED} plan={plan} isValueLong={false} /></span>
                            </div>
                        </div>
                        <div className="col-md-5-9 my-3 p-3 bg-purple rounded-3 shadow">
                            <div className="col-4 float-start ">
                                <span className="learn-texttitlepurple">CUSTOMER<br />CARE</span>
                            </div>
                            <div className="col-8 learn-spaceparagraphpurple1">
                                <span className="learn-textparagrappurple">
                                    <div className="text-scroll-seccion-purple">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.CUSTOMERCARE} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 p-3 bg-purple rounded-3 shadow space-col-md-5-9">
                            <div className="col-4 float-start">
                                <span className="learn-texttitlepurple">INTERNET<br />SECURITY</span>
                            </div>
                            <div className="col-8 learn-spaceparagraphpurple1">
                                <span className="learn-textparagrappurple">
                                    <div className="text-scroll-seccion-purple">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.INTERNET_SECURITY} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12 my-3 p-3 bg-purple rounded-3 shadow">
                            <div className="col-2 float-start">
                                <span className="learn-texttitlepurple">RELIABILITY<br />AND SUPPORT<br />CARE</span>
                            </div>
                            <div className="col-10 learn-spaceparagraphpurple2">
                                <span className="learn-textparagrappurple">
                                    <div className="text-scroll-seccion-purple">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.RELIABILITY_AND_SUPPORT} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-12 my-3 p-3 bg-purple rounded-3 shadow">
                            <div className="col-2 float-start">
                                <span className="learn-texttitlepurple">WIRELESS<br />NETWORKING</span>
                            </div>
                            <div className="col-10 learn-spaceparagraphpurple2">
                                <span className="learn-textparagrappurple">
                                    <div className="text-scroll-seccion-purple">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.WIRELESS_NETWORKING} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-5-9 my-3 p-3 bg-purple rounded-3 shadow">
                            <div className="col-4 float-start">
                                <span className="learn-texttitlepurple">TRIAL<br />PERIOD OR<br />MONEY-BACK<br />GUARANTEE</span>
                            </div>
                            <div className="col-8 learn-spaceparagraphpurple1">
                                <span className="learn-textparagrappurple">
                                    <div className="text-scroll-seccion-purple">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.TRIAL_PERIOD_OR_MONEY_BACK_GUARANTEE} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 p-3 bg-purple rounded-3 shadow space-col-md-5-9 ">
                            <div className="col-4 float-start space-bg-purple-text">
                                <span className="learn-texttitlepurple">HOSPOT<br />WIFI</span>
                            </div>
                            <div className="col-8 learn-spaceparagraphpurple1">
                                <span className="learn-textparagrappurple">
                                    <div className="text-scroll-seccion-purple">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.HOTSPOT_WIFI} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6 my-3 p-3 bg-purple rounded-3 shadow">
                            <div className="col-3 float-start">
                                <span className="learn-texttitlepurple">EMAIL<br />ACCOUNTS<br />INCLUDED</span>
                            </div>
                            <div className="learn-spaceparagraphpurple1">
                                <span className="learn-textparagrappurple ">
                                    <div className="text-scroll-seccion-purple-email">
                                        <LearnMorePropertyValue propertyCode={EnumPropertyId.EMAIL_ACCOUNTS_INCLUDED} plan={plan} />
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-12 my-3 bg-purple p-3 rounded-3 shadow">
                                <div className="space-bg-purple-text">
                                    <span className="learn-texttitlepurple">
                                        WEBSITE
                                        <br />
                                        <br />
                                    </span>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => onClickOpenUrl(EnumPropertyId.WEB_SITE)}>CLICK HERE</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="col-12 my-3 bg-purple p-3 rounded-3 shadow">
                                <div className="space-bg-purple-text">
                                    <span className="learn-texttitlepurple">VIEW MORE<br />DETAILS<br /></span>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => onClickOpenUrl(EnumPropertyId.LEARN)}>CLICK HERE</button>
                                </div>
                            </div>
                        </div>
                        <ClickToDialLearnMore />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnMoreInternet;
