import React, { useState } from "react";

import {
  EnumPropertyId,
  IResponseBundlePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import equipment from "../../../images/equipment.png"; // with import
import professionalInstall from "../../../images/professional_install.png"; // with import

interface IBundlePlanLargeMobileExpandedProps {
  plan: IResponseBundlePlansDTO;
}

const BundlePlanLargeMobileExpanded: React.FC<
  IBundlePlanLargeMobileExpandedProps
> = ({ plan }) => {
  const [iGlobals] = useGlobalState("globals");

  const [seeMoreTechSupport, setSeeMoreTechSupport] = useState(false);
  const [seeMoreSalesDescription, setSeeMoreSalesDescription] = useState(false);

  const onClickSeeMoreTechSupport = () => {
    setSeeMoreTechSupport(!seeMoreTechSupport);
  };

  const onClickSeeMoreSalesDescription = () => {
    setSeeMoreSalesDescription(!seeMoreSalesDescription);
  };

  return (
    <>
      <hr className="m-card-hr" />
      <div className="col-12">
        <table className="table bundle-card-table-second">
          <tbody className="card-more-table">
            <tr>
              <td>
                <img src={professionalInstall} alt="" />
              </td>
            </tr>
            <tr>
              <td>Professional Installation</td>
            </tr>
            <tr>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.INSTALLATION
                    )?.value
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <img src={equipment} alt="" />
              </td>
            </tr>
            <tr>
              <td>Equipment Fee</td>
            </tr>
            <tr>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.EQUIPMENT_FEE
                    )?.value
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th></th>
            </tr>
          </thead>
          <tbody className="card-more-table">
            <tr className="">
              <td>
                <div className="card-view-title more-card-view-title">
                  WHY THIS CARRIER
                </div>
              </td>
            </tr>
            <tr className="">
              <td>
                <div className="card-recommended more-card-view-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
                    )?.value
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BundlePlanLargeMobileExpanded;
