import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { EnumViewPlansLargeType, IPlanFilterParamsInternet, IPlansCompare } from '../../contracts/types';
import { redirectCompare } from '../../helpers/redirectPages';
import { useGlobalState } from '../../state';

interface IItemAddressProps {
    planInternet: IPlanFilterParamsInternet;
    index: number;
}

const ItemAddress: React.FC<IItemAddressProps> = ({ planInternet, index }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters, setIFilters] = useGlobalState('filters');
    const queryClient = useQueryClient();
    const secondary = planInternet.secondary !== '' ? `, ${planInternet.secondary}` : '';

    useEffect(() => {
        planInternet.openedGroupAddressPlans = false;

        if (window.location.pathname.includes('multiple')) {
            setIPlanFilterParams({
                ...iPlanFilterParams,
                "removeQuery": false
            });
        }
    }, []);

    const onClickSelectAddress = (e: any, idMultiple: number) => {
        let someSelectedAddress = false;
        let multipleParamSelect = iPlanFilterParams.multipleAddresses.multipleInternetParams.find(m => m.idMultiple === idMultiple);
        if (multipleParamSelect) {
            multipleParamSelect.selectAddress = e.target.checked;

            let multipleParams = iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(m => m.idMultiple !== idMultiple);
            multipleParams.push(multipleParamSelect);

            const lengthSelected = multipleParams.filter(m => m.selectAddress === true).length;
            if (lengthSelected > 0) {
                someSelectedAddress = true;
            }

            let numberChecked = multipleParams.filter(m => m.selectAddress === true).length;
            let checkAllAddress = false;

            if (numberChecked === multipleParams.length) {
                checkAllAddress = true;
            }

            setIPlanFilterParams({
                ...iPlanFilterParams,
                multipleAddresses: {
                    ...iPlanFilterParams.multipleAddresses,
                    "multipleInternetParams": [...multipleParams]
                },
                "someSelectedAddress": someSelectedAddress,
                "selectedAllAddress": checkAllAddress
            });
        }
    };

    const onClickDeleteAddress = () => {
        const arrayMultipleParams = iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(plan => plan.idMultiple !== planInternet.idMultiple);

        if (arrayMultipleParams.length === 0) {
            setIPlanFilterParams({
                ...iPlanFilterParams,
                idAddressMultiple: -1,
                multipleAddresses: {
                    ...iPlanFilterParams.multipleAddresses,
                    multipleInternetParams: arrayMultipleParams
                }
            });
        }
        else {
            if (arrayMultipleParams.length === 1) {
                setIPlanFilterParams({
                    ...iPlanFilterParams,
                    multipleAddresses: {
                        ...iPlanFilterParams.multipleAddresses,
                        multipleInternetParams: arrayMultipleParams
                    },
                    internetParams: arrayMultipleParams[0]
                });
            }
            else {
                setIPlanFilterParams({
                    ...iPlanFilterParams,
                    multipleAddresses: {
                        ...iPlanFilterParams.multipleAddresses,
                        multipleInternetParams: arrayMultipleParams
                    }
                });
            }
        }
    };

    const onClickChangePlan = (idMultiple: number) => {
        planInternet.openedGroupAddressPlans = true;

        setIPlanFilterParams({
            ...iPlanFilterParams,
            'internetParams': planInternet,
            'idAddressMultiple': idMultiple,
            "removeQuery": true,
        });

        setIFilters({
            ...iFilters,
            "viewPlansLargeTypeInternet": EnumViewPlansLargeType.MULTIADDRESS
        });

        setTimeout(function () {
            window.open(iPlanFilterParams.baseUrl + 'search', "_self");
        }, 100);
    };

    const onClickCompareLink = (e: any, internetParams: IPlanFilterParamsInternet) => {
        if (internetParams.plan) {
            const planComp: IPlansCompare = {
                idPlan: internetParams.plan.planId,
                codeProvider: internetParams.plan.codeProvider,
                planName: `${internetParams.plan.providerName} ${internetParams.plan.planDownloadSpeed} ${internetParams.plan.planTechnologyName} ${internetParams.plan.planTerm}mo`,
                planPrice: internetParams.plan.planPrice
            };

            let addressCompare = iFilters.plansCompareMultiple.find(p => p.address === internetParams.address);
            if (!addressCompare) {
                addressCompare = {
                    address: internetParams.address,
                    plansCompare: []
                };
            }

            addressCompare.plansCompare = [planComp];
            const addressCompareMinus = iFilters.plansCompareMultiple.filter(p => p.address !== internetParams.address);
            addressCompareMinus.push(addressCompare);

            setIFilters({
                ...iFilters,
                plansCompareMultiple: addressCompareMinus,
            });
        }

        setIPlanFilterParams({
            ...iPlanFilterParams,
            'internetParams': { ...internetParams },
            idAddressMultiple: internetParams.idMultiple,
        });

        redirectCompare(iPlanFilterParams.baseUrl);
    };

    return (
        <tr>
            <td><input type="checkbox" onClick={(e) => onClickSelectAddress(e, planInternet.idMultiple)} checked={planInternet.selectAddress ? planInternet.selectAddress : false} /></td>
            <td>
                {index}
                {
                    !planInternet.plan &&
                    <div className="tooltipWire">
                        <a href="javascript:void(0)" onClick={() => onClickChangePlan(planInternet.idMultiple)}>
                            <img className="imgPendingMultiple" src={`${iPlanFilterParams.imagesUrl}/multiAlert.png?_t=1625150239`} />
                        </a>
                        <span className="tooltiptextWire">This address does not have an associated plan. Please choose one.</span>
                    </div>
                }
            </td>
            <td>{planInternet.streetLine}{secondary}, {planInternet.city}, {planInternet.state} {planInternet.zipCode}</td>
            <td>
                <a href="javascript:void(0)" onClick={onClickDeleteAddress}><img src={`${iPlanFilterParams.imagesUrl}/iconTrash.png`} /></a>
                <a href="javascript:void(0)" onClick={(e: any) => onClickCompareLink(e, planInternet)}><img src={`${iPlanFilterParams.imagesUrl}/iconChangePlan.png`} /></a>
            </td>
            <td>
                {
                    planInternet.plan
                        ?
                        <>
                            <span>
                                {
                                    planInternet.plan.internalPlanId === -1
                                        ? `(${planInternet.plan.planTypeName})`
                                        : `${planInternet.plan.providerName}
                                                ${planInternet.plan.planDownloadSpeed < 1000 ? planInternet.plan.planDownloadSpeed : planInternet.plan && planInternet.plan.planDownloadSpeed / 1000}${planInternet.plan.planDownloadSpeed < 1000 ? 'Mbps' : 'Gbps'}
                                                ${planInternet.plan.planTechnologyName}
                                                ${planInternet.plan.planTerm}mo:
                                                $${planInternet.plan.planPrice.toFixed(2)}`
                                }
                            </span>
                            <span> <a href="javascript:void(0)" onClick={() => onClickChangePlan(planInternet.idMultiple)}>Change</a></span>
                        </>
                        :
                        <>
                            <span>
                                <a href="javascript:void(0)" onClick={() => onClickChangePlan(planInternet.idMultiple)}>Choose one</a>
                                {
                                    planInternet.plansByCodeProvider.length !== 3 &&
                                    <img src={`${iPlanFilterParams.imagesUrl}/loadingMultiple.gif`} height={20} alt="" />
                                }
                            </span>
                        </>
                }
            </td>
        </tr>
    );
};

export default ItemAddress;