import React, { useEffect, useState } from 'react';
import { EnumPlanType, EnumViewPlansType } from '../../contracts/types';
import { useGlobalState } from '../../state';
import ItemPlansCompareInternet from './itemPlanCompareInternet.comp';
import useVoicePlansPagination from './../../hooks/api/useVoicePlansPagination';
import ItemPlansCompareVoice from './itemPlanCompareVoice.comp';
import ItemPlansCompareBundle from './itemPlanCompareBundle.comp';
import { redirectCompare } from '../../helpers/redirectPages';

interface IListPlanCompareProps {

}

const ListPlansCompare: React.FC<IListPlanCompareProps> = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters, setIFilters] = useGlobalState('filters');

    const [cssCollapsed, setCssCollapsed] = useState('');

    const { plans: plansVoice } = useVoicePlansPagination(EnumViewPlansType.LARGE, 1);

    useEffect(() => {
        if (iFilters.tabShoppingCart) {
            if ((iFilters.tabShoppingCart === EnumPlanType.BUSINESS_INTERNET && iFilters.plansCompareInternet.length === 0) ||
                (iFilters.tabShoppingCart === EnumPlanType.BUSINESS_VOICE_PHONE && iFilters.plansCompareVoice.length === 0) ||
                (iFilters.tabShoppingCart === EnumPlanType.BUSINESS_BUNDLES && iFilters.plansCompareBundle.length === 0)
            ) {
                setCssCollapsed('-collapse');
            }
            else {
                setCssCollapsed('');
            }
        }
    }, [iFilters.tabShoppingCart, iFilters.plansCompareInternet.length, iFilters.plansCompareVoice.length, iFilters.plansCompareBundle.length]);

    const onClickTab = (planType: EnumPlanType) => {
        setIFilters({
            ...iFilters,
            tabShoppingCart: planType,
        });
    }

    const onClickCompareNow = () => {
        if ((iFilters.tabShoppingCart === EnumPlanType.BUSINESS_INTERNET && iFilters.plansCompareInternet.length > 0) ||
            (iFilters.tabShoppingCart === EnumPlanType.BUSINESS_VOICE_PHONE && iFilters.plansCompareVoice.length > 0) ||
            (iFilters.tabShoppingCart === EnumPlanType.BUSINESS_BUNDLES && iFilters.plansCompareBundle.length > 0)
        ) {
            redirectCompare(iPlanFilterParams.baseUrl);
        }
    }

    return (
        <div className={`comparePlan-popup${cssCollapsed}`} id="myCompare">
            <div className="row containerSelected">
                <div className="col-4">
                    <button type="button" className="btn btn-primary" onClick={() => onClickTab(EnumPlanType.BUSINESS_INTERNET)}>Selcted Business Internet Plans</button>
                </div>
                <div className="col-4">
                    <button type="button" className="btn btn-primary" onClick={() => onClickTab(EnumPlanType.BUSINESS_VOICE_PHONE)}>Selected Business Phone Voice</button>
                </div>
                <div className="col-4">
                    <button type="button" className="btn btn-primary" onClick={() => onClickTab(EnumPlanType.BUSINESS_BUNDLES)}>Selected Bundles</button>
                </div>
            </div>
            <div className={`container Scroll-Container${cssCollapsed}`}>
                <table className="table compare-table">
                    <tbody>
                        {
                            iFilters.tabShoppingCart === EnumPlanType.BUSINESS_INTERNET &&
                            iFilters.plansCompareInternet.map((planCompare, index) => (
                                <ItemPlansCompareInternet key={index} planCompare={planCompare} />
                            ))
                        }
                        {
                            iFilters.tabShoppingCart === EnumPlanType.BUSINESS_VOICE_PHONE &&
                            iFilters.plansCompareVoice.map((idPlanCompare, index) => {
                                const plan = plansVoice.find(p => p.planId === idPlanCompare) || null;
                                if (plan) {
                                    return <ItemPlansCompareVoice key={index} plan={plan} />;
                                }
                            })
                        }
                        {
                            iFilters.tabShoppingCart === EnumPlanType.BUSINESS_BUNDLES &&
                            iFilters.plansCompareBundle.map((planCompare, index) => (
                                <ItemPlansCompareBundle key={index} planCompare={planCompare} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <button type="button" className="btn btn-light btnCompare" onClick={onClickCompareNow}>Compare now</button>
        </div>
    );
};

export default ListPlansCompare;
