import React from "react";
import { IFilterCheckbox } from "../../../contracts/types";

interface IFilterListCheckboxProps {
  label: string;
  filterValues: number[];
  options: IFilterCheckbox[];
  onChangeValue: (values: number[]) => void;
}

const FilterListCheckbox: React.FC<IFilterListCheckboxProps> = ({
  label,
  filterValues,
  options,
  onChangeValue,
}) => {
  const cssHreFilter = `metrics-Filter${
    label === "Internet Type" ? "IntType" : label
  }`;

  const onChange = (optionValue: number, e: any) => {
    let filter: number[] = filterValues == null ? [] : filterValues;

    if (e.target.checked) {
      filter.push(optionValue);
    } else {
      filter = filter.filter((t) => t !== optionValue);
    }

    onChangeValue(filter);
  };

  const onClickClearValues = () => {
    onChangeValue([]);
  };

  return (
    <div>
      <h6>{label}</h6>
      {filterValues && filterValues.length > 0 && (
        <div>
          <a
            className="aClear"
            href="javascript:void(0)"
            onClick={() => onClickClearValues()}
          >
            Reset
          </a>
        </div>
      )}
      {options?.map((option: IFilterCheckbox) => (
        <div key={option.value} className="form-inline">
          <input
            className={cssHreFilter}
            type="checkbox"
            checked={filterValues?.includes(option.value)}
            onChange={(e) => {
              onChange(option.value, e);
            }}
          />
          <label className="pl-1 pt-sm-0 pt-1">{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export default FilterListCheckbox;
