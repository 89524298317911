import React from 'react';
import { IQuestionDTO, IQuestionOptionDTO } from '../../../contracts/types';
import { useGlobalState } from '../../../state';
import parse from 'html-react-parser';
import useGetParameters from '../../../hooks/api/useGetParameters';
import LoadingSmall from '../../loadingSmall.comp';

interface IQuestionRadioProps {
    questionDTO: IQuestionDTO;
    onChange: (value: string, billingSmarty: any, isClickCalendly: boolean) => void;
}

const QuestionRadio: React.FC<IQuestionRadioProps> = ({ questionDTO, onChange }) => {

    const [iPlanFilterParams] = useGlobalState('planFilterParams');

    const surveyQuestionsResponse = iPlanFilterParams.surveyResponse.surveyQuestionsResponse.filter(q => q.questionId === questionDTO.id);
    const value = surveyQuestionsResponse.length > 0 ? surveyQuestionsResponse[0].value : questionDTO.defaultValue;
    const orderId = iPlanFilterParams.surveyData.orderId == null ? '' : iPlanFilterParams.surveyData.orderId;

    const onChangeRadio = (e: any) => {
        onChange(e.target.value, null, false);
    };

    const { } = useGetParameters();

    const onClickCalendly = (value: string) => {
        onChange(value, null, true);
    };

    const findToken = (value: string, label: string) => {
        if (label.indexOf('${orderId}') !== -1) {
            if (orderId === 0) {
                return <>{parse(label.replace('${orderId}', ''))}<LoadingSmall /></>
            }

            return parse(label.replace('${orderId}', orderId.toString()));
        }

        else if (label.indexOf('${calendly}') !== -1) {
            return <span className="orderCalendly">
                {label.replace('${calendly}', '')}
                <a href="javascript:void(0)" onClick={() => onClickCalendly(value)}>
                    Schedule
                </a>
            </span>
        }

        return parse(label);
    }

    return (
        <>
            {
                questionDTO.questionOptionsDTO.map((option: IQuestionOptionDTO) => (
                    <div className="row">
                        <div className="checkbox-tick">
                            <label className="male">
                                <input type="radio"
                                    value={option.id}
                                    name={questionDTO.id.toString()}
                                    onChange={onChangeRadio}
                                    checked={value === option.id.toString()}
                                    disabled={(questionDTO.id === 5 && (option.id === 1 || option.id === 3) && iPlanFilterParams.surveyData.disabledTBI) ? true : false}
                                /><span> </span>
                                {
                                    findToken(option.id.toString(), option.label)
                                }
                                {
                                    (questionDTO.id === 5 && (option.id === 1 || option.id === 3) && iPlanFilterParams.surveyData.disabledTBI) ? ' (Monday through Friday 7am-9pm CST)' : ''
                                }
                                <br />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                ))
            }
        </>
    );
};

export default QuestionRadio;