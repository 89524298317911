import React from 'react';
import { EnumPlanType, IQuestionDTO, ISmartyResponseDTO, ISurveyQuestionResponse, IWrSettings } from '../../../contracts/types';
import QuestionString from './questionString.comp';
import QuestionRadio from './questionRadio.comp';
import { useGlobalState } from '../../../state';
import parse from 'html-react-parser';
import QuestionButton from './questionButton.comp';
import QuestionBilling from './questionBilling.comp';

interface IQuestionProps {
    wrSettings: IWrSettings;
    questionDTO: IQuestionDTO;
    isOnlyQuestion: boolean;
}

const Question: React.FC<IQuestionProps> = ({ wrSettings, questionDTO, isOnlyQuestion }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters] = useGlobalState('filters');

    const onChange = (value: string, billingSmarty: ISmartyResponseDTO, isClickCalendly: boolean = false, billingAddresSelected: string = '') => {
        let arraySurveyQuestionResponse: ISurveyQuestionResponse[] = iPlanFilterParams.surveyResponse.surveyQuestionsResponse === null ? [] : iPlanFilterParams.surveyResponse.surveyQuestionsResponse;
        let surveyQuestionResponse = arraySurveyQuestionResponse.find((q) => q.questionId === questionDTO.id);
        let isValidData: boolean | null = null;

        if (questionDTO.required) {
            isValidData = true;

            if (value === '') {
                isValidData = false;
            }

            if (isValidData && questionDTO.type === 'email' && value !== '') {
                let lastAtPos = value.lastIndexOf('@');
                let lastDotPos = value.lastIndexOf('.');

                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && value.length - lastDotPos > 2)) {
                    isValidData = false;
                }
            } else if (isValidData && questionDTO.type === 'phone' && value !== '' && value.includes('_')) {
                isValidData = false;
            }
        } else if (questionDTO.type === 'email') {
            isValidData = false;
            if (value !== '') {
                let lastAtPos = value.lastIndexOf('@');
                let lastDotPos = value.lastIndexOf('.');

                isValidData = true;
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && value.indexOf('@@') === -1 && lastDotPos > 2 && value.length - lastDotPos > 2)) {
                    isValidData = false;
                }
            }
        } else {
            isValidData = true;
        }

        if (surveyQuestionResponse) {
            surveyQuestionResponse.value = value;
            surveyQuestionResponse.isValid = isValidData;
        } else {
            arraySurveyQuestionResponse.push({ questionId: questionDTO.id, value: value, label: '', isValid: isValidData });
        }

        if (questionDTO.type === 'billing' && billingSmarty) {
            const smarty: ISmartyResponseDTO = {
                street_line: billingSmarty.street_line,
                zipcode: billingSmarty.zipcode,
                city: billingSmarty.city ? billingSmarty.city : '',
                state: billingSmarty.state ? billingSmarty.state : '',
                entries: 0,
                secondary: billingSmarty.secondary,
            };

            setIPlanFilterParams({
                ...iPlanFilterParams,
                'billingAddress': value,
                'billingSmarty': smarty,
                'billingAddresSelected': billingAddresSelected,
                'nextPageOrder': billingAddresSelected === 'INP' || iPlanFilterParams.multipleAddresses.multipleInternetParams.length > 1 || iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES ? false : true,
                surveyResponse: {
                    ...iPlanFilterParams.surveyResponse,
                    'surveyQuestionsResponse': arraySurveyQuestionResponse,
                },
            });
        } else if (questionDTO.type === 'button') {
            setIPlanFilterParams({
                ...iPlanFilterParams,
                'nextPageOrder': true,
                surveyResponse: {
                    ...iPlanFilterParams.surveyResponse,
                    'surveyQuestionsResponse': arraySurveyQuestionResponse,
                },
            });
        } else {
            setIPlanFilterParams({
                ...iPlanFilterParams,
                'isClickCalendly': isClickCalendly,
                surveyResponse: {
                    ...iPlanFilterParams.surveyResponse,
                    'surveyQuestionsResponse': arraySurveyQuestionResponse,
                },
            });
        }
    };

    const label = isOnlyQuestion ? '' : questionDTO.label + (questionDTO.required && questionDTO.label !== '' && questionDTO.type !== 'billing' ? ' <span className="labelRequired">*</span>' : '');

    return (
        <>
            <span>{parse(label)}</span>
            {(questionDTO.type === 'string' || questionDTO.type === 'phone' || questionDTO.type === 'email' || questionDTO.type === 'date') && <QuestionString key={questionDTO.id} questionDTO={questionDTO} onChange={onChange} />}
            {questionDTO.type === 'radio' && <QuestionRadio key={questionDTO.id} questionDTO={questionDTO} onChange={onChange} />}
            {questionDTO.type === 'button' && <QuestionButton key={questionDTO.id} questionDTO={questionDTO} onChange={onChange} />}
            {questionDTO.type === 'billing' && <QuestionBilling key={questionDTO.id} wrSettings={wrSettings} questionDTO={questionDTO} onChange={onChange} />}
        </>
    );
};

export default Question;
