import React, { useEffect, useState } from 'react';
import useAccountResgisterUser from '../../hooks/api/useAccountRegisterUser';

interface IRegisterUserProps {

}

const RegisterUser: React.FC<IRegisterUserProps> = () => {
    const [valueEmail, setValueEmail] = useState('');
    const [valuePassword, setValuePassword] = useState('');
    const [valueCPassword, setValueCPassword] = useState('');
    const [valueSendUserName, setValueSendUserName] = useState('');
    const [valueSendEmail, setValueSendEmail] = useState('');
    const [valueSendPassword, setValueSendPassword] = useState('');

    const {
        data
    } = useAccountResgisterUser(valueSendUserName, valueSendEmail, valueSendPassword);

    useEffect(() => {
        if (data && data.isOk) {
            alert("User Register...");
        }
    }, [data]);

    const onChangeEmail = (e: any) => {
        setValueEmail(e.currentTarget.value);
    };

    const onChangePassword = (e: any) => {
        setValuePassword(e.currentTarget.value);
    };

    const onChangeCPassword = (e: any) => {
        setValueCPassword(e.currentTarget.value);
    };

    const onClickRegister = () => {
        if (valueEmail === '') {
            alert('Email is required');
            return;
        }
        if (valuePassword === '') {
            alert('Password is required');
            return;
        }
        if (valueCPassword === '') {
            alert('Confirm Password is required');
            return;
        }
        if (valuePassword !== valueCPassword) {
            alert('Password and confirm is diferent, please verify');
            return;
        }

        if (valueEmail !== '' && valuePassword !== '' && valueCPassword !== '') {
            setValueSendUserName(valueEmail);
            setValueSendEmail(valueEmail);
            setValueSendPassword(valuePassword);
        }
    };

    return (
        <>
            <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        id="email"
                        onChange={onChangeEmail}
                        value={valueEmail}
                        className="form-control"
                        placeholder="Email" />
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-10">
                    <input
                        type="password"
                        id="password"
                        onChange={onChangePassword}
                        value={valuePassword}
                        className="form-control"
                        placeholder="***" />
                </div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="cpassword" className="col-sm-2 col-form-label">Confirm Password</label>
                <div className="col-sm-10">
                    <input
                        type="password"
                        id="cpassword"
                        onChange={onChangeCPassword}
                        value={valueCPassword}
                        className="form-control"
                        placeholder="***" />
                </div>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3" onClick={onClickRegister}>Register</button>
            </div>
        </>
    );
};

export default RegisterUser;