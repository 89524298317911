import React from 'react';
import { IPlansCompare } from '../../contracts/types';
import { useGlobalState } from '../../state';

interface IItemPlansCompareProps {
    planCompare: IPlansCompare;
}

const ItemPlansCompareInternet: React.FC<IItemPlansCompareProps> = ({ planCompare }) => {
    const [iFilters, setIFilters] = useGlobalState('filters');

    const onClickRemovePlanCompare = () => {
        const plansCompare = iFilters.plansCompareInternet.filter((pc) => pc.idPlan !== planCompare.idPlan && pc.codeProvider === planCompare.codeProvider);

        setIFilters({
            ...iFilters,
            plansCompareInternet: plansCompare,
        });
    }

    return (
        <tr>
            <td>
                <i className="fa fa-trash-o align-middle " aria-hidden="true" onClick={onClickRemovePlanCompare} />
            </td>
            <td>
                <span className="compareTextTable">{planCompare.planName}</span>
            </td>
            <td>${planCompare.planPrice}</td>
        </tr>
    );
};

export default ItemPlansCompareInternet;
