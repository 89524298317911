import { EnumInternetPlanFeatures, EnumPlanTechnologyType, EnumSortBy, IFilters, IResponseInternetPlansDTO } from "../contracts/types";

export function filtersPlansInternet(arrayPlans: IResponseInternetPlansDTO[], iFilters: IFilters): IResponseInternetPlansDTO[] {
	if (arrayPlans && arrayPlans.length === 0) {
		return [];
	}

	arrayPlans.forEach((plan: IResponseInternetPlansDTO) => {
		plan.planHidden = false;
	});

	if ((iFilters.filterPrice && (iFilters.filterPrice.min !== 0 || iFilters.filterPrice.max !== 0)) ||
		(iFilters.filterSpeed && (iFilters.filterSpeed.min !== 0 || iFilters.filterSpeed.max !== 0)) ||
		(iFilters.filterTerm && iFilters.filterTerm.length > 0) ||
		(iFilters.filterPlanFeatures && iFilters.filterPlanFeatures.length > 0) ||
		(iFilters.filterProvider && iFilters.filterProvider.length > 0)
	) {
		arrayPlans.forEach((plan: IResponseInternetPlansDTO) => {
			let visiblePrice = true;
			let visibleSpeed = true;
			let visibleTerm = true;
			let visibleProvider = true;
			let visibleFeature = true;

			if (iFilters.filterPrice && (iFilters.filterPrice.min !== 0 || iFilters.filterPrice.max !== 0)) {
				visiblePrice = (plan.planPrice >= iFilters.filterPrice!.min);
				if (visiblePrice && iFilters.filterPrice!.max !== 0) {
					visiblePrice = (plan.planPrice <= iFilters.filterPrice!.max);
				}
			}

			if (iFilters.filterSpeed && (iFilters.filterSpeed.min !== 0 || iFilters.filterSpeed.max !== 0)) {
				visibleSpeed = (plan.planDownloadSpeed >= iFilters.filterSpeed!.min);
				if (visibleSpeed && iFilters.filterSpeed!.max !== 0) {
					visibleSpeed = (plan.planDownloadSpeed <= iFilters.filterSpeed!.max);
				}
			}

			if (iFilters.filterTerm && iFilters.filterTerm.length > 0) {
				visibleTerm = iFilters.filterTerm.includes(plan.planTerm);
			}

			if (iFilters.filterProvider && iFilters.filterProvider.length > 0) {
				visibleProvider = iFilters.filterProvider.includes(plan.providerId);
			}

			if (iFilters.filterPlanFeatures && iFilters.filterPlanFeatures.length > 0) {
				let isVisible = false;
				iFilters.filterPlanFeatures.forEach((feature) => {
					if (isVisible === false) {
						switch (feature) {
							case EnumInternetPlanFeatures.CABLE:
								isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.CABLE || plan.providerName === 'AT&T');
								break;
							case EnumInternetPlanFeatures.DSL:
								isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.DSL);
								break;
							case EnumInternetPlanFeatures.FIBER:
								isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.FIBER);
								break;
							case EnumInternetPlanFeatures.HFC:
								isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.HFC);
								break;
							case EnumInternetPlanFeatures.METERED:
								isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.METERED);
								break;
							case EnumInternetPlanFeatures.EQUIPMENT_INCLUDED:
								isVisible = (plan.planModemIncluded);
								break;
						}
					}
				});

				visibleFeature = isVisible;
			}

			plan.planHidden = !(visiblePrice && visibleSpeed && visibleTerm && visibleProvider && visibleFeature);
		});
	}

	return arrayPlans;
}