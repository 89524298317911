import { IResponseVoicePlansDTO } from "../../../contracts/types";
import { useGlobalState } from "../../../state";

interface IVoicePlanShortProps {
  plan: IResponseVoicePlansDTO;
}

const VoicePlanShort: React.FC<IVoicePlanShortProps> = ({ plan }) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");

  const onClickGetStarted = () => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        plan: plan,
      },
      surveyData: {
        ...iPlanFilterParams.surveyData,
        isOpen: true,
        isPageThanks: false,
        planId: plan.planId,
        encryptOrderId: "",
        orderId: -1,
      },
    });

    if (!iPlanFilterParams.isLocalhost) {
      window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
    }
  };

  return (
    <div className="col-lg col-md-3">
      <div className="titleTab">BUSINESS VOICE AND PHONE</div>
      <div className="content3Prices">
        <span>$</span>
        <span>{plan.calculatedPrice.toFixed(2)}</span>
        <span>monthly</span>
      </div>
      <a
        className="buttonGetPrices"
        href="javascript:void(0)"
        onClick={() => onClickGetStarted()}
      >
        Order now
      </a>
      <div className="squareBrandFD">
        <img
          src={`data:image/jpeg;base64,${
            iGlobals.carriers.find((c) => c.id === plan.providerId)?.imageBase64
          }`}
        />
      </div>
      <ul>
        <li>{plan.term} Months</li>
        <li>Usage/Local LD: {plan.voiceFeatureName}</li>
        <li>
          Chat/IM:{" "}
          <img
            src={`${iPlanFilterParams.imagesUrl}/${
              plan.chatImPrice ? "IconYes.png" : "IconNot.png"
            }`}
            alt={""}
          />
        </li>
        <li>
          Meet/Video:{" "}
          <img
            src={`${iPlanFilterParams.imagesUrl}/${
              plan.meetVideoPrice ? "IconYes.png" : "IconNot.png"
            }`}
            alt={""}
          />
        </li>
      </ul>
    </div>
  );
};

export default VoicePlanShort;
