import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { ISurveySearchDTO } from "../../contracts/types";

export default function useSearchOrder(encryptOrderId: string) {

	const api = useServiceApi();

	const doRequest = async (): Promise<ISurveySearchDTO> => {

		const { data } = await api.get(`${ApiEndPoints.GetSearchOrder}?encryptOrderId=${encryptOrderId}`);

		return data;
	};

	return useQuery<ISurveySearchDTO>(
		['order', encryptOrderId],
		() => doRequest(), {
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: encryptOrderId!==''
		}
	);
}
