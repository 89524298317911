import { useState, useEffect } from "react";
import {
  EnumPlanType,
  IWrSettings,
  EnumIncludePropertyId,
  EnumPropertyId,
  IRequestSaveOrderDTO,
  OrderFormValues,
} from "../../contracts/types";
import {
  OrderFormError,
  OrderFormInitialValues,
  useGlobalState,
} from "../../state";
import speedUp from "../../images/up.svg"; // with import
import loadingGif from "../../images/loading.gif"; // with import
import speedDown from "../../images/down.svg"; // with import
import { useServiceApi, ApiEndPoints } from "../../api";

const md5 = require("md5");
const Cryptr = require("cryptr");

interface ISurveyProps {
  wrSettings: IWrSettings;
}
const OrderNow: React.FC<ISurveyProps> = ({ wrSettings }) => {
  const [iGlobals] = useGlobalState("globals");
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [formValues, setFormValues] = useState(OrderFormInitialValues);
  const [formErrors, setFormErrors] = useState(OrderFormError);
  const [isSubmit, setIsSubmit] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [isAPISuccess, setIsAPISuccess] = useState(false);
  const [apiError, setApiError] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const api = useServiceApi();

  // console.log("iPlanFilterParams => ", iPlanFilterParams.internetParams);
  let planData: any = [];
  let isInternet =
    iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET ? true : false;
  let isVOIP =
    iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE ? true : false;
  let isBundle =
    iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES ? true : false;

  let includesValues;
  if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET) {
    planData = iPlanFilterParams.internetParams.plan;
  } else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE) {
    planData = iPlanFilterParams.voiceParams.plan;
    includesValues = iGlobals.productProperties.filter(
      (pp) =>
        pp.productId === planData.productId &&
        EnumIncludePropertyId.includes(pp.propertyCode)
    );
  } else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
    planData = iPlanFilterParams.bundleParams.plan;
  }

  if (
    planData == undefined ||
    (planData.planId <= 0 && planData.productId <= 0)
  ) {
    window.open(iPlanFilterParams.baseUrl + "search", "_self");
  }

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const talkToCallAction = () => {
    window.open("tel:+18449477737", "_self");
  };

  useEffect(() => {
    if (Object.values(formErrors).every((x) => x == "") && isSubmit) {
      const request: IRequestSaveOrderDTO = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        phone: formValues.phone,
        companyName: formValues.companyName,
        multipleLocations: formValues.multipleLocations,
        callTime: formValues.callTime,
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2,
        city: formValues.city,
        zipcode: formValues.zipcode,
        planId: planData.planId,
        providerId: planData.providerId,
        planTypeCode: isInternet == true ? planData.planTypeCode : "VOIP",
      };

      const useme =
        "yTu7x$f2VJB%RBR6W}xWnw[K)^M!YY<C3{//a5ZhmJ$!pn6KA]=X8z:/c)O]JLW";
      const cryptr = new Cryptr(useme);
      const encryptedString = cryptr.encrypt(
        md5(
          `${useme}${request.firstName}${request.lastName}${request.email}${request.phone}${request.companyName}${request.multipleLocations}${request.callTime}${request.addressLine1}${request.addressLine2}${request.city}${request.zipcode}${request.planId}${request.providerId}${request.planTypeCode}${useme}`
        )
      );
      let config = {
        headers: {
          Auth: encryptedString,
        },
      };
      setShowLoading(true);
      setDisableSubmit(true);
      api
        .post(`${ApiEndPoints.SaveOrder}`, request, config)
        .then((response) => {
          console.log("SaveOrder response => ", response.data.orderId);
          setOrderId(response.data.orderId);
          setIsAPISuccess(true);
          setShowLoading(false);
          setDisableSubmit(false);
          setApiError("");
        })
        .catch((error) => {
          setShowLoading(false);
          setDisableSubmit(false);
          setApiError(error + "");
          console.log("SaveOrder error => ", error);
        });
    }
  }, [formErrors]);

  const validate = (values: OrderFormValues) => {
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      companyName: "",
      multipleLocations: "",
      callTime: "",
      addressLine1: "",
      city: "",
      zipcode: "",
    };
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstName) {
      errors.firstName = "First name is required!";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required!";
    }
    if (!values.email) {
      errors.email = "Email address is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required!";
    }
    if (!values.companyName) {
      errors.companyName = "Company name is required!";
    }
    if (!values.addressLine1) {
      errors.addressLine1 = "Location address is required!";
    }
    if (!values.city) {
      errors.city = "City is required!";
    }
    if (!values.zipcode) {
      errors.zipcode = "Zipcode is required!";
    }
    return errors;
  };

  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-3364ac2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="3364ac2"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-00a0bc9"
          data-id="00a0bc9"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-a493899 elementor-widget elementor-widget-html"
              data-id="a493899"
              data-element_type="widget"
              data-widget_type="html.default"
            >
              <div className="elementor-widget-container">
                {isAPISuccess == false && (
                  <div className="container order-now-card-container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="col-md-12 order-form-title">
                          <h1>Order securely from Wiresurfer</h1>
                        </div>
                        <form
                          className="order-form-block"
                          onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <div className="col-md-6">
                              <label>
                                First name
                                <span className="required-class">*</span>
                              </label>
                              <input
                                id=""
                                type="text"
                                name="firstName"
                                placeholder="First name"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.firstName}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.firstName}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Last name
                                <span className="required-class">*</span>
                              </label>
                              <input
                                id=""
                                type="text"
                                name="lastName"
                                placeholder="Last name"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.lastName}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.lastName}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label>
                                Email address
                                <span className="required-class">*</span>
                              </label>
                              <input
                                id=""
                                type="email"
                                name="email"
                                placeholder="Email address"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.email}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.email}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Phone number
                                <span className="required-class">*</span>
                              </label>
                              <input
                                id=""
                                type="text"
                                name="phone"
                                placeholder="Phone number"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.phone}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.phone}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label>
                                Company name
                                <span className="required-class">*</span>
                              </label>
                              <input
                                id=""
                                type="text"
                                name="companyName"
                                placeholder="Company Name"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.companyName}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.companyName}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <label>Multiple Locations?</label>
                              <select
                                id=""
                                name="multipleLocations"
                                className=""
                                value={formValues.multipleLocations}
                                onChange={handleChange}
                              >
                                <option value="1">1</option>
                                <option value="2-5">2-5</option>
                                <option value="6-10">6-10</option>
                                <option value="10+">10+</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label>
                                Best time(s) to call
                                <span className="required-class">*</span>
                              </label>
                              <select
                                id=""
                                name="callTime"
                                className=""
                                value={formValues.callTime}
                                onChange={handleChange}
                              >
                                <option value="morning">Morning</option>
                                <option value="midday">Mid-day</option>
                                <option value="afternoon">Afternoon</option>
                              </select>
                              <p className="validation-error">
                                {formErrors.callTime}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label>
                                Location address
                                <span className="required-class">*</span>
                              </label>
                              <input
                                id=""
                                type="text"
                                name="addressLine1"
                                placeholder="Address line 1"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.addressLine1}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.addressLine1}
                              </p>
                            </div>
                            <div className="col-md-12">
                              <input
                                id=""
                                type="text"
                                name="addressLine2"
                                placeholder="Address line 2"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.addressLine2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                id=""
                                type="text"
                                name="city"
                                placeholder="City"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.city}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.city}
                              </p>
                            </div>
                            <div className="col-md-6">
                              <input
                                id=""
                                type="text"
                                name="zipcode"
                                placeholder="Postal / Zip Code"
                                className=""
                                autoComplete="do-not-autofill"
                                value={formValues.zipcode}
                                onChange={handleChange}
                              />
                              <p className="validation-error">
                                {formErrors.zipcode}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <p className="api-response-error">{apiError}</p>
                            </div>
                            <div className="col-md-12 order-submit-btn">
                              <input
                                id=""
                                type="submit"
                                className=""
                                autoComplete="do-not-autofill"
                                value="Submit"
                                disabled={disableSubmit}
                              />
                              {showLoading == true && (
                                <img
                                  className="loading-order"
                                  src={loadingGif}
                                  alt=""
                                />
                              )}
                            </div>
                          </div>
                          {isInternet == true && (
                            <div className="row">
                              <div className="col-md-12 after-clicking-submit">
                                After clicking submit, a Wiresurfer advisor will
                                be immediately in contact to review your order,
                                gather any further info required (ex: technical
                                contacts, installation date requests, etc..) and
                                send the contract (if applicable).
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          {isInternet == true && (
                            <div className="col-md-12 what-happen-next">
                              <h3>What Happens Next</h3>
                              <ol>
                                <li>
                                  Information is required to be filled out to
                                  place an order in accordance with local and
                                  national telecom regulations. Your data will
                                  not be shared outside this ordering process.
                                </li>
                                <li>No pre-payment is required.</li>
                                <li>
                                  After completing the information, a Wiresurfer
                                  advisor will be immediately in contact to
                                  review your order and send the contract (if
                                  applicable).
                                </li>
                                <li>
                                  An implementation and project management rep
                                  from the chosen telecom provider will then
                                  set-up installation of ordered service(s).
                                </li>
                              </ol>
                            </div>
                          )}
                          {isInternet == false && (
                            <div className="col-md-12 what-happen-next">
                              <h3>What Happens Next</h3>
                              <ol>
                                <li>
                                  After completing the information, a Wiresurfer
                                  advisor will be immediately in contact to
                                  review your requirements, answer any questions
                                  about this VoIP plan or other options, conduct
                                  a demo and more.
                                </li>
                                <li>
                                  Should you want to place an order, your
                                  Wiresurfer advisor will collect further
                                  information required and send the contract.
                                </li>
                                <li>
                                  An implementation and project management rep
                                  from the chosen telecom provider will then
                                  set-up installation of ordered service(s).
                                </li>
                                <li>No pre-payment is required.</li>
                                <li>
                                  Your data will not be shared outside this
                                  ordering process.
                                </li>
                              </ol>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12 order-plan-title">
                            {isBundle == true && <h2>{planData.planName}</h2>}
                            {isBundle == false && (
                              <h2>{planData.displayName}</h2>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 center-alignment provider-logo">
                            <img
                              className=""
                              src={`data:image/jpeg;base64,${
                                iGlobals.carriers.find(
                                  (c) => c.id === planData.providerId
                                )?.imageBase64
                              }`}
                              alt=""
                            />
                          </div>
                        </div>
                        {isInternet == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Plan type:</p>
                            </div>
                            <div className="col-md-8">
                              {planData.planTypeName}
                            </div>
                          </div>
                        )}
                        {isInternet == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Technology:</p>
                            </div>
                            <div className="col-md-8">
                              {planData.planTechnologyName}
                            </div>
                          </div>
                        )}
                        {isInternet == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Term:</p>
                            </div>
                            <div className="col-md-8">
                              {planData.planTerm} / mo.
                            </div>
                          </div>
                        )}
                        {isVOIP == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Term:</p>
                            </div>
                            <div className="col-md-8">
                              {planData.term} / mo.
                            </div>
                          </div>
                        )}
                        {isBundle == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Term:</p>
                            </div>
                            <div className="col-md-8">{planData.planTerm}</div>
                          </div>
                        )}
                        <div className="row order-plan-sub-title">
                          <div className="col-md-4">
                            <p>Price:</p>
                          </div>
                          {isInternet == true && (
                            <div className="col-md-8">
                              <div>
                                ${planData.planPrice}{" "}
                                <div className="excludes-taxes">
                                  Excludes taxes and regulatory fees
                                </div>
                              </div>
                            </div>
                          )}
                          {isBundle == true && (
                            <div className="col-md-8">
                              <div>
                                ${planData.planPrice} / mo.{" "}
                                <div className="excludes-taxes">
                                  Excludes taxes and regulatory fees
                                </div>
                              </div>
                            </div>
                          )}
                          {isVOIP == true && (
                            <div className="col-md-8">
                              <div>
                                ${planData.seatsTypePrice} / mo.{" "}
                                {iPlanFilterParams.voiceParams.filterNumber}{" "}
                                seats{" "}
                                <div className="excludes-taxes">
                                  Taxes + fees extra
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {isInternet == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Speed:</p>
                            </div>
                            <div className="col-md-8">
                              <div>
                                <div className="internet-speed-down">
                                  <img src={speedDown} alt="" />
                                  {planData.planDownloadSpeed >= 1000
                                    ? (
                                        planData.planDownloadSpeed / 1000
                                      ).toFixed(0)
                                    : planData.planDownloadSpeed}
                                  {planData.planDownloadSpeed >= 1000
                                    ? " Gbps"
                                    : " Mbps"}
                                  <img
                                    src={speedUp}
                                    alt=""
                                    className="order-form-speedup"
                                  />
                                  {planData.planUploadSpeed >= 1000
                                    ? (planData.planUploadSpeed / 1000).toFixed(
                                        0
                                      )
                                    : planData.planUploadSpeed}
                                  {planData.planUploadSpeed >= 1000
                                    ? " Gbps"
                                    : " Mbps"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isBundle == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Internet Speed:</p>
                            </div>
                            <div className="col-md-8">
                              <div>
                                <div className="internet-speed-down">
                                  {planData.planDownloadSpeed >= 1000
                                    ? (
                                        planData.planDownloadSpeed / 1000
                                      ).toFixed(0)
                                    : planData.planDownloadSpeed}
                                  {planData.planDownloadSpeed >= 1000
                                    ? " Gbps"
                                    : " Mbps"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isVOIP == true && (
                          <div className="row order-plan-sub-title inlcude-voip-block">
                            <div className="col-md-4">
                              <p>Includes:</p>
                            </div>
                            <div className="col-md-8">
                              <ul className="voice-card-includes-lst">
                                {includesValues != undefined &&
                                  includesValues.map((element) => (
                                    <li>{element.value}</li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {isBundle == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Phone Line:</p>
                            </div>
                            <div className="col-md-8">
                              {
                                iGlobals.productProperties.find(
                                  (pp) =>
                                    pp.productId === planData.productId &&
                                    pp.propertyCode ===
                                      EnumPropertyId.PHONE_LINE
                                )?.value
                              }
                            </div>
                          </div>
                        )}
                        {isBundle == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Professional Installation:</p>
                            </div>
                            <div className="col-md-8">
                              {
                                iGlobals.productProperties.find(
                                  (pp) =>
                                    pp.productId === planData.productId &&
                                    pp.propertyCode ===
                                      EnumPropertyId.INSTALLATION
                                )?.value
                              }
                            </div>
                          </div>
                        )}
                        {isBundle == true && (
                          <div className="row order-plan-sub-title">
                            <div className="col-md-4">
                              <p>Equipment Fee:</p>
                            </div>
                            <div className="col-md-8">
                              {
                                iGlobals.productProperties.find(
                                  (pp) =>
                                    pp.productId === planData.productId &&
                                    pp.propertyCode ===
                                      EnumPropertyId.EQUIPMENT_FEE
                                )?.value
                              }
                            </div>
                          </div>
                        )}
                        <div className="row talk_to_us_any_main">
                          <div className="col-md-12 talk_to_us_any">
                            Talk to a Wiresurfer expert:<br></br>
                            <p onClick={talkToCallAction}>1 (844) 947-7737</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isAPISuccess == true && (
                  <div className="container order-now-success-card-container">
                    <div className="row order-now-thank">
                      <div className="col-md-12">
                        <div className="row message-thank">
                          <h5>Thanks for the submission!</h5>
                          <h5>
                            One of our telecom experts will be in touch in a few
                            minutes, until then please note your
                          </h5>
                        </div>
                        <div className="row title-main-order">
                          <h2> Request Number: # {orderId}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default OrderNow;
