import React, { useEffect } from 'react';
import { EnumViewPlansType, IWrSettings, EnumPlanType } from '../../contracts/types';
import InternetPlans from './internet/internetPlans.comp';
import VoicePlans from './voice/voicePlans.comp';
import { useGlobalState } from '../../state';
import BundlePlans from './bundle/bundlePlans.comp';

interface IPlansProps {
    viewPlansType: EnumViewPlansType;
    wrSettings: IWrSettings;
}

const Plans: React.FC<IPlansProps> = ({ viewPlansType, wrSettings }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters, setIFilters] = useGlobalState('filters');

    useEffect(() => {
        const path = window.location.pathname;

        if (path.includes('business-internet')) {
            setIFilters({
                ...iFilters,
                planTypeCode: EnumPlanType.BUSINESS_INTERNET
            });

            setIPlanFilterParams({
                ...iPlanFilterParams,
                internetParams: {
                    ...iPlanFilterParams.internetParams,
                    'address': '',
                    'city': '',
                    'state': '',
                    'streetLine': '',
                    'zipCode': '-1',
                },
            });
        } else if (path.includes('business-phone')) {
            setIFilters({
                ...iFilters,
                planTypeCode: EnumPlanType.BUSINESS_VOICE_PHONE
            });

            setIPlanFilterParams({
                ...iPlanFilterParams,
            });
        }
    }, [iPlanFilterParams]);

    return (
        <>
            {iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && <InternetPlans viewPlansType={viewPlansType} wrSettings={wrSettings} />}
            {iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE && <VoicePlans viewPlansType={viewPlansType} wrSettings={wrSettings} />}
            {iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && <BundlePlans viewPlansType={viewPlansType} wrSettings={wrSettings} />}
        </>
    );
};

export default Plans;
