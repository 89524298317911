import axios from "axios";
import { useQuery } from "react-query";
import { ApiEndPoints } from "../../api"
import { IAuditVisitor } from "../../contracts/types";
import { useGlobalState } from "../../state";

export default function useAuditGetIp() {
	const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');

	const doRequest = async (): Promise<IAuditVisitor> => {
		const { data } = await axios.get(`${ApiEndPoints.GetAuditIp}`);

		setIAuditVisitor(
			{
				...iAuditVisitor,
				id: -1,
				ip: data.ip,
				address: '',
				stateAbbreviation: data.state_prov,
				city: data.city,
				zipCode: data.zipcode,
				longitude: data.longitude,
				latitude: data.latitude
			});

		return iAuditVisitor;
	};

	return useQuery<IAuditVisitor>(
		['auditGetIp'],
		() => doRequest(), {
		staleTime: Infinity,
		cacheTime: Infinity,
		retry: 1,
		enabled: iAuditVisitor.ip === ''
	});
}
