import { useState, useEffect } from 'react';
import { IResponseBundlePlansDTO, EnumViewPlansType } from "../../contracts/types";
import useBundlePlans from './useBundlePlans';

export default function useVoicePlansPagination(viewPlansType: EnumViewPlansType, pageNumber: number) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [plansData, setPlansData] = useState<IResponseBundlePlansDTO[]>([]);
    const [plans, setPlans] = useState<IResponseBundlePlansDTO[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [finishCharge, setFinishCharge] = useState(false);

    const {
        data,
        isLoading,
        isError
    } = useBundlePlans(viewPlansType);

    useEffect(() => {
        setLoading(isLoading);
        setError(isError);
    }, [isLoading, isError]);

    useEffect(() => {
        if (data) {
            setPlansData(data.lstResponseBundlePlansDTO);
        }
    }, [data]);

    useEffect(() => {
        if (plansData) {
            setPlans(plansData.slice(0, pageNumber * 10));
            setHasMore(plansData.length > pageNumber * 10);

            if (data) {
                setFinishCharge(true);
            }
            else {
                setFinishCharge(false);
            }
        }
    }, [plansData, pageNumber]);

    return { loading, error, plans, hasMore, finishCharge };
};