import ReactDOM from 'react-dom';
import App from './App';
//import 'bootstrap/dist/css/bootstrap.css';

const target = document.getElementById('wr-root');

if (target !== null) {
    const id = target.dataset.id ? target.dataset.id : '';

    const wrSettings = (window as any).wrSettings[id];
    ReactDOM.render(<App wrSettings={wrSettings} />, target);
}
