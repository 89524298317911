import React, { useEffect, useState } from "react";

import {
  IResponseInternetPlansDTO,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import { redirectCompare } from "../../../helpers/redirectPages";
import speedUp from "../../../images/up.svg"; // with import
import speedDown from "../../../images/down.svg"; // with import
interface IInternetPlanLargeMobileProps {
  plan: IResponseInternetPlansDTO;
  onChangeCompareCheckBox: (
    planId: number,
    codeProvider: string | undefined
  ) => void;
}

const InternetPlanLargeMobile: React.FC<IInternetPlanLargeMobileProps> = ({
  plan,
  onChangeCompareCheckBox,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [seeMoreRecommended, setSeeMoreRecommended] = useState(false);
  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreRecommended = () => {
    setSeeMoreRecommended(!seeMoreRecommended);
  };

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  return (
    <div className="row">
      <div className="col-12 p-2">
        {plan.providerLogo === "logoWiresurferDummy.png" ? (
          <img
            className="mx-auto d-block"
            src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`}
            alt=""
          />
        ) : (
          <img
            className="mx-auto d-block"
            src={`data:image/jpeg;base64,${
              iGlobals.carriers.find((c) => c.id === plan.providerId)
                ?.imageBase64
            }`}
            alt=""
          />
        )}
      </div>
      <div className="col-12 p-2 m-service-type-name ">{plan.displayName}</div>
      <hr className="m-card-hr" />
      <div className="col-md-12 m-internet-card-first">
        <div className="text-head">Price</div>
        <div>${plan.planPrice.toFixed(2)} / mo.</div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-12 m-internet-card-first">
        <div className="text-head">Term</div>
        <div>{plan.planTerm} Months</div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-3 m-internet-card-first no-border">
        <div className="text-head">Max Speed</div>
        <div>
          <div className="internet-speed-down">
            <div className="down-speed-icon">
              <img src={speedDown} alt="" />
            </div>
            <div className="down-speed-text">
              {plan.planDownloadSpeed >= 1000
                ? (plan.planDownloadSpeed / 1000).toFixed(0)
                : plan.planDownloadSpeed}
              {plan.planDownloadSpeed >= 1000 ? " Gbps" : " Mbps"}
            </div>
          </div>
          <div className="internet-speed-up">
            <div className="up-speed-icon">
              <img src={speedUp} alt="" />
            </div>
            <div className="up-speed-text">
              {plan.planUploadSpeed >= 1000
                ? (plan.planUploadSpeed / 1000).toFixed(0)
                : plan.planUploadSpeed}
              {plan.planUploadSpeed >= 1000 ? " Gbps" : " Mbps"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternetPlanLargeMobile;
