import React from 'react';
import parse from 'html-react-parser';
import { EnumPropertyId, IResponseInternetPlansDTO } from '../../../contracts/types';
import { useGlobalState } from '../../../state';
import LearnMorePropertyValue from './learnMorePropertyValue.com';
import ClickToDialLearnMore from '../../tbi/clickToDIal/clickToDialLearnMore';

interface ILearnMoreInternetProps {
    plan: IResponseInternetPlansDTO;
}

const LearnMoreInternetMobile: React.FC<ILearnMoreInternetProps> = ({ plan }) => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals] = useGlobalState('globals');

    const onClickBwCalculator = () => {
        window.open(iPlanFilterParams.baseUrl + 'bw-calculator', "_self");
    }

    const onClickOpenUrl = (code: EnumPropertyId) => {
        const productProperty = iGlobals.productProperties.find(pp => pp.productId === plan.productId && pp.propertyCode === code);
        const url = productProperty ? productProperty.value : '';

        if (url !== '') {
            window.open(url, "_blank");
        }
    };

    const proProInt = iGlobals.productProperties.find(pp => pp.productId === plan.productId && pp.propertyCode === EnumPropertyId.UNLIMITED_INTERNET_DATA);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div id="learn-name-learnmore-mobile" className="card-purple-mobile-space">
                                <span className="learn-texttitle-mobile">{plan.providerName}</span>
                                <br />
                            </div>
                            <div id="learn-name-data-text-space" className="card-purple-data-text">
                                <span className="learn-textsubtitle-mobile">{plan.planTypeName}</span>
                            </div>
                        </div>
                        <br />
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div id="starting-learnmore-mobile" className="card-purple-mobile-space">
                                <span className="learn-texttitle2-mobile">Starting at</span>
                            </div>
                            <div id="starting-data-text-space" className="card-purple-data-text">
                                <span className="learn-numberprice-mobile">${plan.planPrice.toFixed(2)}</span>
                                <span className="learn-texttitle2-mobile">mo</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <img className="rounded image-woman-size rounded-3" src="/wp-content/wiresresources/images/v2/learn-more/imagen-men.jpg" alt='' />
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <span id="learnmore-mobile-maximumspeed" className="learn-texttitle2-mobile float-start">MAXIMUM<br />SPEED</span>
                            <div className="size-learnmore-mobile">
                                <div className="card-icon-bolt-learnmore-mobile float-start" />
                                <div>
                                    <div className="card-icon-down-mobile float-start" />
                                    <span className="card-max-term">{plan.planDownloadSpeed}</span>
                                    <div className="card-icon-mbps-mobile float-end" />
                                </div>
                                <hr className="card-hr" />
                                <div>
                                    <div className="card-icon-up-mobile float-start" />
                                    <span className="card-max-term">{plan.planUploadSpeed}</span>
                                    <div className="card-icon-mbps-mobile float-end" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="card-icon-learn-priceguarantee-mobile float-start" />
                            <span id="learnmore-mobile-priceguaranteee" className="learn-texttitle2-mobile float-end">PRICE<br />GUARANTEE</span>
                            <a id="price-text"><LearnMorePropertyValue propertyCode={EnumPropertyId.PRICE_GUARANTEE} plan={plan} isValueLong={false} /></a>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <span id="learnmore-mobile-conecteddevices" className="learn-texttitle2-mobile">CONECTED
                                DEVICES</span>
                            <br />
                            <br />
                            <button id="learnmore-mobile-knowmorehere" type="button" className="btn btn-primary btn-learnmore-mobile" onClick={onClickBwCalculator}>
                                KNOW MORE HERE
                            </button>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="card-icon-asymmetrical-mobile float-start" />
                            <span id="learnmore-mobile-asymetrical" className="learn-texttitle2-mobile float-end">SYMMETRICAL<br /> /
                                ASYMMETRICAL <br />CONNECTION</span>
                            <a id="asymetrical-text">
                                <LearnMorePropertyValue propertyCode={EnumPropertyId.SYM_ASYM_CONNECTION} plan={plan} isValueLong={false} />
                            </a>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="card-icon-learn-dedicated-mobile float-start" />
                            <div>
                                <span id="learnmore-mobile-dedicated" className="learn-texttitle2-mobile float-end">DEDICATED<br />/
                                    SHARED <br />INTERNET</span>
                                <a id="shared-text">
                                    <LearnMorePropertyValue propertyCode={EnumPropertyId.DEDICATED_SHARED_INTERNET} plan={plan} isValueLong={false} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="row">
                                <div className="col-5">
                                    <div>
                                        <span id="learnmore-mobile-term" className="learn-texttitleterm-mobile">TERM</span>
                                        <div className="card-icon-learn-calendar-mobile" />
                                    </div>
                                </div>
                                <div className="col-6 float-end">
                                    <div id="term-number">
                                        <span className="learn-numberterm-mobile">{plan.planTerm}</span>
                                        <span className="learn-textmonths-mobile">Months</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="card-icon-learn-techsupport-mobile float-start" />
                            <div>
                                <span id="learnmore-mobile-techsupport" className="learn-texttitle2-mobile float-end">TECH<br />SUPPORT</span>
                                <a id="tech-text">
                                    <LearnMorePropertyValue propertyCode={EnumPropertyId.TECH_SUPPORT} plan={plan} isValueLong={false} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="card-icon-learn-technology-mobile float-start" />
                            <div>
                                <span id="learnmore-mobile-technology" className="learn-texttitle2-mobile float-end">TECHNOLOGY</span>
                                <a id="technology-text">{plan.planTechnologyName}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="thisplan-learnmore" className="learn-textthisplan-mobile ">
                                        THIS PLAN<br />WORKS<br />BEST FOR
                                    </span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.BEST_FOR} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="whythis-learnmore" className="learn-textthisplan-mobile">WHY<br />THIS<br />CARRIER</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.WHYTHISCARRIER} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="installation-learnmore" className="learn-textthisplan-mobile">INSTALLATION</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.INSTALLATION} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-light rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="staticip-learnmore" className="learn-textthisplan-mobile">STATIC IP</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.STATIC_IP} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 my-2">
                        <img className="rounded image-woman-size rounded-3" src="/wp-content/wiresresources/images/v2/learn-more/learn-more-two-purple.png" alt="" />
                    </div>
                    <div className="col-4 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div id="contractterm-learnmore-mobile" className="card-purple-mobile-space">
                                <span className="learn-texttitle-mobile-purple">CONTRACT TERMS</span>

                            </div>
                            <div id="contract-terms-data-text-space" className="card-purple-data-text">
                                <a id="contract-terms-data-text">Wi-Fi Gateway for wireless network</a>
                            </div>
                        </div>
                        <br />
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div id="unlimiteddata-learnmore-mobile" className="card-purple-mobile-space">
                                <span id="learnmore-mobile-unlimited" className="learn-texttitlepurple-mobile">
                                    UNLIMITED<br />INTERNET DATA
                                </span>
                            </div>
                            <div id="unlimiteddata-text-space" className="card-purple-data-text">
                                <a id="unlimited-text">
                                    {
                                        proProInt && <>{parse(proProInt.value)}</>
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div id="unlimitedspace-learnmore-mobile" className="card-purple-mobile-space">
                                <span id="learnmore-mobile-unlimited-data" className="learn-texttitlepurple-mobile">
                                    UNLIMITED<br />INTERNET DATA
                                </span>
                            </div>
                            <div id="unlimited-data-text-space" className="card-purple-data-text">
                                <a id="unlimited-data-text">
                                    {
                                        proProInt && <>{parse(proProInt.valueLong)}</>
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div id="unlimitedspace-learnmore-mobile" className="card-purple-mobile-space">
                                <span id="learnmore-mobile-customer" className="learn-texttitlepurple-mobile">CUSTOMER CARE</span>
                            </div>
                            <div id="customer-data-text-space" className="card-purple-data-text">
                                <a id="customer-text">
                                    <LearnMorePropertyValue propertyCode={EnumPropertyId.CUSTOMERCARE} plan={plan} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="whythis-carrier-learnmore" className="learn-textthisplan-mobile-purple">WHY<br />THIS<br />CARRIER</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.WHYTHISCARRIER} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="reliability-learnmore" className="learn-textthisplan-mobile-purple">
                                        RELIABILITY<br />AND SUPPORT<br />CARE
                                    </span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.RELIABILITY_AND_SUPPORT} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="wireless-learnmore" className="learn-textthisplan-mobile-purple">WIRELESS<br />NETWORKING</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.WIRELESS_NETWORKING} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="trailperiod-learnmore" className="learn-textthisplan-mobile-purple">
                                        TRIAL<br />PERIOD OR<br />MONEY-BACK<br />GUARANTEE
                                    </span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.TRIAL_PERIOD_OR_MONEY_BACK_GUARANTEE} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="hospot-wifi-learnmore" className="learn-textthisplan-mobile-purple">HOSPOT<br />WIFI</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.HOTSPOT_WIFI} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle">
                                    <span id="email-accounts-learnmore" className="learn-textthisplan-mobile-purple">EMAIL<br />ACCOUNTS<br />INCLUDED</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <div className="text-scroll-seccion-light-mobile">
                                        <span className="learn-textparagraph-mobile">
                                            <LearnMorePropertyValue propertyCode={EnumPropertyId.EMAIL_ACCOUNTS_INCLUDED} plan={plan} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile-buttons ">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle-footer">
                                    <span id="email-accounts-learnmore" className="learn-textthisplan-mobile-purple">WEBSITE</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <button type="button" className="btn  btn-learnmore-mobile-purple-footer" onClick={() => onClickOpenUrl(EnumPropertyId.WEB_SITE)}>
                                        KNOW MORE HERE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-2">
                        <div className="col-12 bg-purple-mobile rounded-3 shadow p-2 container-sizemobile-mobile-buttons ">
                            <div className="pt-2">
                                <div className="float-start learn-spacetitle-footer">
                                    <span id="email-accounts-learnmore" className="learn-textthisplan-mobile-purple">VIEW MORE<br />DETAILS</span>
                                </div>
                                <div className="learn-spaceparagraph ">
                                    <button type="button" className="btn  btn-learnmore-mobile-purple-footer" onClick={() => onClickOpenUrl(EnumPropertyId.LEARN)}>
                                        CLICK HERE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ClickToDialLearnMore isMobile={true} />
                </div>
            </div>
        </>
    );
};

export default LearnMoreInternetMobile;
