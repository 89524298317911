import React from "react";

import {
  EnumPropertyId,
  IResponseInternetPlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import internetInstall from "../../../images/internet-install.png"; // with import
import internetPriceGuarantee from "../../../images/internet-price-guarantee.png"; // with import
import isRouter from "../../../images/internet-type.png"; // with import
import internetType from "../../../images/broadband_fibre.png"; // with import
import learnMoreImg from "../../../images/learn.png"; // with import
interface IInternetPlanLargeMobileExpandedProps {
  plan: IResponseInternetPlansDTO;
}

const InternetPlanLargeMobileExpanded: React.FC<
  IInternetPlanLargeMobileExpandedProps
> = ({ plan }) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals, setIGlobals] = useGlobalState("globals");

  const onClickMoreDetails = (pPlan: IResponseInternetPlansDTO) => {
    setIGlobals({
      ...iGlobals,
      planLearMore: pPlan,
    });

    setTimeout(function () {
      window.open(iPlanFilterParams.baseUrl + "learnmore", "_blank");
    }, 100);
  };

  let isLearnMore = false;
  let learnMoreLink = "";
  let learnMore = iGlobals.productProperties.filter(
    (pp) =>
      pp.productId === plan.productId &&
      pp.propertyCode === EnumPropertyId.LEARN
  );
  if (learnMore.length > 0 && plan.displayName.includes("Security Edge")) {
    learnMoreLink = learnMore[0].value;
    isLearnMore = true;
  }

  return (
    <>
      <hr className="m-card-hr" />
      <div className="col-md-12 card-float-int">
        {isLearnMore === true && learnMoreLink != "" ? (
          <table className="table internet-card-table-second-five">
            <thead></thead>
            <tbody className="card-more-table">
              <tr>
                <td className="internet-price-guarantee">
                  <img src={internetPriceGuarantee} alt="" />
                </td>
              </tr>
              <tr>
                <td>Price Guarantee</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.PRICE_GUARANTEE
                      )?.value
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="internet-install">
                  <img src={internetInstall} alt="" />
                </td>
              </tr>
              <tr>
                <td>Installation</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.INSTALLATION
                      )?.value
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="is-router">
                  <img src={isRouter} alt="" />
                </td>
              </tr>
              <tr>
                <td>Router</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.ROUTER
                      )?.value
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="internet-type">
                  <img src={internetType} alt="" />
                </td>
              </tr>
              <tr className="align-middle">
                <td>Type</td>
              </tr>
              <tr>
                <td>
                  <div>{plan.planTechnologyName}</div>
                </td>
              </tr>
              <tr>
                <td className="internet-learn-more">
                  <img src={learnMoreImg} alt="" />
                </td>
              </tr>
              <tr className="align-middle">
                <td>
                  <div>
                    <a
                      className="learn-more-link"
                      href={learnMoreLink}
                      target="_balnk"
                    >
                      Learn More
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>{plan.planTechnologyName}</div>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table className="table internet-card-table-second">
            <thead></thead>
            <tbody className="card-more-table">
              <tr>
                <td className="internet-price-guarantee">
                  <img src={internetPriceGuarantee} alt="" />
                </td>
              </tr>
              <tr>
                <td>Price Guarantee</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.PRICE_GUARANTEE
                      )?.value
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="internet-install">
                  <img src={internetInstall} alt="" />
                </td>
              </tr>
              <tr>
                <td>Installation</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.INSTALLATION
                      )?.value
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="is-router">
                  <img src={isRouter} alt="" />
                </td>
              </tr>
              <tr>
                <td>Router</td>
              </tr>
              <tr>
                <td>
                  <div>
                    {
                      iGlobals.productProperties.find(
                        (pp) =>
                          pp.productId === plan.productId &&
                          pp.propertyCode === EnumPropertyId.ROUTER
                      )?.value
                    }
                  </div>
                </td>
              </tr>
              <tr>
                <td className="internet-type">
                  <img src={internetType} alt="" />
                </td>
              </tr>
              <tr className="align-middle">
                <td>Type</td>
              </tr>
              <tr>
                <td>
                  <div>{plan.planTechnologyName}</div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <hr className="m-card-hr" />

      <div className="row">
        {(() => {
          if (plan.planDownloadSpeed >= 250 && plan.planDownloadSpeed < 500) {
            return (
              <div className="col-md-12 m-recommended-use-for">
                <h1>Average Use Recommended Use For:</h1>
                <ul>
                  <li>25 to 50 users or devices</li>
                  <li>Advanced Internet Needs</li>
                  <li>Regular On-Line Backups and File Sharing</li>
                  <li>Video and Web Conferences</li>
                </ul>
              </div>
            );
          } else if (
            plan.planDownloadSpeed >= 500 &&
            plan.planDownloadSpeed < 2000
          ) {
            return (
              <div className="col-md-12 m-recommended-use-for">
                <h1>High Speed Recommended Use For:</h1>
                <ul>
                  <li>50 to 200+ users or devices</li>
                  <li>eCommerce and Hosted Servers</li>
                  <li>Heavy Internet Usage</li>
                  <li>Cloud Applications Connectivity</li>
                  <li>VoIP and UCaaS Applications</li>
                </ul>
              </div>
            );
          } else if (
            plan.planDownloadSpeed >= 2000 &&
            plan.planDownloadSpeed < 5000
          ) {
            return (
              <div className="col-md-12 m-recommended-use-for">
                <h1>Ultra-Fast Recommended Use For:</h1>
                <ul>
                  <li>100 to 200 users or devices</li>
                  <li>Multiple Server Hosting</li>
                  <li>VoIP / UCaaS Applications</li>
                  <li>Cloud-Based Computing</li>
                </ul>
              </div>
            );
          } else if (plan.planDownloadSpeed >= 5000) {
            return (
              <div className="col-md-12 m-recommended-use-for">
                <h1>Supersonic Recommended Use For:</h1>
                <ul>
                  <li>200+ users or devices</li>
                  <li>Almost Anything</li>
                  <li>
                    Supersonic Operating Speed with Near-Zero Interruptions
                  </li>
                </ul>
              </div>
            );
          } else {
            return (
              <div className="col-md-12 m-recommended-use-for">
                <h1>Recommended Use For: </h1>
                <ul>
                  <li>Up to 25 users or devices</li>
                  <li>Heavy Web Browsing</li>
                  <li>Point-of-Sale Systems</li>
                  <li>On-line Backups and File Sharing</li>
                </ul>
              </div>
            );
          }
        })()}
      </div>
    </>
  );
};

export default InternetPlanLargeMobileExpanded;
