import React, { useEffect, useState } from "react";

import {
  EnumIncludePropertyId,
  EnumPropertyId,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";
import { redirectCompare } from "../../../helpers/redirectPages";

interface IVoicePlanLargeMobileProps {
  plan: IResponseVoicePlansDTO;
  onChangeCompareCheckBox: (planId: number) => void;
}

const VoicePlanLargeMobile: React.FC<IVoicePlanLargeMobileProps> = ({
  plan,
  onChangeCompareCheckBox,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  const onClickCompareLink = (e: any) => {
    redirectCompare(iPlanFilterParams.baseUrl);
  };

  let includesValues = [];
  includesValues = iGlobals.productProperties.filter(
    (pp) =>
      pp.productId === plan.productId &&
      EnumIncludePropertyId.includes(pp.propertyCode)
  );

  return (
    <div className="row align-middle">
      <div className="col-12">
        <img
          className="mx-auto d-block"
          src={`data:image/jpeg;base64,${
            iGlobals.carriers.find((c) => c.id === plan.providerId)?.imageBase64
          }`}
          alt=""
        />
      </div>
      <div className="col-12 text-center p-2">
        <p className="voice-bolt-bp service-type-name-mobile">
          {plan.serviceTypeName}
        </p>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-12 m-internet-card-first">
        <div className="text-head">Price</div>
        <div>
          ${plan.calculatedPrice.toFixed(2)} / mo. <br />
          {iPlanFilterParams.voiceParams.filterNumber}{" "}
          {iPlanFilterParams.voiceParams.filterNumber > 1 ? "seats" : "seat"}
          <br />
          Taxes + fees extra
        </div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-12 m-internet-card-first">
        <div className="text-head">Term</div>
        <div>{plan.term} Months</div>
      </div>
      <hr className="m-card-hr" />
      <div className="col-md-12 m-internet-card-first">
        <div className="text-head">Includes</div>
        <div>
          <ul className="voice-card-includes-lst">
            {includesValues.map((element) => (
              <li>{element.value}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default VoicePlanLargeMobile;
