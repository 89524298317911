import React from "react";

import {
  EnumPropertyId,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import longDistance from "../../../images/long-distance.png"; // with import
import voipPhone from "../../../images/phone.png"; // with import
import technology from "../../../images/technology.svg"; // with import
interface IVoicePlanLargeMobileExpandedProps {
  plan: IResponseVoicePlansDTO;
}

const VoicePlanLargeMobileExpanded: React.FC<
  IVoicePlanLargeMobileExpandedProps
> = ({ plan }) => {
  const [iGlobals] = useGlobalState("globals");

  return (
    <>
      <hr className="m-card-hr" />
      <div className="col-12 p-2">
        <table className="table voip-card-table-second">
          <tbody className="card-more-table">
            <tr>
              <td>
                <img src={technology} alt="" />
              </td>
            </tr>
            <tr>
              <td>Technology</td>
            </tr>
            <tr>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECHNOLOGY
                    )?.valueLong
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <img src={longDistance} alt="" />
              </td>
            </tr>
            <tr>
              <td>Long Distance</td>
            </tr>
            <tr>
              <td>
                <div>
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.LONG_DISTANCE
                  )?.valueShort || "No"}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <img src={voipPhone} alt="" />
              </td>
            </tr>
            <tr>
              <td>VOIP Phone</td>
            </tr>
            <tr>
              <td>
                <div>
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.EQUIPMENT_INCLUDED
                  )?.value || "Additional"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr className="m-card-hr" />
        <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th></th>
            </tr>
          </thead>
          <tbody className="card-more-table">
            <tr className="">
              <td>
                <div className="more-card-view-title">Recommended Use For:</div>
              </td>
            </tr>
            <tr className="">
              <td>
                <div className="more-card-view-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
                    )?.value
                  }
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VoicePlanLargeMobileExpanded;
