import React from 'react';
import { IResponseInternetPlansDTO } from '../../../contracts/types';
import { useGlobalState } from '../../../state';

interface IInternetPlanShortProps {
    plan: IResponseInternetPlansDTO;
}

const InternetPlanShort: React.FC<IInternetPlanShortProps> = ({ plan }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals] = useGlobalState('globals');

    const onClickGetStarted = (pPlan: IResponseInternetPlansDTO) => {
        let surveyData = iPlanFilterParams.surveyData;
        surveyData.planId = pPlan.planId;
        surveyData.providerId = pPlan.providerId;
        surveyData.internalPlanId = pPlan.internalPlanId;
        surveyData.isOpen = true;
        surveyData.orderId = -1;
        surveyData.isPageThanks = false;

        let internetParams = iPlanFilterParams.internetParams;
        internetParams.plan = pPlan;

        setIPlanFilterParams({
            ...iPlanFilterParams,
            'internetParams': internetParams,
            'surveyData': surveyData,
        });

        setTimeout(function () {
            window.open(iPlanFilterParams.baseUrl + 'quotenow', '_self');
        }, 100);
    };

    return (
        <div className="col-lg col-md-3">
            <div className="titleTab">{plan.planTypeName}</div>
            <div className="content3Prices">
                <span>$</span>
                <span>{plan.planPrice.toFixed(2)}</span>
                <span>monthly</span>
            </div>
            <a
                className={`buttonGetPrices ${plan.isLanding ? 'metrics-chooseOffert' : 'metrics-order'}`}
                href="javascript:void(0)"
                onClick={() => onClickGetStarted(plan)}
                data-brand={plan.providerName}
                data-value={plan.planPrice.toFixed(2)}
                data-frequency="Monthly"
            >
                Order now
            </a>
            <div className="squareBrandFD">
                <img src={`data:image/jpeg;base64,${iGlobals.carriers.find((c) => c.id === plan.providerId)?.imageBase64}`} />
            </div>
            <ul>
                <li>{plan.planTechnologyName}</li>
                <li>
                    <span>
                        <img src={`${iPlanFilterParams.imagesUrl}/download.jpg`} alt="" width="15px" />
                        {plan.planDownloadSpeed < 1000 ? `${plan.planDownloadSpeed} Mbps` : `${plan.planDownloadSpeed / 1000} Gbps`}
                    </span>
                    <span>
                        <img src={`${iPlanFilterParams.imagesUrl}/upload.jpg`} alt="" width="15px" />
                        {plan.planUploadSpeed < 1000 ? `${plan.planUploadSpeed} Mbps` : `${plan.planUploadSpeed / 1000} Gbps`}
                    </span>
                </li>
                <li>{plan.planTerm} Months</li>
                {
                    !plan.isLanding && plan.planModemIncluded && <li>Modem included</li>
                }
                {
                    !plan.isLanding && plan.planTechnologyName === 'Broadband' && (
                        <>
                            <li>Shared Connection</li>
                            <li>Best Effort</li>
                            <li>No SLA</li>
                        </>
                    )
                }
                {
                    !plan.isLanding && plan.planTechnologyName === 'Fiber' && (
                        <>
                            <li>Dedicated Fiber</li>
                            <li>SLA 99.9% or Higher</li>
                        </>
                    )
                }
                {
                    plan.isLanding && plan.promoDescription && (
                        <>
                            <li>{plan.promoDescription}</li>
                        </>
                    )
                }
            </ul>
        </div>
    );
};

export default InternetPlanShort;
