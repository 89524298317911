import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { EnumHTTPMessage } from "../../contracts/types";

export default function useTbiGetHttpMessate(httpMessate: EnumHTTPMessage, orderAddressId: number) {
	const api = useServiceApi();

	const doRequest = async (): Promise<string> => {
		if (httpMessate === EnumHTTPMessage.EMPTY || orderAddressId === -1) {
			return '';
		}

		const { data } = await api.get(`${ApiEndPoints.GetTbiHttpMessage}?httpMessate=${httpMessate}&orderAddressId=${orderAddressId}`);

		return JSON.stringify(data);
	};

	return useQuery<string>(
		['tbiGetHttpMessate', httpMessate, orderAddressId],
		() => doRequest(), {
		staleTime: Infinity,
		cacheTime: Infinity,
		enabled: true
	}
	);
}
