import React from 'react';
import { useGlobalState } from '../state';

interface ILoadingSmallProps {

}

const LoadingSmall: React.FC<ILoadingSmallProps> = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');

    return (
        <span>
            <img className='size-loading' src={`${iPlanFilterParams.imagesUrl}/loadingMultiple.gif`} height={20} alt="" />
        </span>
    );
};

export default LoadingSmall;