import React from "react";
import { useGlobalState } from "../state";

interface ILoadingProps {
  typeName: string;
}

const Loading: React.FC<ILoadingProps> = ({ typeName }) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");

  return (
    <div className="resultWait">
      <div className="animationLoading">
        <img
          src={`${iPlanFilterParams.imagesUrl}/SpinnerLoading.gif`}
          width={100}
          alt=""
        />
        <div>
          <h3>Searching ...</h3>
          <p className="internetprov-text">{typeName}</p>
          <img
            src={`${iPlanFilterParams.imagesUrl}/loadingImg.png`}
            width={100}
            className="imgSquareSize"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
