import React from 'react';

interface ISeeMorePlansProps {
    onClickSeeMorePlans: () => void;
}

const SeeMorePlans: React.FC<ISeeMorePlansProps> = ({ onClickSeeMorePlans }) => {
    return (
        <section className="elementor-section elementor-top-section elementor-element elementor-element-cd05133 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="cd05133" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ed53f5e" data-id="ed53f5e" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-f278eb9 elementor-widget elementor-widget-html" data-id="f278eb9" data-element_type="widget" data-widget_type="html.default">
                            <div className="elementor-widget-container">
                                <div className="container text-center container-seemore link-url metrics-SeeMorePlans" onClick={onClickSeeMorePlans}>
                                    <i className="fa fa-chevron-down" aria-hidden="true" />
                                    <span className="card-seemore">
                                        See more plans
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SeeMorePlans;
