import React from 'react';
import { EnumPlanType } from '../../../contracts/types';
import { useGlobalState } from '../../../state';
import CompareCardsBundle from './compareCardsBundle.comp';
import CompareCardsInternet from './compareCardsInternet.comp';
import CompareCardsVoice from './compareCardsVoice.comp';

interface ICompareCardsProps {

}

const CompareCards: React.FC<ICompareCardsProps> = () => {
    const [iFilters] = useGlobalState('filters');

    return (
        <>
            {
                iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && <CompareCardsInternet />
            }
            {
                iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE && <CompareCardsVoice />
            }
            {
                iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && <CompareCardsBundle />
            }
        </>
    );
};

export default CompareCards;
