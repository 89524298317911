import React from 'react';
import { useGlobalState } from '../../state';
import { IResponseVoicePlansDTO } from '../../contracts/types';

interface IItemPlansCompareProps {
    plan: IResponseVoicePlansDTO;
}

const ItemPlansCompareVoice: React.FC<IItemPlansCompareProps> = ({ plan }) => {
    const [iFilters, setIFilters] = useGlobalState('filters');

    const onClickRemovePlanCompare = () => {
        const plansCompare = iFilters.plansCompareVoice.filter((idPlan) => idPlan !== plan.planId);

        setIFilters({
            ...iFilters,
            plansCompareVoice: plansCompare,
        });
    }

    return (
        <tr>
            <td>
                <i className="fa fa-trash-o align-middle " aria-hidden="true" onClick={onClickRemovePlanCompare} />
            </td>
            <td>
                <span className="compareTextTable">{plan.planName}</span>
            </td>
            <td>${plan.calculatedPrice}</td>
        </tr>
    );
};

export default ItemPlansCompareVoice;
