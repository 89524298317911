import React from 'react';
import { IOrderAddressesDTO } from '../../contracts/types';

interface ISummaryBodyInternetProps {
    orderAddress: IOrderAddressesDTO;
}

export const SummaryBodyInternet: React.FC<ISummaryBodyInternetProps> = ({ orderAddress }) => {
    return (
        <div className="row tableInformationOC">
            <div>
                <span>Address: </span> {orderAddress.address}
            </div>
            <table className="tableOSPlan">
                <tr>
                    <th>Type</th>
                    <th>Brand</th>
                    <th>Speed</th>
                    <th>Attributes</th>
                    <th>Payment options</th>
                    <th>Price</th>
                </tr>
                {orderAddress && orderAddress.responseInternetPlans && (
                    <tr>
                        <td>{orderAddress.responseInternetPlans.planTypeName}</td>
                        <td>{orderAddress.responseInternetPlans.providerName}</td>
                        <td>
                            {orderAddress.responseInternetPlans.providerName !== 'Wiresurfer' ? (
                                orderAddress.responseInternetPlans.planDownloadSpeed < 1000 ? (
                                    orderAddress.responseInternetPlans.planDownloadSpeed
                                ) : orderAddress.responseInternetPlans.planDownloadSpeed ? (
                                    orderAddress.responseInternetPlans.planDownloadSpeed / 1000
                                ) : (
                                    0
                                )
                            ) : (
                                <></>
                            )}
                            {orderAddress.responseInternetPlans.providerName !== 'Wiresurfer' ? orderAddress.responseInternetPlans.planDownloadSpeed < 1000 ? 'Mbps' : 'Gbps' : <></>}
                        </td>
                        <td>
                            <ul>
                                {orderAddress.responseInternetPlans.providerName !== 'Wiresurfer' ? (
                                    <>
                                        <li>
                                            {orderAddress.responseInternetPlans.planTechnologyName}({orderAddress.responseInternetPlans.planServiceTypeName})
                                        </li>
                                        <li>
                                            <span>Download</span>&nbsp;
                                            {orderAddress.responseInternetPlans && orderAddress.responseInternetPlans.planDownloadSpeed < 1000
                                                ? orderAddress.responseInternetPlans.planDownloadSpeed
                                                : orderAddress.responseInternetPlans.planDownloadSpeed
                                                    ? orderAddress.responseInternetPlans.planDownloadSpeed / 1000
                                                    : 0}
                                            {orderAddress.responseInternetPlans && orderAddress.responseInternetPlans.planDownloadSpeed < 1000 ? 'Mbps' : 'Gbps'}
                                        </li>
                                        <li>
                                            <span>Upload</span>&nbsp;
                                            {orderAddress.responseInternetPlans && orderAddress.responseInternetPlans.planUploadSpeed < 1000
                                                ? orderAddress.responseInternetPlans.planUploadSpeed
                                                : orderAddress.responseInternetPlans.planUploadSpeed
                                                    ? orderAddress.responseInternetPlans.planUploadSpeed / 1000
                                                    : 0}
                                            {orderAddress.responseInternetPlans && orderAddress.responseInternetPlans.planUploadSpeed < 1000 ? 'Mbps' : 'Gbps'}
                                        </li>
                                        <li>{orderAddress.responseInternetPlans.planTerm} Months</li>
                                        <li>{orderAddress.responseInternetPlans.planModemIncluded ? 'Modem included' : 'Modem not included'}</li>
                                        {
                                            orderAddress.responseInternetPlans.promoDescription && orderAddress.responseInternetPlans.promoDescription !== '' &&
                                            <li>{orderAddress.responseInternetPlans.promoDescription}</li>
                                        }
                                    </>
                                ) : (
                                    'Wiresurfer Agent Required'
                                )}
                            </ul>
                        </td>
                        <td>{orderAddress.responseInternetPlans.providerName !== 'Wiresurfer' && 'Monthly'}</td>
                        <td>{orderAddress.responseInternetPlans.providerName !== 'Wiresurfer' && `$ ${orderAddress.responseInternetPlans.planPrice.toFixed(2)}`}</td>
                    </tr>
                )}
            </table>
        </div>
    );
};
