import React, { useEffect, useState } from 'react';
import { IFilterRange, IMinMax } from "../../../contracts/types";
import NumberFormat from 'react-number-format';

interface IFilterRangeProps {
    label: string;
    options: IFilterRange[];
    optionValue: IMinMax;
    onChangeValue: (minMax: IMinMax) => void;
}

const FilterRange: React.FC<IFilterRangeProps> = ({ label, options, optionValue, onChangeValue }) => {
    const [valueMinMax, setValueMinMax] = useState<IMinMax>(optionValue);
    const cssHreFilter = `metrics-Filter${label}`;
    const cssButtonGo = `metrics-Button${label}`;
    const cssMinFilter = `metrics-min${label}`;
    const cssMaxFilter = `metrics-max${label}`;

    const onClickOption = (minMax: IMinMax) => {
        onChangeValue(minMax);
        setValueMinMax(minMax);
    };

    const onClickGoOption = (e: any) => {
        onChangeValue(valueMinMax);
    };

    const onValueChangeMinOption = (values: any) => {
        const { value } = values;
        setValueMinMax({ ...valueMinMax, min: value ? value : 0 });
    };

    const onValueChangeMaxOption = (values: any) => {
        const { value } = values;
        setValueMinMax({ ...valueMinMax, max: value ? value : 0 });
    };

    return (
        <div>
            <h6>{label}</h6>
            {optionValue && (optionValue.min !== 0 || optionValue.max !== 0)
                ? <div><a href="javascript:void(0)" onClick={() => onClickOption({ min: 0, max: 0 })} className={cssHreFilter}>&lt;Any {label}</a></div>
                : <>
                    {
                        options.map((option, index) => (
                            <div key={index}>
                                <a href="javascript:void(0)" onClick={() => onClickOption(option.minMax)} className={cssHreFilter}>{option.label}</a>
                            </div>
                        ))
                    }
                </>
            }
            <div>
                <NumberFormat
                    className={`form-control ${cssMinFilter}`}
                    placeholder={"Min"}
                    thousandSeparator={true}
                    value={valueMinMax.min === 0 ? '' : valueMinMax.min}
                    onValueChange={onValueChangeMinOption}
                    decimalScale={0} />
                <NumberFormat
                    className={`form-control ${cssMaxFilter}`}
                    placeholder={"Max"}
                    thousandSeparator={true}
                    value={valueMinMax.max === 0 ? '' : valueMinMax.max}
                    onValueChange={onValueChangeMaxOption}
                    decimalScale={0} />
                <button onClick={onClickGoOption} className={cssButtonGo}>Go</button>
            </div>
        </div>
    );
};

export default FilterRange;