import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { EnumSearchType, IAuditVisitorSearch } from "../../contracts/types";
import { useGlobalState } from '../../state';

export default function useAuditSaveSearch() {
	const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');
	const api = useServiceApi();

	const doRequest = async (): Promise<IAuditVisitorSearch> => {
		const search = iAuditVisitor.search;

		const { data } = await api.post<IAuditVisitorSearch>(`${ApiEndPoints.GetAuditSaveSearch}`, search);

		if (data.type === EnumSearchType.MULTIPLE) {
			let searchMultiple = iAuditVisitor.searchMultiple;
			searchMultiple.push(data);

			setIAuditVisitor(
				{
					...iAuditVisitor,
					"search": {
						...iAuditVisitor.search,
						"id": data.id
					},
					"searchMultiple": searchMultiple
				});
		}
		else {
			setIAuditVisitor(
				{
					...iAuditVisitor,
					"search": {
						...iAuditVisitor.search,
						"id": data.id
					}
				});
		}


		return data;
	};

	return useQuery<IAuditVisitorSearch>(
		['saveAuditSearch', iAuditVisitor.search.planTypeCode],
		() => doRequest(), {
		//staleTime: Infinity,
		enabled: iAuditVisitor.search.id === -1 && iAuditVisitor.search.visitorId !== -1
	}
	);
}
