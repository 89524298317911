import React from 'react';
import Tab from './search/tab.comp';

interface ISearchTabProps {
    isPageCompare: boolean;
}

const Search: React.FC<ISearchTabProps> = ({ isPageCompare }) => {
    return (
        <>
            <Tab isPageCompare={isPageCompare} />
        </>
    );
};

export default Search;