import { EnumSortBy, IResponseInternetPlansDTO } from "../contracts/types";

export function sortByPlansInternet(arrayPlans: IResponseInternetPlansDTO[], sortBy: EnumSortBy): IResponseInternetPlansDTO[] {
	if (arrayPlans && arrayPlans.length === 0) {
		return [];
	}

	arrayPlans = arrayPlans.sort((a, b) => {
		switch (sortBy) {
			case EnumSortBy.LOW_TO_HIGH_PRICE:
				return a.planPrice - b.planPrice;
			case EnumSortBy.HIGH_TO_LOW_PRICE:
				return b.planPrice - a.planPrice;
			case EnumSortBy.LOW_TO_HIGH_SPEED:
				return a.planDownloadSpeed - b.planDownloadSpeed;
			case EnumSortBy.HIGH_TO_LOW_SPEED:
				return b.planDownloadSpeed - a.planDownloadSpeed;
			default:
				return b.planRated - a.planRated;
		}
	});

	return arrayPlans;
}