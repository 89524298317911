import React, { useState } from "react";
import { useEffect } from "react";
import { QueryClient } from "react-query";
import {
  EnumPlanType,
  IPageSurveyDTO,
  ISurveyQuestionResponse,
} from "../../contracts/types";
import { dispatchDataLayer } from "../../helpers/fnGoogleTag";
import useGetParameters from "../../hooks/api/useGetParameters";
import useSurveySave from "../../hooks/api/useSurveySave";
import { useGlobalState } from "../../state";
import LoadingSmall from "../loadingSmall.comp";

interface IWizardProps {
  children?: React.ReactNode[];
}

const queryClient = new QueryClient();

const Wizard: React.FC<IWizardProps> = ({ children }) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [activePageIndex, setActivePageIndex] = useState(0);
  const pages = React.Children.toArray(children);
  const currentPage: any = pages[activePageIndex];
  const pageSurveyDTO: IPageSurveyDTO = currentPage
    ? currentPage.props.pageSurveyDTO
    : undefined;

  const [showNext, setShowNext] = useState(false);
  const [showFinish, setShowFinish] = useState(false);
  const [disableNextFinish, setDisableNextFinish] = useState(false);

  const {} = useGetParameters();

  useEffect(() => {
    if (iPlanFilterParams.isClickCalendly) {
      return;
    }

    if (!pageSurveyDTO) {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        isClickCalendly: false,
        surveyData: {
          ...iPlanFilterParams.surveyData,
          isOpen: false,
          orderId: null,
          isPageThanks: false,
          urlOrderSummary: "",
          currentPageIndex: 0,
        },
        surveyResponse: {
          ...iPlanFilterParams.surveyResponse,
          orderId: null,
          orderAddressesDTO: [],
          surveyQuestionsResponse: [],
          encryptOrderId: "",
          isFinish: false,
          urlOrderSummary: "",
        },
      });
    } else {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        surveyData: {
          ...iPlanFilterParams.surveyData,
          currentPageIndex: activePageIndex,
        },
      });
    }

    if (pageSurveyDTO && activePageIndex !== 6) {
      let isDisabled = false;
      let viewNext = true;
      let viewFinish = false;
      let arraySurveyQuestionResponse: ISurveyQuestionResponse[] =
        iPlanFilterParams.surveyResponse.surveyQuestionsResponse === null
          ? []
          : iPlanFilterParams.surveyResponse.surveyQuestionsResponse;
      let arrayQuestionRequired =
        iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET
          ? pageSurveyDTO?.questionsDTO.filter(
              (q) => q.internet && q.required === true
            )
          : pageSurveyDTO?.questionsDTO.filter(
              (q) => q.voice && q.required === true
            );

      // Valid complete for Questions required
      arrayQuestionRequired.forEach((questionRequired) => {
        // Search questionResponse
        let surveyQuestionResponse = arraySurveyQuestionResponse.find(
          (q) => q.questionId === questionRequired.id
        );

        if (!surveyQuestionResponse || surveyQuestionResponse.value === "") {
          isDisabled = true;
        }

        if (
          !isDisabled &&
          questionRequired.type === "phone" &&
          surveyQuestionResponse &&
          surveyQuestionResponse.value.includes("_")
        ) {
          isDisabled = true;
        }
      });

      if (pageSurveyDTO.numPage === 1) {
        let questionEmailResponse = arraySurveyQuestionResponse.find(
          (q) => q.questionId === 4
        );

        if (questionEmailResponse && questionEmailResponse.value.length > 0) {
          let isEmailValid = true;
          let lastAtPos = questionEmailResponse.value.lastIndexOf("@");
          let lastDotPos = questionEmailResponse.value.lastIndexOf(".");

          if (
            !(
              lastAtPos < lastDotPos &&
              lastAtPos > 0 &&
              questionEmailResponse.value.indexOf("@@") === -1 &&
              lastDotPos > 2 &&
              questionEmailResponse.value.length - lastDotPos > 2
            )
          ) {
            isEmailValid = false;
          }

          if (!isEmailValid) {
            isDisabled = true;
          }
        }
      } else if (pageSurveyDTO.numPage === 2) {
        let questionNextInfo = pageSurveyDTO.questionsDTO.find(
          (q) => q.id === 5
        );

        if (questionNextInfo) {
          let questionResponse = arraySurveyQuestionResponse.find(
            (q) => q.questionId === questionNextInfo?.id
          );

          if (questionResponse && questionResponse.value !== "") {
            viewNext = false;
            viewFinish = true;

            if (questionResponse.value === "4") {
              viewNext = true;
              viewFinish = false;
            }
          }
        }
      } else if (pageSurveyDTO.numPage === 5) {
        const questionDate = pageSurveyDTO.questionsDTO.find(
          (q) => q.type === "date"
        );
        if (questionDate && questionDate.value !== "") {
          const surveyQuestionResponse = arraySurveyQuestionResponse.find(
            (q) => q.questionId === questionDate.id
          );
          if (surveyQuestionResponse && surveyQuestionResponse.value !== "") {
            const now = new Date();
            let dateValue = new Date(surveyQuestionResponse.value);

            if (surveyQuestionResponse.value.indexOf("/") !== -1) {
              const dateParts = surveyQuestionResponse.value.split("/");
              dateValue = new Date(
                parseInt(dateParts[2]),
                parseInt(dateParts[0]) - 1,
                parseInt(dateParts[1]),
                23,
                59
              );
            } else if (surveyQuestionResponse.value.indexOf("-") !== -1) {
              const dateParts = surveyQuestionResponse.value.split("-");
              dateValue = new Date(
                parseInt(dateParts[0]),
                parseInt(dateParts[1]) - 1,
                parseInt(dateParts[2]),
                23,
                59
              );
            }

            if (dateValue < now) {
              isDisabled = true;
            }
          }
        }
      }

      // if is last page
      if (activePageIndex === pages.length - 2) {
        viewNext = false;
        viewFinish = true;
      }

      setDisableNextFinish(isDisabled);

      setShowNext(viewNext);
      setShowFinish(viewFinish);
    }
  }, [iPlanFilterParams.surveyResponse, activePageIndex]);

  useEffect(() => {
    if (iPlanFilterParams.isClickCalendly) {
      return;
    }

    if (iPlanFilterParams.nextPageOrder) {
      goNextPage();

      setIPlanFilterParams({
        ...iPlanFilterParams,
        nextPageOrder: false,
      });
    }
  }, [iPlanFilterParams.nextPageOrder]);

  useEffect(() => {
    if (iPlanFilterParams.isClickCalendly) {
      onClickFinish();
    }
  }, [iPlanFilterParams.isClickCalendly]);

  const goNextPage = () => {
    if (activePageIndex === 0) {
      const email =
        iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find(
          (q) => q.questionId === 4
        )?.value;
      if (email) {
        const objDatLayer = {
          event: "nextStep1",
          email: email,
        };
        dispatchDataLayer(objDatLayer);
      }
    } else if (activePageIndex === 1 && pageSurveyDTO) {
      const optSelected =
        iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find(
          (q) => q.questionId === 5
        )?.value;
      const optsQuestion = pageSurveyDTO.questionsDTO.find((q) => q.id === 5);
      console.log("sdfdsf");

      if (optSelected && optsQuestion) {
        const questionOptionDTO = optsQuestion.questionOptionsDTO.find(
          (r) => r.id === parseInt(optSelected)
        );

        if (questionOptionDTO) {
          const label = questionOptionDTO.label.replace(
            "${orderId}",
            iPlanFilterParams.surveyResponse.orderId
              ? iPlanFilterParams.surveyResponse.orderId.toString()
              : ""
          );
          let event = "";

          if (parseInt(optSelected) === 4) {
            event = "nextStep2";
          } else if (parseInt(optSelected) === 1) {
            event = "finishStep2";
          }

          const objDatLayer = {
            event: event,
            option: label,
          };

          dispatchDataLayer(objDatLayer);
        }
      }
    }
    if (activePageIndex >= 2) {
      const objDatLayer = {
        event: `nextStep${activePageIndex + 1}`,
      };
      dispatchDataLayer(objDatLayer);
    }

    finishSurvey();

    setActivePageIndex((index) => index + 1);
    setShowNext(true);
    setShowFinish(false);
  };

  const goPrevPage = () => {
    finishSurvey();

    setActivePageIndex((index) => index - 1);
    setShowNext(true);
  };

  const finishSurvey = useSurveySave(showFinish);

  const onClickFinish = () => {
    if (activePageIndex === 1 && pageSurveyDTO) {
      const optSelected =
        iPlanFilterParams.surveyResponse.surveyQuestionsResponse.find(
          (q) => q.questionId === 5
        )?.value;
      const optsQuestion = pageSurveyDTO.questionsDTO.find((q) => q.id === 5);

      if (optSelected && optsQuestion) {
        const questionOptionDTO = optsQuestion.questionOptionsDTO.find(
          (r) => r.id === parseInt(optSelected)
        );
        if (questionOptionDTO) {
          const label = questionOptionDTO.label.replace(
            "${orderId}",
            iPlanFilterParams.surveyResponse.orderId
              ? iPlanFilterParams.surveyResponse.orderId.toString()
              : ""
          );
          let event = "";

          if (parseInt(optSelected) === 4) {
            event = "nextStep2";
          } else if (parseInt(optSelected) === 1) {
            event = "finishStep2";
          }

          const objDatLayer = {
            event: event,
            option: label,
          };

          dispatchDataLayer(objDatLayer);
        }
      }
    }

    finishSurvey();

    setIPlanFilterParams({
      ...iPlanFilterParams,
      surveyData: {
        ...iPlanFilterParams.surveyData,
        isPageThanks: true,
      },
      idAddressMultiple: -1,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams: [],
      },
    });

    queryClient.invalidateQueries("survey");
    setActivePageIndex(pages.length - 1);
  };

  const ButtonPrev = () =>
    activePageIndex > 0 ? (
      <input
        type="button"
        value="Back"
        className="btnBackward"
        onClick={goPrevPage}
      />
    ) : null;

  const ButtonNextFinish = () => {
    return (
      <>
        {activePageIndex === 1 &&
        iPlanFilterParams.surveyResponse.orderId === 0 ? (
          <LoadingSmall />
        ) : (
          <>
            {showNext && (
              <input
                type="button"
                value="Next"
                className={
                  disableNextFinish ? "btnForwardDisable" : "btnForward"
                }
                onClick={goNextPage}
                disabled={disableNextFinish}
              />
            )}
            {showFinish && (
              <input
                type="button"
                value="Finish"
                className={
                  disableNextFinish ? "btnForwardDisable" : "btnForward"
                }
                onClick={onClickFinish}
                disabled={disableNextFinish}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="wizard">
      <div className="wizard__content">{currentPage}</div>
      {pageSurveyDTO && pageSurveyDTO.numPage === pages.length ? (
        <></>
      ) : (
        !iPlanFilterParams.surveyData.isPageThanks && (
          <div className="wizard__buttons">
            <ButtonPrev />
            <ButtonNextFinish />
          </div>
        )
      )}
    </div>
  );
};

export default Wizard;
