import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IQuestionDTO } from '../../../contracts/types';
import { useGlobalState } from '../../../state';
import NumberFormat from 'react-number-format';

interface IQuestionStringProps {
    questionDTO: IQuestionDTO;
    onChange: (value: string, billingSmarty: any) => void;
}

const QuestionString: React.FC<IQuestionStringProps> = ({ questionDTO, onChange }) => {


    const [iPlanFilterParams] = useGlobalState('planFilterParams');

    const surveyQuestionsResponse = iPlanFilterParams.surveyResponse.surveyQuestionsResponse.filter(q => q.questionId === questionDTO.id);

    const value = surveyQuestionsResponse.length > 0 ? surveyQuestionsResponse[0].value : questionDTO.defaultValue;
    const isValid = surveyQuestionsResponse.length > 0 ? surveyQuestionsResponse[0].isValid : null;

    const onChangeString = (e: any) => {
        onChange(e.target.value, null);
    };

    let uuidInput = uuidv4();

    return (
        <div className="row">
            <div className="holder">
                {
                    questionDTO.type === "phone"
                        ?
                        <NumberFormat
                            id={uuidInput}
                            //id={questionDTO.id.toString()}
                            format="+1 (###) ###-####"
                            mask="_"
                            className={`control ${isValid === null ? '' : (isValid ? 'questionTrue' : 'questionFalse')}`}
                            autoComplete='do-not-autofill'
                            value={value}
                            onChange={onChangeString}
                            title={questionDTO.label}
                            required={questionDTO.required}
                        />
                        :
                        <>
                            <input
                                id={uuidInput}
                                //id={questionDTO.id.toString()}
                                type={questionDTO.type === "date" ? "date" : "text"}
                                className={`control ${isValid === null ? '' : (isValid ? 'questionTrue' : 'questionFalse')}`}
                                autoComplete="do-not-autofill"
                                value={value}
                                onChange={onChangeString}
                                title={questionDTO.label}
                                required={questionDTO.required}

                            />
                            {
                                isValid === false && questionDTO.type === 'email' &&
                                <span className="labelQuestionFalse">Not a valid email address</span>
                            }
                        </>
                }
            </div>
        </div>
    );
};

export default QuestionString;