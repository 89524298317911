import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { ISmartyResponseDTO } from "../../contracts/types";

export default function useSearchSuggestionsSM(search: string, selected: string,  searchAddresses: boolean) {
	
	const api = useServiceApi();
	const searchEncode = encodeURI(search);
	const selectedEncode = encodeURI(selected);

	const doRequest = async (): Promise<ISmartyResponseDTO[]> => {
		const url = `${ApiEndPoints.GetSearchSuggestionsSM}&search=${searchEncode}&selected=${selectedEncode}`;
		const { data } = await api.get(url);

		return data.suggestions;
	};

	return useQuery<ISmartyResponseDTO[]>(
		['suggestionsSM', searchEncode, selectedEncode],
		() => doRequest(), {
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: searchEncode !== '' && searchAddresses && search.length>=3
		}
	);
}
