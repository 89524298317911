import React, { useEffect, useState } from "react";
import {
  EnumViewPlansLargeType,
  EnumSortBy,
  IOptionSelect,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveSortBy from "../../../hooks/api/useAuditSaveSortBy";
import Select from "../../select.comp";

const InternetPlanTools: React.FC = () => {
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");
  const [showTabsViewType, setShowTabsViewType] = useState(false);

  useEffect(() => {
    if (iFilters.showMoreLocations) {
      setShowTabsViewType(true);
    } else {
      setShowTabsViewType(false);
    }
  }, [iFilters.showMoreLocations]);

  const {} = useAuditSaveSortBy();

  const onClickDetailsView = (e: any) => {
    setIFilters({
      ...iFilters,
      viewPlansLargeTypeInternet: EnumViewPlansLargeType.DETAILS,
    });
  };

  const onClickMultiAdrressView = (e: any) => {
    setIFilters({
      ...iFilters,
      viewPlansLargeTypeInternet: EnumViewPlansLargeType.MULTIADDRESS,
    });
  };

  const onChangeSort = (optionSelected: IOptionSelect) => {
    setIFilters({
      ...iFilters,
      sortBy: optionSelected.value,
    });

    setIAuditVisitor({
      ...iAuditVisitor,
      searchSortBy: {
        ...iAuditVisitor.searchSortBy,
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        sortBy: optionSelected.value,
      },
    });
  };

  const INTERNET_SORT_BY: IOptionSelect[] = [
    { value: EnumSortBy.NONE, label: "Select One" },
    { value: EnumSortBy.LOW_TO_HIGH_PRICE, label: "Price: low to high" },
    { value: EnumSortBy.HIGH_TO_LOW_PRICE, label: "Price: high to low" },
    { value: EnumSortBy.LOW_TO_HIGH_SPEED, label: "Speed: low to high" },
    { value: EnumSortBy.HIGH_TO_LOW_SPEED, label: "Speed: high to low" },
  ];

  return (
    <>
      {
        <div className="row d-flex justify-content-around align-items-center">
          <div id="iconFilterLayout" className="col-6">
            {showTabsViewType && (
              <>
                <button
                  onClick={onClickDetailsView}
                  className={
                    iFilters.viewPlansLargeTypeInternet ===
                    EnumViewPlansLargeType.DETAILS
                      ? "iconListWS iconListViewDisable"
                      : "iconListWS iconListViewEnable"
                  }
                  disabled={
                    iFilters.viewPlansLargeTypeInternet ===
                    EnumViewPlansLargeType.DETAILS
                  }
                />

                <button
                  onClick={onClickMultiAdrressView}
                  className={
                    iFilters.viewPlansLargeTypeInternet ===
                    EnumViewPlansLargeType.MULTIADDRESS
                      ? "iconListGR iconListViewDisable"
                      : "iconListGR buttonViewGroupAddressEnable"
                  }
                  disabled={
                    iFilters.viewPlansLargeTypeInternet ===
                    EnumViewPlansLargeType.MULTIADDRESS
                  }
                ></button>
              </>
            )}
          </div>
          <div id="orderBy" className="col-12 col-md-6 align-items-center">
            <span className="filtersortby">Sort by:</span>
            <Select
              optionsSelect={INTERNET_SORT_BY}
              onChange={onChangeSort}
              value={iFilters.sortBy.toString()}
            />
          </div>
        </div>
      }
    </>
  );
};

export default InternetPlanTools;
