import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IParametersResponseDTO } from "../../contracts/types";

export default function useGetParameters() {

	const api = useServiceApi();

	const doRequest = async (): Promise<IParametersResponseDTO> => {

		const { data } = await api.get(`${ApiEndPoints.GetParameters}`);

        return data;
    };

	return useQuery<IParametersResponseDTO>(
        ['parameters'],
        () => doRequest(),{
            staleTime: Infinity,
			cacheTime: Infinity,
			enabled: true // iPlanFilterParams.surveyData.orderId === null && iPlanFilterParams.surveyData.isOpen
        }
    );
}
