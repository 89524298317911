import React from 'react';
import { IResponseInternetPlansDTO } from '../../../contracts/types';
import { useGlobalState } from '../../../state';

interface IBundlePlanShortProps {
    plan: IResponseInternetPlansDTO;
}

const BundlePlanShort: React.FC<IBundlePlanShortProps> = ({ plan }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iGlobals] = useGlobalState('globals');

    const onClickGetStarted = () => {
        let surveyData = iPlanFilterParams.surveyData;
        surveyData.planId = plan.planId;
        surveyData.providerId = plan.providerId;
        surveyData.internalPlanId = plan.internalPlanId;
        surveyData.isOpen = true;
        surveyData.orderId = -1;
        surveyData.isPageThanks = false;

        setIPlanFilterParams({
            ...iPlanFilterParams,
            bundleParams: {
                ...iPlanFilterParams.bundleParams,
                plan: plan,
            },
            'surveyData': surveyData,
        });

        if (!iPlanFilterParams.isLocalhost) {
            window.open(iPlanFilterParams.baseUrl + 'quotenow', '_self');
        }
    };

    return (
        <div className="col-lg col-md-3">
            <div className="titleTab">{plan.planTypeName}</div>
            <div className="content3Prices">
                <span>$</span>
                <span>{plan.planPrice.toFixed(2)}</span>
                <span>monthly</span>
            </div>
            <a className="buttonGetPrices" href="javascript:void(0)" onClick={() => onClickGetStarted()}>
                Order now
            </a>
            <div className="squareBrandFD">
                <img src={`data:image/jpeg;base64,${iGlobals.carriers.find((c) => c.id === plan.providerId)?.imageBase64}`} alt="" />
            </div>
            <ul>
                <li>{plan.planTechnologyName}</li>
                <li>
                    <span>
                        <img src={`${iPlanFilterParams.imagesUrl}/download.jpg`} alt="" width="15px" />
                        {plan.planDownloadSpeed < 1000 ? `${plan.planDownloadSpeed} Mbps` : `${plan.planDownloadSpeed / 1000} Gbps`}
                    </span>
                    <span>
                        <img src={`${iPlanFilterParams.imagesUrl}/upload.jpg`} alt="" width="15px" />
                        {plan.planUploadSpeed < 1000 ? `${plan.planUploadSpeed} Mbps` : `${plan.planUploadSpeed / 1000} Gbps`}
                    </span>
                </li>
                <li>{plan.planTerm} Months</li>
                {plan.planModemIncluded && <li>Modem included</li>}
                {plan.planTechnologyName === 'Broadband' && (
                    <>
                        <li>Shared Connection</li>
                        <li>Best Effort</li>
                        <li>No SLA</li>
                    </>
                )}
                {plan.planTechnologyName === 'Fiber' && (
                    <>
                        <li>Dedicated Fiber</li>
                        <li>SLA 99.9% or Higher</li>
                    </>
                )}
            </ul>
        </div>
    );
};

export default BundlePlanShort;
