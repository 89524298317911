import React from "react";
import { EnumSortBy, IOptionSelect } from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveSortBy from "../../../hooks/api/useAuditSaveSortBy";
import Select from "../../select.comp";

const VoicePlanTools: React.FC = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");

  const {} = useAuditSaveSortBy();

  const onChangeSort = (optionSelected: IOptionSelect) => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      voiceParams: {
        ...iPlanFilterParams.voiceParams,
        sortBy: optionSelected.value,
      },
    });

    setIAuditVisitor({
      ...iAuditVisitor,
      searchSortBy: {
        ...iAuditVisitor.searchSortBy,
        id: -1,
        visitorSearchId: iAuditVisitor.search.id,
        sortBy: optionSelected.value,
      },
    });
  };

  const VOICE_SORT_BY: IOptionSelect[] = [
    { value: EnumSortBy.NONE, label: "Select One" },
    { value: EnumSortBy.LOW_TO_HIGH_PRICE, label: "Price: low to high" },
    { value: EnumSortBy.HIGH_TO_LOW_PRICE, label: "Price: high to low" },
  ];

  return (
    <div className="row d-flex justify-content-around align-items-center">
      <div id="orderBy" className="col-12 col-md-6 align-items-center">
        <span className="filtersortby">Sort by:</span>
        <Select
          optionsSelect={VOICE_SORT_BY}
          onChange={onChangeSort}
          value={iPlanFilterParams.voiceParams.sortBy.toString()}
        />
      </div>
    </div>
  );
};

export default VoicePlanTools;
