import { useServiceApi, ApiEndPoints } from '../../api';
import { EnumPlanType, IOrderAddressesDTO, IOrderFeatureDTO, IOrderVoiceEquipmentDTO, ISurveyResponse } from '../../contracts/types';
import { useGlobalState } from '../../state';
import useVoiceEquipments from './useVoiceEquipments';

export default function useSurveySave(isFinish: boolean) {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters] = useGlobalState('filters');
    const [iAuditVisitor] = useGlobalState('auditVisitor');

    const api = useServiceApi();

    const { } = useVoiceEquipments();

    const saveSurvey = async () => {
        let surveyRequest: ISurveyResponse = iPlanFilterParams.surveyResponse;

        surveyRequest.orderId = iPlanFilterParams.surveyData.orderId;
        surveyRequest.encryptOrderId = iPlanFilterParams.surveyData.encryptOrderId;
        surveyRequest.isFinish = isFinish;
        surveyRequest.baseUrl = iPlanFilterParams.baseUrl;
        surveyRequest.billingSmarty = iPlanFilterParams.billingSmarty;
        surveyRequest.urlOrderSummary = iPlanFilterParams.surveyData.urlOrderSummary;
        surveyRequest.planTypeCode = iFilters.planTypeCode;
        surveyRequest.referred = iAuditVisitor.referred;
        surveyRequest.visitorId = iAuditVisitor.id;
        const featuresPlan: IOrderFeatureDTO[] = [];

        if (iPlanFilterParams.isClickCalendly) {
            surveyRequest.isFinish = true;
        }

        if (surveyRequest.orderId === 0) {
            let arraySearchId: number[] = [];
            arraySearchId.push(iAuditVisitor.search.id);

            if (iPlanFilterParams?.multipleAddresses && iPlanFilterParams.idAddressMultiple !== -1) {
                arraySearchId = iAuditVisitor.searchMultiple.map((s) => s.id);
            }

            surveyRequest.arrayVisitorSearchId = arraySearchId;
        }

        surveyRequest.orderAddressesDTO = [];
        surveyRequest.equipmentsVoice = [];

        if (surveyRequest.planTypeCode === EnumPlanType.BUSINESS_INTERNET) {
            if (iPlanFilterParams.multipleAddresses.multipleInternetParams.length >= 1) {
                iPlanFilterParams.multipleAddresses.multipleInternetParams.forEach((internetParam) => {
                    if (internetParam.plan && internetParam.plan.planInstallPrice === 0) {
                        internetParam.plan.planInstallPrice = undefined;
                    }
                    if (internetParam.plan && internetParam.plan.internalPlanId === -1) {
                        internetParam.plan.planNote = '';
                    }

                    const orderAddress: IOrderAddressesDTO = {
                        orderId: surveyRequest.orderId,
                        responseInternetPlans: internetParam.plan ? internetParam.plan : undefined,
                        streetLine: internetParam.streetLine,
                        city: internetParam.city,
                        state: internetParam.state,
                        zipCode: internetParam.zipCode,
                        secondary: internetParam.secondary,
                    };

                    if (orderAddress.responseInternetPlans) {
                        orderAddress.responseInternetPlans.providerLogo = '';
                    }

                    surveyRequest.orderAddressesDTO.push(orderAddress);
                });
            } else {
                if (iPlanFilterParams.internetParams.plan && iPlanFilterParams.internetParams.plan.planInstallPrice === 0) {
                    iPlanFilterParams.internetParams.plan.planInstallPrice = undefined;
                }
                if (iPlanFilterParams.internetParams.plan && iPlanFilterParams.internetParams.plan.internalPlanId === -1) {
                    iPlanFilterParams.internetParams.plan.planNote = '';
                }

                const orderAddress: IOrderAddressesDTO = {
                    orderId: surveyRequest.orderId,
                    responseInternetPlans: iPlanFilterParams.internetParams.plan ? iPlanFilterParams.internetParams.plan : undefined,
                    streetLine: iPlanFilterParams.internetParams.streetLine,
                    city: iPlanFilterParams.internetParams.city,
                    state: iPlanFilterParams.internetParams.state,
                    zipCode: iPlanFilterParams.internetParams.zipCode,
                    secondary: iPlanFilterParams.internetParams.secondary,
                };

                if (orderAddress.responseInternetPlans) {
                    orderAddress.responseInternetPlans.providerLogo = '';
                }

                surveyRequest.orderAddressesDTO.push(orderAddress);
            }
        }
        else if (surveyRequest.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
            const orderAddress: IOrderAddressesDTO = {
                orderId: surveyRequest.orderId,
                responseBundlePlans: iPlanFilterParams.bundleParams.plan ? iPlanFilterParams.bundleParams.plan : undefined,
                streetLine: iPlanFilterParams.bundleParams.streetLine,
                city: iPlanFilterParams.bundleParams.city,
                state: iPlanFilterParams.bundleParams.state,
                zipCode: iPlanFilterParams.bundleParams.zipCode,
                secondary: iPlanFilterParams.bundleParams.secondary,
            };

            if (orderAddress.responseInternetPlans) {
                orderAddress.responseInternetPlans.providerLogo = '';
            }

            surveyRequest.orderAddressesDTO.push(orderAddress);
        }
        else {
            if (iPlanFilterParams.voiceParams.plan) {
                surveyRequest.planVoice = iPlanFilterParams.voiceParams.plan;
                surveyRequest.planVoice.numberOfSeats = iPlanFilterParams.voiceParams.filterNumber;

                if (surveyRequest.planVoice) {
                    iPlanFilterParams.voiceParams.equipmentConfig.forEach((eqConfig) => {
                        const planEquipment = eqConfig.equipmentPlanConfig.find((pe) => pe.planId === surveyRequest.planVoice?.planId);

                        if (planEquipment) {
                            const orderVoiceEquipment: IOrderVoiceEquipmentDTO = {
                                orderId: surveyRequest.orderId,
                                equipmentId: eqConfig.equipmentId,
                                equipmentName: eqConfig.equipmentName,
                                equipmentNumber: planEquipment.nEquipments,
                                equipmentPrice: planEquipment.price,
                            };

                            surveyRequest.equipmentsVoice.push(orderVoiceEquipment);
                        }
                    });
                }

                iPlanFilterParams.subProductsPlan.forEach((subProPla) => {
                    const subPro = iPlanFilterParams.voiceParams.filterConfigSubProducts.find((sp) => sp.id === subProPla.subProductId)?.plans.find((pl) => pl.productId === iPlanFilterParams.voiceParams.plan?.productId);
                    let unitPrice = 0;

                    if (subPro && subPro.values) {
                        const subProPlanVal = subPro.values
                            .sort((a, b) => {
                                return a.quantity - b.quantity;
                            })
                            .find((s) => subPro.filterConfig >= s.quantity);
                        unitPrice = subProPlanVal ? subProPlanVal.unitPrice : 0;
                    }

                    const orderSubProd: IOrderFeatureDTO = {
                        orderId: surveyRequest.orderId,
                        featureId: subProPla.subProductId,
                        description: subProPla.description,
                        featureNumber: subProPla.subProductNumber,
                        price: unitPrice,
                    };

                    featuresPlan.push(orderSubProd);
                });
            }
        }

        surveyRequest.featuresPlan = featuresPlan;

        const { data } = await api.post(`${ApiEndPoints.SetSurveyResponse}`, surveyRequest);

        if (surveyRequest.orderId === 0) {
            let surveyData = iPlanFilterParams.surveyData;
            let surveyResponse = iPlanFilterParams.surveyResponse;

            surveyData.orderId = data.orderId;
            surveyData.encryptOrderId = data.encryptOrderId;
            surveyData.urlOrderSummary = `${iPlanFilterParams.baseUrl}quotesummary/?quote=${surveyData.encryptOrderId}`;
            surveyResponse.orderId = surveyData.orderId;
            surveyResponse.encryptOrderId = surveyData.encryptOrderId;

            setIPlanFilterParams({
                ...iPlanFilterParams,
                'surveyData': surveyData,
                'surveyResponse': surveyResponse,
            });
        }
    };

    return saveSurvey;
}
