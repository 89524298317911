import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { EnumSearchType, IAuditVisitorSearchFilter } from "../../contracts/types";
import { useGlobalState } from "../../state";

export default function useAuditSaveFilter() {
	const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');
	const api = useServiceApi();

	const doRequest = async (): Promise<IAuditVisitorSearchFilter> => {
		const filter = iAuditVisitor.searchFilter;
		let arraySearchId: number[] = [];
		arraySearchId.push(filter.visitorSearchId);
		
		if (iAuditVisitor.search.type === EnumSearchType.MULTIPLE) {
			arraySearchId = iAuditVisitor.searchMultiple.map(s => s.id);
		}

		filter.arraySearchsId = arraySearchId;
		
		const { data } = await api.post<IAuditVisitorSearchFilter>(`${ApiEndPoints.GetAuditSaveFilter}`, filter);

		setIAuditVisitor(
			{
				...iAuditVisitor,
				"searchFilter": {
					...iAuditVisitor.searchFilter,
					"id": data.id
				},

			});

		return data;
	};

	return useQuery<IAuditVisitorSearchFilter>(
		['saveAuditFilter'],
		() => doRequest(), {
			//staleTime: Infinity,
			enabled: iAuditVisitor.searchFilter.id === -1 && iAuditVisitor.searchFilter.visitorSearchId !== -1 && (iAuditVisitor.searchFilter.value1 !== '' || iAuditVisitor.searchFilter.value2 !== '')
		}
	);
}
