import React, { useEffect, useState } from 'react';
import useSearchOrder from '../hooks/api/useSearchOrder';
import { SummaryHead } from './orderSummary/summaryHead';
import { SummaryBody } from './orderSummary/summaryBody';
import { SummaryFoot } from './orderSummary/summaryFoot';

const SearchOrder: React.FC = ({}) => {
    const [encryptOrderId, setEncryptOrderId] = useState('');

    const { data } = useSearchOrder(encryptOrderId);

    useEffect(() => {
        const encryptOrder = new URLSearchParams(window.location.search).get('quote');

        if (encryptOrder && encryptOrder !== '') {
            setEncryptOrderId(encryptOrder);
        }
    }, []);

    return (
        <div className="container squareOrderConfirmation">
            {data && (
                <>
                    <SummaryHead surverSearch={data} />
                    <SummaryBody surverSearch={data} />
                    <br />
                    <br />
                    <SummaryFoot surverSearch={data} />
                </>
            )}
        </div>
    );
};

export default SearchOrder;
