import React from 'react';

interface IBundlePlanLargeMobileCollapsedProps {

}

const BundlePlanLargeMobileCollapsed: React.FC<IBundlePlanLargeMobileCollapsedProps> = () => {

    return (
        <>

        </>
    );
};

export default BundlePlanLargeMobileCollapsed;
