import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../state";
import {
  EnumPlanTechnologyType,
  EnumPlanType,
  EnumSearchType,
  EnumViewPlansLargeType,
  IAuditVisitorSearch,
  IMultipleAddresses,
  IPlanFilterParamsBundle,
  IPlanFilterParamsInternet,
  ISmartyResponseDTO,
} from "../../contracts/types";
import useSearchSuggestionsSM from "../../hooks/api/useSearchSuggestionsSM";
import useAuditSaveSearch from "../../hooks/api/useAuditSaveSearch";

interface ISearchAddressSMProps {
  isMultipleAddress: boolean;
  placeHolder: string;
  isBillingAddress?: boolean | false;
  onChangeAddressParent?: (e: any) => void;
  onClickSelectAddressParent?: (smartyResponseDTO: ISmartyResponseDTO) => void;
  onClickSelectAddAddressParent?: (
    internetParams: IPlanFilterParamsInternet
  ) => void;
  cssInputAddress?: string;
  smartyDTO?: ISmartyResponseDTO | null;
}

const AddressText: React.FC<ISearchAddressSMProps> = ({
  isMultipleAddress,
  placeHolder,
  isBillingAddress,
  onChangeAddressParent,
  onClickSelectAddressParent,
  onClickSelectAddAddressParent,
  cssInputAddress = "",
  smartyDTO,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");
  const [iFilters] = useGlobalState("filters");

  const [activeAddress, setActiveAddress] = useState(0);
  const [showAddresses, setShowAddresses] = useState(false);
  const [searchAddresses, setSearchAddresses] = useState(false);
  const [suggestions, setSuggestions] = useState<ISmartyResponseDTO[]>([]);
  const [addressValue, setAddressValue] = useState(
    isBillingAddress
      ? iPlanFilterParams.billingAddresSelected === "INP"
        ? iPlanFilterParams.billingAddress
        : ""
      : iPlanFilterParams.internetParams.address
  );
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState(
    isBillingAddress
      ? iPlanFilterParams.billingAddresSelected === "INP"
        ? iPlanFilterParams.billingAddress
        : ""
      : iPlanFilterParams.internetParams.addressDetail
  );
  const [smartyResponse, setSmartyResponse] = useState<ISmartyResponseDTO>();

  const { data } = useSearchSuggestionsSM(
    addressValue,
    selectedAddress,
    searchAddresses
  );

  const {} = useAuditSaveSearch();

  useEffect(() => {
    if (iFilters.showMoreLocations) {
      if (smartyDTO && smartyDTO.street_line !== "") {
        setAddressValue(`${smartyDTO.street_line} ${smartyDTO.city}`);
        setAddressDetail(getAddressDetail(smartyDTO));
      } else {
        setAddressValue("");
        setAddressDetail("");
      }

      return;
    }

    if (
      iPlanFilterParams.internetParams.address !== "" ||
      isMultipleAddress ||
      isBillingAddress
    ) {
      if (isBillingAddress) {
        return;
      }
      if (
        iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
        isMultipleAddress
      ) {
        setAddressValue("");
        setAddressDetail("");

        if (
          iPlanFilterParams.smartyAddressInternet &&
          iPlanFilterParams.internetParams.zipCode === "-1"
        ) {
          setSmartyResponse(iPlanFilterParams.smartyAddressInternet);
        }

        return;
      }

      if (window.location.href === iPlanFilterParams.baseUrl) {
        setAddressValue("");
        setAddressDetail("");
      } else {
        setAddressValue(iPlanFilterParams.internetParams.address);
        setAddressDetail(iPlanFilterParams.internetParams.addressDetail);
      }
    } else if (
      window.location.pathname.includes("search") &&
      (iPlanFilterParams.smartyAddressInternet ||
        iPlanFilterParams.smartyAddressBundle)
    ) {
      const smartyAddIntBun = iPlanFilterParams.smartyAddressInternet
        ? iPlanFilterParams.smartyAddressInternet
        : iPlanFilterParams.smartyAddressBundle;

      if (smartyAddIntBun) {
        setAddressValue(
          `${smartyAddIntBun.street_line} ${smartyAddIntBun.city}`
        );
        setAddressDetail(getAddressDetail(smartyAddIntBun));

        setSmartyResponse(smartyAddIntBun);
      }
    }

    if (!isBillingAddress && smartyDTO && smartyDTO.street_line !== "") {
      setAddressValue(`${smartyDTO.street_line} ${smartyDTO.city}`);
      setAddressDetail(getAddressDetail(smartyDTO));
    }
  }, []);

  useEffect(() => {
    if (data) {
      setSuggestions([]);
      setSuggestions(data);
      setActiveAddress(0);
      setShowAddresses(true);
      setSearchAddresses(false);
    }
  }, [data]);

  useEffect(() => {
    if (
      smartyResponse &&
      `${smartyResponse.street_line}${smartyResponse.city}` !== ""
    ) {
      const address = `${smartyResponse.street_line} ${smartyResponse.city}`;

      if (isBillingAddress) {
        return;
      }

      if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET) {
        let newAddress: IPlanFilterParamsInternet = {
          streetLine: smartyResponse.street_line,
          city: smartyResponse.city,
          zipCode: smartyResponse.zipcode,
          address: address,
          addressDetail: addressDetail,
          state: smartyResponse.state,
          secondary: smartyResponse.secondary,
          latitude: 0,
          longitude: 0,
          country: "US",
          idMultiple: 0,
          openedGroupAddressPlans: false,
          smartyAddress: null,
          plansByCodeProvider: [],
          plan: {
            planId: 0,
            productId: 0,
            internalPlanId: 0,
            planName: "",
            planServiceTypeCode: "",
            planTypeCode: EnumPlanType.BUSINESS_INTERNET,
            planTypeName: "",
            displayName: "",
            providerId: 0,
            providerName: "",
            providerLogo: "",
            planRated: 0,
            planFiberFastQualType: "",
            planTechnologyName: "",
            planDownloadSpeed: 0,
            planUploadSpeed: 0,
            planTerm: 0,
            planModemIncluded: false,
            planPrice: 0,
            planInstallPrice: 0,
            planLatitude: 0,
            planLongitude: 0,
            planNote: "",
            planHidden: false,
            planServiceTypeName: "",
            learnMore: "",
            codeProvider: "",
          },
        };

        if (isMultipleAddress) {
          const multipleAddresses: IMultipleAddresses =
            iPlanFilterParams.multipleAddresses;
          let arrayMultipleParams: IPlanFilterParamsInternet[] =
            multipleAddresses === undefined ||
            multipleAddresses.multipleInternetParams === null
              ? []
              : multipleAddresses.multipleInternetParams;

          const existsPlanFilterInMultiple: boolean = arrayMultipleParams.find(
            (planFilter) => planFilter.address === address
          )
            ? true
            : false;

          if (!existsPlanFilterInMultiple) {
            const maxAddress =
              arrayMultipleParams &&
              arrayMultipleParams.length > 0 &&
              arrayMultipleParams.reduce(function (prev, current) {
                return prev.idMultiple > current.idMultiple ? prev : current;
              });

            const idMultiple = maxAddress ? maxAddress.idMultiple + 1 : 1;
            newAddress.idMultiple = idMultiple;

            arrayMultipleParams.push(newAddress);
            setAddressValue("");
            setAddressDetail("");

            setIPlanFilterParams({
              ...iPlanFilterParams,
              internetParams: newAddress,
              multipleAddresses: {
                ...iPlanFilterParams.multipleAddresses,
                multipleInternetParams: arrayMultipleParams,
              },
            });

            if (onClickSelectAddAddressParent) {
              onClickSelectAddAddressParent(newAddress);
            }

            if (addressValue !== "") {
              const visSearch: IAuditVisitorSearch = {
                id: -1,
                visitorId: iAuditVisitor.id,
                type: EnumSearchType.MULTIPLE,
                planTypeCode: iFilters.planTypeCode,
                address: addressValue,
                stateAbbreviation: smartyResponse.state,
                city: smartyResponse.city,
                zipCode: smartyResponse.zipcode,
                seats: 0,
                compares: [],
              };

              setIAuditVisitor({
                ...iAuditVisitor,
                search: visSearch,
              });
            }
          }
        } else {
          setTimeout(function () {
            setIPlanFilterParams({
              ...iPlanFilterParams,
              internetParams: newAddress,
            });
          }, 100);
        }
      } else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
        let newAddress: IPlanFilterParamsBundle = {
          streetLine: smartyResponse.street_line,
          city: smartyResponse.city,
          zipCode: smartyResponse.zipcode,
          address: address,
          addressDetail: addressDetail,
          state: smartyResponse.state,
          secondary: smartyResponse.secondary,
          latitude: 0,
          longitude: 0,
          country: "US",
          smartyAddress: null,
        };

        setTimeout(function () {
          setIPlanFilterParams({
            ...iPlanFilterParams,
            bundleParams: newAddress,
          });
        }, 100);
      }
    }
  }, [smartyResponse]);

  useEffect(() => {
    if (window.location.pathname.includes("search")) {
      if (
        iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
        iPlanFilterParams.smartyAddressInternet
      ) {
        setSmartyResponse(iPlanFilterParams.smartyAddressInternet);
      }

      if (
        iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES &&
        iPlanFilterParams.smartyAddressBundle
      ) {
        setSmartyResponse(iPlanFilterParams.smartyAddressBundle);
      }
    }
  }, [
    iPlanFilterParams.smartyAddressInternet,
    iPlanFilterParams.smartyAddressBundle,
  ]);

  useEffect(() => {
    if (
      iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
      !iFilters.showMoreLocations &&
      iPlanFilterParams.multipleAddresses.multipleInternetParams.length > 0
    ) {
      const smartyAddrees =
        iPlanFilterParams.multipleAddresses.multipleInternetParams[0]
          .smartyAddress;
      if (smartyAddrees && smartyAddrees.street_line !== "") {
        onClick(smartyAddrees);
      }
    }
  }, [iFilters.showMoreLocations]);

  const onChangeAddress = (e: any) => {
    setSuggestions([]);
    setSelectedAddress("");
    setSearchAddresses(false);

    setAddressValue(e.currentTarget.value);
    setAddressDetail(e.currentTarget.value);
    if (onChangeAddressParent) {
      onChangeAddressParent(e);
    }
  };

  const onClick = (smartyResponseDTO: ISmartyResponseDTO) => {
    if (smartyResponseDTO.entries > 1) {
      openEntriesSugestions(smartyResponseDTO);
      return;
    }

    setActiveAddress(0);
    setShowAddresses(false);
    setSearchAddresses(false);
    setAddressValue(
      `${smartyResponseDTO.street_line} ${smartyResponseDTO.city}`
    );
    setAddressDetail(getAddressDetail(smartyResponseDTO));

    if (isBillingAddress && onClickSelectAddressParent) {
      onClickSelectAddressParent(smartyResponseDTO);
    } else if (isMultipleAddress) {
      setSmartyResponse(smartyResponseDTO);
    } else if (onClickSelectAddressParent) {
      onClickSelectAddressParent(smartyResponseDTO);
    }
  };

  const onKeyDown = (e: any) => {
    // User pressed the enter key
    if (showAddresses) {
      if (e.keyCode === 13) {
        const smartyResponseDTO = suggestions[activeAddress];

        if (smartyResponseDTO && smartyResponseDTO.entries > 1) {
          openEntriesSugestions(smartyResponseDTO);
          return;
        }

        setActiveAddress(0);
        setShowAddresses(false);
        setSearchAddresses(false);

        if (smartyResponseDTO) {
          setAddressValue(
            `${smartyResponseDTO.street_line} ${smartyResponseDTO.city}`
          );
          setAddressDetail(getAddressDetail(smartyResponseDTO));
          setSmartyResponse(smartyResponseDTO);
        }
      }
      // User pressed the up arrow
      else if (e.keyCode === 38) {
        if (activeAddress === 0) {
          return;
        }

        setActiveAddress(activeAddress - 1);
      }
      // User pressed the down arrow
      else if (e.keyCode === 40) {
        if (activeAddress - 1 === suggestions.length) {
          return;
        }

        setActiveAddress(activeAddress + 1);
      }
    }
  };

  const openEntriesSugestions = (smartyResponseDTO: ISmartyResponseDTO) => {
    setSuggestions([]);
    setActiveAddress(0);

    setSearchAddresses(true);
    setAddressDetail(`${smartyResponseDTO.street_line}`);
    setAddressValue(`${smartyResponseDTO.street_line}`);
    setSelectedAddress(
      `${smartyResponseDTO.street_line} ${smartyResponseDTO.secondary} (${smartyResponseDTO.entries}) ${smartyResponseDTO.city} ${smartyResponseDTO.state}, ${smartyResponseDTO.zipcode}`
    );
  };

  const getAddressDetail = (smartyResponseDTO: ISmartyResponseDTO) => {
    const entries =
      smartyResponseDTO.entries > 1
        ? ` (${smartyResponseDTO.entries} more entries)`
        : "";
    return `${smartyResponseDTO.street_line}, ${
      smartyResponseDTO.secondary !== ""
        ? `${smartyResponseDTO.secondary}${entries}, `
        : ""
    }${smartyResponseDTO.city}, ${smartyResponseDTO.state} ${
      smartyResponseDTO.zipcode
    }`;
  };

  return (
    <>
      <input type="hidden" value={addressValue} id="hiddenAddress" />
      <input
        className={cssInputAddress}
        type="text"
        onChange={onChangeAddress}
        onKeyDown={onKeyDown}
        value={addressDetail}
        placeholder={placeHolder}
        //width="600px"
        autoComplete="off"
        aria-autocomplete="list"
        //aria-expanded="false"
        onKeyUp={(e) => {
          function timer() {
            setSearchAddresses(true);
          }

          setTimeout(timer, 300);
        }}
      />
      <div className="autocomplete-dropdown-container">
        <div className="searchShadow" hidden={!showAddresses}>
          {showAddresses &&
            suggestions.map((suggestion: ISmartyResponseDTO, index) => {
              let className = "suggestion-item";

              // Flag the active suggestion with a class
              if (index === activeAddress && showAddresses) {
                className = "suggestion-item--active";
              }

              return (
                <div
                  className={className}
                  key={suggestion.zipcode}
                  onClick={() => onClick(suggestion)}
                >
                  {getAddressDetail(suggestion)}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default AddressText;
