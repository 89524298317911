import React from 'react';
import { useGlobalState } from '../state';

interface ILoadingFloatingProps {

}

const LoadingFloating: React.FC<ILoadingFloatingProps> = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');

    return (
        <div className="floating-buttons">
            <img src={`${iPlanFilterParams.imagesUrl}/SpinnerLoading.gif`} />
            <div>Loading ...</div>
        </div>
    );
};

export default LoadingFloating;