import React, { useEffect } from "react";

import {
  IResponseInternetPlansDTO,
  EnumPropertyId,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import useAuditSaveCompare from "../../../hooks/api/useAuditSaveCompare";

interface IInternetPlanLargeExpandedProps {
  plan: IResponseInternetPlansDTO;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const InternetPlanLargeExpanded: React.FC<IInternetPlanLargeExpandedProps> = ({
  plan,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals, setIGlobals] = useGlobalState("globals");

  const {} = useAuditSaveCompare();

  useEffect(() => {}, []);

  const onClickMoreDetails = (pPlan: IResponseInternetPlansDTO) => {
    setIGlobals({
      ...iGlobals,
      planLearMore: pPlan,
    });

    setTimeout(function () {
      window.open(iPlanFilterParams.baseUrl + "learnmore", "_blank");
    }, 100);
  };

  const onClickBwCalculator = () => {
    window.open(iPlanFilterParams.baseUrl + "bw-calculator", "_self");
  };

  return (
    <>
      <div className="row">
        {(() => {
          if (plan.planDownloadSpeed >= 250 && plan.planDownloadSpeed < 500) {
            return (
              <div className="col-md-12 recommended-use-for">
                <h1>Average Use Recommended Use For:</h1>
                <ul>
                  <li>25 to 50 users or devices</li>
                  <li>Advanced Internet Needs</li>
                  <li>Regular On-Line Backups and File Sharing</li>
                  <li>Video and Web Conferences</li>
                </ul>
              </div>
            );
          } else if (
            plan.planDownloadSpeed >= 500 &&
            plan.planDownloadSpeed < 2000
          ) {
            return (
              <div className="col-md-12 recommended-use-for">
                <h1>High Speed Recommended Use For:</h1>
                <ul>
                  <li>50 to 200+ users or devices</li>
                  <li>eCommerce and Hosted Servers</li>
                  <li>Heavy Internet Usage</li>
                  <li>Cloud Applications Connectivity</li>
                  <li>VoIP and UCaaS Applications</li>
                </ul>
              </div>
            );
          } else if (
            plan.planDownloadSpeed >= 2000 &&
            plan.planDownloadSpeed < 5000
          ) {
            return (
              <div className="col-md-12 recommended-use-for">
                <h1>Ultra-Fast Recommended Use For:</h1>
                <ul>
                  <li>100 to 200 users or devices</li>
                  <li>Multiple Server Hosting</li>
                  <li>VoIP / UCaaS Applications</li>
                  <li>Cloud-Based Computing</li>
                </ul>
              </div>
            );
          } else if (plan.planDownloadSpeed >= 5000) {
            return (
              <div className="col-md-12 recommended-use-for">
                <h1>Supersonic Recommended Use For:</h1>
                <ul>
                  <li>200+ users or devices</li>
                  <li>Almost Anything</li>
                  <li>
                    Supersonic Operating Speed with Near-Zero Interruptions
                  </li>
                </ul>
              </div>
            );
          } else {
            return (
              <div className="col-md-12 recommended-use-for">
                <h1>Recommended Use For: </h1>
                <ul>
                  <li>Up to 25 users or devices</li>
                  <li>Heavy Web Browsing</li>
                  <li>Point-of-Sale Systems</li>
                  <li>On-line Backups and File Sharing</li>
                </ul>
              </div>
            );
          }
        })()}
        {/* <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th scope="col">RECOMMENDED FOR</th>
              <th scope="col">Recommended Use For:</th>
            </tr>
          </thead>
          <tbody className="card-more-table">
            <tr className="">
              <td>
                <div className="more-card-view-title">Recommended Use For:</div>
              </td>
            </tr>
            <tr className="">
              <td>
                <div className="more-card-view-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
                    )?.value
                  }
                </div>
              </td>
            </tr>
            <tr className="align-middle">
              <td>
                <div className="card-recommended-scroll">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.RECOMMENDED_FOR
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div className="card-recommended-scroll"></div>
                <hr className="card-hr" />
                <div
                  className="card-view-call link-url metrics-SearchSeeMore"
                  onClick={onClickSeeMoreWhyCarrier}
                  data-name={plan.planName}
                  data-Price={plan.planPrice.toFixed(2)}
                  data-speed={plan.planDownloadSpeed}
                  data-linephone="N/A"
                  data-term={plan.planTerm}
                >
                  {seeMoreWhyCarrier ? "See less" : "See more"} <br />
                  <i
                    className={
                      seeMoreWhyCarrier
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                  />
                </div>
              </td>
            </tr>
          </tbody> */}
        {/* <thead>
            <tr className="card-tr-title">
              <th scope="col">CONNECTED DEVICES</th>
              <th scope="col"></th>
            </tr>
          </thead> */}
        {/* <tbody>
            <tr>
              <td>
                <div className="device-connected"></div>
                <div className="device-connected-btn">
                  <button
                    type="button"
                    className="btn card-button-connect"
                    onClick={onClickBwCalculator}
                  >
                    LEARN MORE
                  </button>
                </div>
              </td>
              <td>
                <div className="bi-learn-more">
                  <span
                    className="card-view-call link-url"
                    onClick={() => onClickMoreDetails(plan)}
                  >
                    See even more details
                  </span>
                </div>
              </td>
            </tr>
          </tbody> */}
        {/* </table> */}
        {/* <table className="table card-table">
          <thead>
            <tr className="card-tr-title">
              <th>
                <div className="card-icon-install mx-auto d-block" />
              </th>
              <th>
                <div className="card-icon-dedicated mx-auto d-block" />
              </th>
              <th>
                <div className="card-icon-support mx-auto d-block" />
              </th>
              <th>
                <div className="card-icon-technology mx-auto d-block" />
              </th>
              <th>
                <div className="card-icon-staticip mx-auto d-block" />
              </th>
              <th>
                <div className="card-icon-symmetrical mx-auto d-block" />
              </th>
              <th>
                <div className="card-icon-price mx-auto d-block" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="card-more-table">
            <tr className="align-middle">
              <td>
                <div className="card-view-title">INSTALLATION</div>
              </td>
              <td>
                <div className="card-view-title">
                  DEDICATED
                  <br />/ SHARED INTERNET
                </div>
              </td>
              <td>
                <div className="card-view-title">TECH SUPPORT</div>
              </td>
              <td>
                <div className="card-view-title">TECHNOLOGY</div>
              </td>
              <td>
                <div className="card-view-title">STATIC IP</div>
              </td>
              <td>
                <div className="card-view-title">
                  SYMMETRICAL
                  <br />/ ASYMMETRICAL
                  <br />
                  CONNECTION
                </div>
              </td>
              <td>
                <div className="card-view-title">PRICE GUARANTEE</div>
              </td>
              { <td>
                <span
                  className="card-view-call link-url"
                  onClick={() => onClickMoreDetails(plan)}
                >
                  See even more details
                  <i
                    className="fa fa-chevron-right align-middle"
                    aria-hidden="true"
                  ></i>
                </span>
              </td> }
            </tr>
            <tr className="align-middle">
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.INSTALLATION
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode ===
                          EnumPropertyId.DEDICATED_SHARED_INTERNET
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECH_SUPPORT
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECHNOLOGY
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.STATIC_IP
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.SYM_ASYM_CONNECTION
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.PRICE_GUARANTEE
                    )?.value
                  }
                </div>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table> */}
      </div>
      <div>
        <div className="col-md-12">
          <div className="row card-text-center">
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-3">
              {/* <div className="card-center-call">
                                <span className="card-view-call">Call to Get Advice <b>1.844.947.7737</b></span>
                            </div> */}
            </div>
            <div className="col-md-3">
              <span
                className="card-view-call link-url"
                onClick={onClickShowMoreDetails}
              >
                View less details
                <i
                  className="fa fa-chevron-up view-more-icon"
                  aria-hidden="true"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InternetPlanLargeExpanded;
