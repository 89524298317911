import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IRequestAccountUserRegisterDTO, IResponseAccountDTO } from "../../contracts/types";

export default function useAccountResgisterUser(userName:string, email: string, password: string) {

	const api = useServiceApi();

	const doRequest = async (): Promise<IResponseAccountDTO> => {
		const requestDTO: IRequestAccountUserRegisterDTO = {
			"userName": userName,
			"email": email,
			"password": password
		};

		const { data } = await api.post<IResponseAccountDTO>(`${ApiEndPoints.GetAccountUserRegister}`, requestDTO);
		
		return data;
	};

	return useQuery<IResponseAccountDTO>(
		['userRegister', userName, email, password],
		() => doRequest(), {
			staleTime: Infinity,
			retry: 0,
			enabled: userName!=='' && email !== '' && password !== ''
	});
}
