import React, { useState, useEffect } from "react";
import {
  EnumConnectionType,
  EnumSortBy,
  IConnectionType,
  IMultipleAddresses,
  IPlanFilterParamsInternet,
  IPlansByAddress,
  IResponseInternetPlansDTO,
  ISortBy,
  IWrSettings,
} from "../../contracts/types";
import { useGlobalState } from "../../state";
import Select from "react-select";

interface IBulkEditSectionProps {
  wrSettings: IWrSettings;
}

const BulkEditSection: React.FC<IBulkEditSectionProps> = ({ wrSettings }) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");

  const [optionsCarrier, setOptionsCarrier] = useState<any>();
  const [optionsSpeed, setOptionsSpeed] = useState<any>();

  const [carrier, setCarrier] = useState(-1);
  const [speed, setSpeed] = useState(-1);
  const [connectionType, setConnectionType] = useState(
    EnumConnectionType.NOTHING
  );
  const [basedOn, setBasedOn] = useState(EnumSortBy.NONE);
  const [disabledButtonPending, setDisabledButtonPending] = useState(true);
  const [disabledButtonToSelected, setDisabledButtonToSelected] =
    useState(true);
  const [openBulkEdit, setOpenBulkEdit] = useState(
    iPlanFilterParams.multipleAddresses.openedBulkEdit
  );

  const multipleAddresses: IMultipleAddresses =
    iPlanFilterParams.multipleAddresses;
  let arrayMultipleParams: IPlanFilterParamsInternet[] =
    multipleAddresses === undefined ||
    multipleAddresses.multipleInternetParams === null
      ? []
      : multipleAddresses.multipleInternetParams;
  let arrayPlansByAddress: IPlansByAddress[] = [];

  arrayMultipleParams.forEach((planFilterParam) => {
    let arrayPlans: IResponseInternetPlansDTO[] = [];

    if (
      planFilterParam &&
      planFilterParam.plansByCodeProvider &&
      planFilterParam.plansByCodeProvider.length > 0
    ) {
      planFilterParam.plansByCodeProvider.forEach((planByCodeProv) => {
        if (planByCodeProv.plans && planByCodeProv.plans.length > 0) {
          arrayPlans = arrayPlans.concat(planByCodeProv.plans);
        }
      });
    }

    let plansByAddress: IPlansByAddress = {
      address: planFilterParam.address,
      plans: arrayPlans,
    };

    arrayPlansByAddress.push(plansByAddress);
  });

  useEffect(() => {
    orderBasedOn();
  }, [basedOn, iPlanFilterParams.multipleAddresses.multipleInternetParams]);

  useEffect(() => {
    const lengthSelected =
      iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(
        (m) => m.selectAddress === true
      ).length;
    if (lengthSelected > 0) {
      setDisabledButtonToSelected(false);
    } else {
      setDisabledButtonToSelected(true);
    }
  }, [
    iPlanFilterParams.someSelectedAddress,
    iPlanFilterParams.selectedAllAddress,
  ]);

  useEffect(() => {
    verifyPending();
  }, [
    iPlanFilterParams.multipleAddresses.multipleInternetParams.length,
    iPlanFilterParams.multipleAddresses.multipleInternetParams,
  ]);

  useEffect(() => {
    let carriers: any = [];
    let speeds: any = [];

    iPlanFilterParams.multipleAddresses.multipleInternetParams.forEach(
      (planFilterParam) => {
        if (
          planFilterParam &&
          planFilterParam.plansByCodeProvider &&
          planFilterParam.plansByCodeProvider.length > 0
        ) {
          planFilterParam.plansByCodeProvider.forEach((plansByCodeProv) => {
            if (plansByCodeProv.plans && plansByCodeProv.plans.length > 0) {
              plansByCodeProv.plans.forEach((plan) => {
                let i = carriers.findIndex(
                  (x: any) => x.value === plan.providerId
                );
                if (i <= -1) {
                  carriers.push({
                    value: plan.providerId,
                    label: plan.providerName,
                  });
                }

                i = speeds.findIndex(
                  (x: any) => x.value === plan.planDownloadSpeed
                );
                if (i <= -1) {
                  const downloadSpeed =
                    plan.planDownloadSpeed < 1000
                      ? plan.planDownloadSpeed
                      : plan.planDownloadSpeed / 1000;
                  const unitSpeed =
                    plan.planDownloadSpeed < 1000 ? "Mbps" : "Gbps";
                  speeds.push({
                    value: plan.planDownloadSpeed,
                    label: downloadSpeed + " " + unitSpeed,
                  });
                }
              });
            }
          });
        }
      }
    );

    let optsCarriers = carriers?.map((item: any) => ({
      value: item.value,
      label: item.label,
    }));
    optsCarriers = optsCarriers.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    optsCarriers.unshift({ value: -1, label: "Carrier" });

    let optsSpeed = speeds?.map((item: any) => ({
      value: item.value,
      label: item.label,
    }));
    optsSpeed = optsSpeed.sort((a: any, b: any) => {
      return a.value - b.value;
    });
    optsSpeed.unshift({ value: -1, label: "Speed" });

    setOptionsCarrier(optsCarriers);
    setOptionsSpeed(optsSpeed);
  }, [iPlanFilterParams.multipleAddresses]);

  const onChangeCarrier = (optSelected: any) => {
    setCarrier(optSelected.value);
  };

  const onChangeSpeed = (optSelected: any) => {
    setSpeed(optSelected.value);
  };

  const onChangeConnectionType = (optSelected: any) => {
    setConnectionType(optSelected.value);
  };

  const onChangeBasedOn = (optSelected: any) => {
    setBasedOn(optSelected.value);
    orderBasedOn();
  };

  const orderBasedOn = () => {
    arrayPlansByAddress.forEach((plansByAddress) => {
      if (plansByAddress && plansByAddress.plans.length > 0) {
        const plansOrder = [...plansByAddress.plans].sort((a, b) => {
          if (
            basedOn === EnumSortBy.LOW_TO_HIGH_PRICE &&
            a.planPrice > b.planPrice
          )
            return 1;
          if (
            basedOn === EnumSortBy.HIGH_TO_LOW_PRICE &&
            a.planPrice < b.planPrice
          )
            return 1;
          return -1;
        });

        plansByAddress.plans = plansOrder;
      }
    });
  };

  const onClickApplyToSelected = () => {
    let arrayMultipleParamsGlobal: IPlanFilterParamsInternet[] =
      multipleAddresses === undefined ||
      multipleAddresses.multipleInternetParams === null
        ? []
        : multipleAddresses.multipleInternetParams;
    arrayMultipleParamsGlobal.forEach((planFilterParam) => {
      if (planFilterParam.selectAddress) {
        const planFound = findPlan(planFilterParam);
        if (planFound) {
          planFilterParam.plan = planFound;
        }
      }
    });

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams: arrayMultipleParamsGlobal,
      },
    });

    verifyPending();
  };

  const onClickApplyToAll = () => {
    arrayMultipleParams =
      iPlanFilterParams.multipleAddresses === undefined ||
      iPlanFilterParams.multipleAddresses.multipleInternetParams === null
        ? []
        : iPlanFilterParams.multipleAddresses.multipleInternetParams;
    orderBasedOn();

    arrayMultipleParams.forEach((planFilterParam) => {
      const planFound = findPlan(planFilterParam);
      if (planFound) {
        planFilterParam.plan = planFound;
      }
    });

    updateMultipleParams();
  };

  const onClickApplyForPending = () => {
    arrayMultipleParams =
      iPlanFilterParams.multipleAddresses === undefined ||
      iPlanFilterParams.multipleAddresses.multipleInternetParams === null
        ? []
        : iPlanFilterParams.multipleAddresses.multipleInternetParams;
    orderBasedOn();

    arrayMultipleParams.forEach((planFilterParam) => {
      if (!planFilterParam.plan) {
        const planFound = findPlan(planFilterParam);
        if (planFound) {
          planFilterParam.plan = planFound;
        }
      }
    });

    updateMultipleParams();
  };

  const findPlan = (planFilterParam: IPlanFilterParamsInternet) => {
    let planSelect: IResponseInternetPlansDTO | undefined;
    const multiplePlans = arrayPlansByAddress.find(
      (p) => p.address === planFilterParam.address
    );

    multiplePlans?.plans.forEach((plan) => {
      if (
        !planSelect &&
        ((carrier === -1 && speed === -1) ||
          (carrier === plan.providerId && speed === plan.planDownloadSpeed) ||
          (carrier === plan.providerId && speed === -1) ||
          (carrier === -1 && speed === plan.planDownloadSpeed))
      ) {
        if (connectionType === EnumConnectionType.NOTHING) {
          planSelect = plan;
        } else if (
          connectionType === EnumConnectionType.SINCHRONOUS &&
          plan.planDownloadSpeed === plan.planUploadSpeed
        ) {
          planSelect = plan;
        } else if (
          connectionType === EnumConnectionType.NOTSINCHRONOUS &&
          plan.planDownloadSpeed !== plan.planUploadSpeed
        ) {
          planSelect = plan;
        }

        return;
      }
    });

    return planSelect;
  };

  const updateMultipleParams = () => {
    let arrayMultipleParamsGlobal: IPlanFilterParamsInternet[] =
      multipleAddresses === undefined ||
      multipleAddresses.multipleInternetParams === null
        ? []
        : multipleAddresses.multipleInternetParams;
    arrayMultipleParamsGlobal.forEach((planFilterParam) => {
      const planFilter = arrayMultipleParams.find(
        (p) => p.idMultiple === planFilterParam.idMultiple
      );
      if (planFilter) {
        planFilterParam = planFilter;
      }
    });

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams: arrayMultipleParamsGlobal,
      },
    });

    verifyPending();
  };

  const verifyPending = () => {
    let disabledPending = true;
    arrayMultipleParams.forEach((planFilterParam) => {
      if (!planFilterParam.plan) {
        disabledPending = false;
      }
    });

    setDisabledButtonPending(disabledPending);
  };

  const onClickBulkEdit = () => {
    if (openBulkEdit) {
      setOpenBulkEdit(false);
    } else {
      setOpenBulkEdit(true);
    }

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        openedBulkEdit: openBulkEdit,
      },
    });
  };

  const optsBasedOn: ISortBy[] = [
    { value: EnumSortBy.LOW_TO_HIGH_PRICE, label: "Price: Lowest" },
    { value: EnumSortBy.HIGH_TO_LOW_PRICE, label: "Price: Highest" },
  ];

  const optsType: IConnectionType[] = [
    { value: EnumConnectionType.NOTHING, label: "Type" },
    { value: EnumConnectionType.SINCHRONOUS, label: "Synchronous" },
    { value: EnumConnectionType.NOTSINCHRONOUS, label: "Not Nynchronous" },
  ];

  return (
    <div className="container multiAdress">
      <div
        className="bulkEditOpen"
        style={{ display: openBulkEdit ? "" : "none" }}
      >
        <div className="row">
          <h2>
            {
              <button
                className="bulkEditButtonCollapse"
                onClick={onClickBulkEdit}
              >
                -
              </button>
            }
            Bulk edit
          </h2>
          <p>
            Please enter the criteria to mass update your locations with
            business internet plans.
          </p>
        </div>
        <div className="row">
          <div className="col-md-4 text-center">
            <Select
              options={optionsCarrier}
              onChange={(value: number) => onChangeCarrier(value)}
              value={optionsCarrier?.value}
              placeholder={"Carrier"}
            />
          </div>
          <div className="col-md-4 text-center">
            <Select
              options={optionsSpeed}
              onChange={onChangeSpeed}
              value={optionsSpeed?.value}
              placeholder={"Speed"}
            />
          </div>
          <div className="col-md-4 text-center">
            <Select
              options={optsType}
              onChange={onChangeConnectionType}
              value={optsType.filter(
                (option) => option.value === connectionType
              )}
              placeholder={"Type"}
            />
          </div>
        </div>
        <div className="row squareBEbutton">
          <b>Select the best option for me:</b>
          <div className="col-md-4">
            <label>Based on:</label>
            <Select
              options={optsBasedOn}
              onChange={onChangeBasedOn}
              value={optsBasedOn.filter((option) => option.value === basedOn)}
            />
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md text-center">
                <button
                  className={disabledButtonPending ? "disable" : ""}
                  disabled={disabledButtonPending}
                  onClick={onClickApplyForPending}
                >
                  Apply for pending
                </button>
              </div>
              <div className="col-md text-center">
                <button
                  className={disabledButtonToSelected ? "disable" : ""}
                  disabled={disabledButtonToSelected}
                  onClick={onClickApplyToSelected}
                >
                  Apply to selected
                </button>
              </div>
              <div className="col-md text-center">
                <button onClick={onClickApplyToAll}>Apply to all</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bulkEditClose"
        style={{ display: openBulkEdit ? "none" : "" }}
      >
        <div className="row">
          <h2>
            {
              <button
                className="bulkEditButtonCollapse"
                onClick={onClickBulkEdit}
              >
                +
              </button>
            }
            Bulk edit
          </h2>
        </div>
      </div>
    </div>
  );
};

export default BulkEditSection;
