import { useQuery } from 'react-query';
import { useServiceApi, ApiEndPoints } from '../../api';
import { IRequestCalculatorSendDTO, IResponseCalculatorSendDTO } from '../../contracts/types';
import { useGlobalState } from '../../state';

export default function useCalculatorSend(requestSend?: IRequestCalculatorSendDTO) {
    const [iGlobals, setIGlobals] = useGlobalState('globals');
    const api = useServiceApi();

    const doRequest = async (): Promise<IResponseCalculatorSendDTO> => {
        const { data } = await api.post<IResponseCalculatorSendDTO>(`${ApiEndPoints.GetCalculatorSend}`, requestSend);

        let arrayCarriers = iGlobals.carriers;
        data.lstCarriers.forEach((carrierDTO) => {
            const findCarrier = arrayCarriers.find((c) => c.id === carrierDTO.id);
            if (!findCarrier) {
                arrayCarriers.push(carrierDTO);
            }
        });

        let arrayProPro = iGlobals.productProperties;
        data.lstProductProperties.forEach((productPropertyDTO) => {
            const findProPro = arrayProPro.find((pp) => pp.id === productPropertyDTO.id);
            if (!findProPro) {
                arrayProPro.push(productPropertyDTO);
            }
        });

        setIGlobals({
            ...iGlobals,
            'carriers': arrayCarriers,
            'productProperties': arrayProPro,
        });

        return data;
    };

    return useQuery<IResponseCalculatorSendDTO>(['calculatorSend', requestSend?.requestInternetPlans.streetLine, requestSend?.requestInternetPlans.zipCode], () => doRequest(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: requestSend !== undefined && requestSend.requestInternetPlans.streetLine !== '',
    });
}
