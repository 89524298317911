import React from 'react';

interface IVoicePlanLargeMobileCollapsedProps {

}

const VoicePlanLargeMobileCollapsed: React.FC<IVoicePlanLargeMobileCollapsedProps> = () => {

    return (
        <>

        </>
    );
};

export default VoicePlanLargeMobileCollapsed;
