import React, { useState } from 'react';
import { EnumPlanType } from '../../contracts/types';
import { useGlobalState } from '../../state';
import ListPlansCompare from './listPlansCompare.comp';

const ShoppingCart: React.FC = () => {
    const [iFilters] = useGlobalState('filters');

    const [showComparePlans, setShowComparePlans] = useState(false);

    const onClickComparePlans = () => {
        setShowComparePlans(!showComparePlans);
    }

    return (
        <div className="divCustomComparePlan">
            <span className="counter-compare-nav float-start">
                {
                    iFilters.tabShoppingCart === EnumPlanType.BUSINESS_INTERNET &&
                    iFilters.plansCompareInternet.length
                }
                {
                    iFilters.tabShoppingCart === EnumPlanType.BUSINESS_VOICE_PHONE &&
                    iFilters.plansCompareVoice.length
                }
                {
                    iFilters.tabShoppingCart === EnumPlanType.BUSINESS_BUNDLES &&
                    iFilters.plansCompareBundle.length
                }
            </span>
            <a className="Comparetext metrics-link" onClick={onClickComparePlans}>Compare Plans</a>
            {
                showComparePlans &&
                <ListPlansCompare />

            }
        </div>
    );
};

export default ShoppingCart;
