import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IRequestAccountForgotPasswordDTO, IResponseAccountDTO } from "../../contracts/types";

export default function useAccountForgotPassword(email: string) {

	const api = useServiceApi();

	const doRequest = async (): Promise<IResponseAccountDTO> => {
		const requestDTO: IRequestAccountForgotPasswordDTO = {
			"email": email
		};

		const { data } = await api.post<IResponseAccountDTO>(`${ApiEndPoints.GetAccountForgotPassword}`, requestDTO);
		
		return data;
	};

	return useQuery<IResponseAccountDTO>(
		['forgotPassword', email],
		() => doRequest(), {
			staleTime: Infinity,
			retry: 0,
			enabled: email !== ''
	});
}
