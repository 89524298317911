import React from 'react';
import { useGlobalState } from '../../../state';
import { EnumPlanType, IResponseBundlePlansDTO, IResponseInternetPlansDTO } from './../../../contracts/types';

interface ICompareSpeedProps {
    plansDataCompare: IResponseInternetPlansDTO[] | IResponseBundlePlansDTO[];
}

const CompareSpeed: React.FC<ICompareSpeedProps> = ({ plansDataCompare }) => {
    const [iFilters] = useGlobalState('filters');

    let label = 'MAXIMUM SPEED';
    if (iFilters.tabShoppingCart === EnumPlanType.BUSINESS_BUNDLES) {
        label = 'INTERNET SPEED DESCRIPTION';
    }

    return (
        <tr>
            <th className="headcol-compare label-compareBi">{label}</th>
            {
                plansDataCompare.map((plan: any) => {
                    let download = plan.planDownloadSpeed;
                    let upload;

                    if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET) {
                        upload = plan.planUploadSpeed;
                    }

                    return <td className='compare-plan-space-td'>
                        <div className="compare-spaceSpeed">
                            <div className="card-icon-bolt float-start" />
                            <div>
                                <div className="card-icon-down float-start" />
                                <span className="card-max-term">{download}</span>
                                <div className="card-icon-mbps float-end" />
                            </div>
                            {
                                iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
                                <>
                                    <hr className="card-hr" />
                                    <div>
                                        <div className="card-icon-up float-start" />
                                        <span className="card-max-term">{upload}</span>
                                        <div className="card-icon-mbps float-end" />
                                    </div>
                                </>
                            }
                        </div>
                    </td>
                })
            }
        </tr>
    );
};

export default CompareSpeed;
