import React, { useState } from "react";
import { EnumPlanType } from "../../contracts/types";
import { useGlobalState } from "../../state";
import InternetPlanFilters from "./internet/internetPlanFilters.comp";
import ListPlansCompare from "../shoppingCart/listPlansCompare.comp";
import VoicePlanFilters from "./voice/voicePlanFilters.comp";
import BundlePlanFilters from "./bundle/bundlePlanFilters.comp";

interface IPlansProps {}

const PlansFilters: React.FC<IPlansProps> = () => {
  const [iFilters] = useGlobalState("filters");

  const [showComparePlans, setShowComparePlans] = useState(false);

  const onClickFilters = (e: any) => {
    const divMobileFilters = document.getElementById("mobile-filterWS");

    if (divMobileFilters) {
      if (divMobileFilters.classList.value.includes("show")) {
        divMobileFilters.classList.remove("show");
      } else {
        divMobileFilters.classList.add("show");
      }
    }
  };

  const onClickComparePlans = () => {
    setShowComparePlans(!showComparePlans);
  };

  return (
    <>
      <div className="filterWS">
        <div className="container">
          <div className="row">
            <div className="col-6 text-center">
              <button
                type="button"
                className="btn btn-primary"
                id="btnFilters"
                onClick={onClickFilters}
              >
                <div className="icon-filters align-middle"></div>
                Filters
              </button>
            </div>
            {/* <div className="col-6  text-center">
                            <button type="button" className="btn btn-primary">
                                <div className="compare-buttonPlans" onClick={onClickComparePlans}>
                                    <span className="counter-compare float-start">
                                        {
                                            iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && iFilters.plansCompareInternet.length
                                        }
                                        {
                                            iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE && iFilters.plansCompareVoice.length
                                        }
                                        {
                                            iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && iFilters.plansCompareBundle.length
                                        }
                                    </span>
                                    <span className="mobile-compareplan metrics-link">Compare Plans</span>
                                </div>
                            </button>
                        </div> */}
          </div>
          {showComparePlans && (
            <div className="divCustomComparePlan">
              <ListPlansCompare />
            </div>
          )}
        </div>
      </div>
      <div id="mobile-filterWS" className="collapse">
        {iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && (
          <InternetPlanFilters />
        )}
        {iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE && (
          <VoicePlanFilters />
        )}
        {iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && (
          <BundlePlanFilters />
        )}
      </div>
      <section id="sidebarWS">
        {iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET && (
          <InternetPlanFilters />
        )}
        {iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE && (
          <VoicePlanFilters />
        )}
        {iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES && (
          <BundlePlanFilters />
        )}
      </section>
    </>
  );
};

export default PlansFilters;
