import React, { useState } from "react";
import {
  EnumPropertyId,
  EnumIncludePropertyId,
  IResponseVoicePlansDTO,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import longDistance from "../../../images/long-distance.png"; // with import
import voipPhone from "../../../images/phone.png"; // with import
import technology from "../../../images/technology.svg"; // with import

interface IVoicePlanLargeCollapsedProps {
  plan: IResponseVoicePlansDTO;
  showMoreDetails: boolean;
  onClickShowMoreDetails: () => void;
  onClickOrderNow: () => void;
  isCompare: boolean;
}

const VoicePlanLargeCollapsed: React.FC<IVoicePlanLargeCollapsedProps> = ({
  plan,
  showMoreDetails,
  onClickShowMoreDetails,
  onClickOrderNow,
  isCompare,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");

  const [iGlobals] = useGlobalState("globals");

  const [seeMoreWhyCarrier, setSeeMoreWhyCarrier] = useState(false);

  const onClickSeeMoreWhyCarrier = () => {
    setSeeMoreWhyCarrier(!seeMoreWhyCarrier);
  };

  let includesValues = [];
  includesValues = iGlobals.productProperties.filter(
    (pp) =>
      pp.productId === plan.productId &&
      EnumIncludePropertyId.includes(pp.propertyCode)
  );

  return (
    <div className="row">
      <div className="col-md-3 internet-card-first voice-card-col-one">
        <button
          type="button"
          className={`btn internet-order-btn ${
            isCompare ? "metrics-SearchComparePlan" : "metrics-SearchRequestNow"
          }`}
          onClick={onClickOrderNow}
          data-name={plan.planName}
          data-Price={plan.calculatedPrice.toFixed(2)}
          data-speed="N/A"
          data-linephone="N/A"
          data-term={plan.term}
        >
          {isCompare ? "ADD TO COMPARE" : "Order"}
        </button>
      </div>
      <div className="col-md-3 internet-card-first voice-card-col-two">
        <div className="text-head">Price</div>
        <div>
          ${plan.calculatedPrice.toFixed(2)} / mo. <br />
          {iPlanFilterParams.voiceParams.filterNumber}{" "}
          {iPlanFilterParams.voiceParams.filterNumber > 1 ? "seats" : "seat"}
          <br />
          Taxes + fees extra
        </div>
      </div>
      <div className="col-md-3 internet-card-first voice-card-col-three">
        <div className="text-head">Term</div>
        <div>{plan.term} Months</div>
      </div>
      <div className="col-md-3 internet-card-first no-border voice-card-col-four">
        <div className="text-head">Includes</div>
        <div>
          <ul className="voice-card-includes-lst">
            {includesValues.map((element) => (
              <li>{element.value}</li>
            ))}
          </ul>
        </div>
      </div>

      {
        // iGlobals.productProperties.find(
        //   (pp) =>
        //     pp.productId === plan.productId &&
        //     pp.propertyCode === EnumPropertyId.CHAT
        // )?.value
      }
      {/* <div className="col-md-12 card-float-int">
        <table className="table voice-card-table">
          <thead>
            <tr className="card-tr-title">
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">WHY THIS CARRIER</th>
            </tr>
          </thead>
          <tbody>
            <tr className="align-middle">
              <td className="right-border">
                <div className="order-now"></div>
              </td>
              <td className="right-border">
                <div>
                  <span className="card-price">PRICE</span>
                  <span className="voice-bolt">
                    <br />
                  </span>
                </div>
              </td>
              <td className="right-border">
                <div>
                  <span className="card-max-term card-font-term">TERM</span>
                  <div className="voice-bolt">{plan.term} Months</div>
                </div>
              </td>
              <td className="">
                <span className="card-max-term card-font-term">INCLUDES</span>

                <ul
                  id="list-includes-phone-dt"
                  className="list-includes-phone-dt"
                >
                  <li className="">
                    <i className="fa fa-check-circle" aria-hidden="true" />{" "}
                    VOICE
                  </li>
                  {iGlobals.productProperties.filter(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.CHAT
                  ).length >= 1 && (
                    <li className="">
                      <i className="fa fa-check-circle" aria-hidden="true" />{" "}
                      , CHAT
                    </li>
                  )}
                  {iGlobals.productProperties.filter(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.VIDEO
                  ).length >= 1 && (
                    <li className="">
                      <i className="fa fa-check-circle" aria-hidden="true" />{" "}
                      , VIDEO
                    </li>
                  )}
                  {iGlobals.productProperties.filter(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.CONFERENCE
                  ).length >= 1 && (
                    <li className="">
                      <i className="fa fa-check-circle" aria-hidden="true" />{" "}
                      , CONFERENCE
                    </li>
                  )}
                </ul>
              </td>
              <td>
                <div
                  className={
                    seeMoreWhyCarrier
                      ? "card-recommended-scroll"
                      : "card-recommended"
                  }
                >
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.WHYTHISCARRIER
                    )?.value
                  }
                </div>
                <hr className="card-hr" />
                <div
                  className="card-view-call link-url metrics-SearchSeeMore"
                  onClick={onClickSeeMoreWhyCarrier}
                  data-name={plan.planName}
                  data-Price={plan.calculatedPrice.toFixed(2)}
                  data-speed="N/A"
                  data-linephone="N/A"
                  data-term={plan.term}
                >
                  {seeMoreWhyCarrier ? "See less" : "See more"} <br />
                  <i
                    className={
                      seeMoreWhyCarrier
                        ? "fa fa-chevron-up"
                        : "fa fa-chevron-down"
                    }
                    aria-hidden="true"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <div className="col-md-12">
        <table className="table voip-card-table-second">
          <tbody className="card-more-table">
            <tr>
              <td>
                <img src={technology} alt="" />
              </td>
              <td>
                <img src={longDistance} alt="" />
              </td>
              {/* <td>
                <img src={slaImg} alt="" />
              </td> */}
              <td>
                <img src={voipPhone} alt="" />
              </td>
            </tr>
            <tr>
              <td>Technology</td>
              <td>Long Distance</td>
              {/* <td>SLA</td> */}
              <td>VOIP Phone</td>
            </tr>
            <tr className="align-middle">
              <td>
                <div>
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECHNOLOGY
                    )?.valueLong
                  }
                </div>
              </td>
              <td>
                <div>
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.LONG_DISTANCE
                  )?.valueShort || "No"}
                </div>
              </td>
              {/* <td>
                <div>
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.SLA
                  )?.value || "No"}
                </div>
              </td> */}
              <td>
                <div>
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.EQUIPMENT_INCLUDED
                  )?.value || "Additional"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className="col-md-12">
        <table className="table card-table">
          <thead>
                <tr className="align-middle card-font-weight">
                  <th scope="col">Tech support</th>
                  <th scope="col">Technology</th>
                  <th scope="col">Long distance</th>
                  <th scope="col">SLA</th>
                  <th scope="col">Phone included</th>
                </tr>
              </thead>
          <tbody className="card-more-table">
            <tr className="align-middle">
              <td>
                <div className="card-view-title">
                  <div className="icon-tech-support align-middle"></div>
                  TECH SUPPORT
                </div>
              </td>
              <td>
                <div className="card-view-title">
                  <div className="icon-technology align-middle"></div>
                  TECHNOLOGY
                </div>
              </td>
              <td>
                <div className="card-view-title">
                  <div className="icon-long-distance align-middle"></div>
                  unlimited domestic long distance
                </div>
              </td>
              <td>
                <div className="card-view-title">
                  <div className="icon-sla align-middle"></div>
                  SLA
                </div>
              </td>
              <td>
                <div className="card-view-title">
                  <div className="icon-phone align-middle"></div>
                  VOIP PHONE
                </div>
              </td>
            </tr>
            <tr className="align-middle">
              <td>
                <div className="card-view-sub-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECH_SUPPORT
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div className="card-view-sub-title">
                  {
                    iGlobals.productProperties.find(
                      (pp) =>
                        pp.productId === plan.productId &&
                        pp.propertyCode === EnumPropertyId.TECHNOLOGY
                    )?.value
                  }
                </div>
              </td>
              <td>
                <div className="card-view-sub-title">
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.LONG_DISTANCE
                  )?.value || "No"}
                </div>
              </td>
              <td>
                <div className="card-view-sub-title">
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.SLA
                  )?.value || "No"}
                </div>
              </td>
              <td>
                <div className="card-view-sub-title">
                  {iGlobals.productProperties.find(
                    (pp) =>
                      pp.productId === plan.productId &&
                      pp.propertyCode === EnumPropertyId.EQUIPMENT_INCLUDED
                  )?.value || "No"}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      {!showMoreDetails && (
        <>
          <div className="col-md-12">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-3"></div>
              <div className="col-sm-3">
                {/* <div className="card-center-call">
                  <span
                    className="card-view-call metrics-SearchCall"
                    data-name={plan.planName}
                    data-Price={plan.calculatedPrice.toFixed(2)}
                    data-speed="N/A"
                    data-linephone="N/A"
                    data-term={plan.term}
                  >
                    <p>
                      Call to Get Advice <b>1.844.947.7737ss</b>
                    </p>
                  </span>
                </div> */}
              </div>
              <div className="col-sm-3 view-more-details">
                <span
                  className="card-view-call link-url metrics-SearchViewDetail"
                  onClick={onClickShowMoreDetails}
                  data-name={plan.planName}
                  data-Price={plan.calculatedPrice.toFixed(2)}
                  data-speed="N/A"
                  data-linephone="N/A"
                  data-term={plan.term}
                >
                  View more details
                  <i
                    className="fa fa-chevron-down view-more-icon"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VoicePlanLargeCollapsed;
