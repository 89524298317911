import React from 'react';
import parse from 'html-react-parser';
import { useGlobalState } from '../../../state';
import { EnumPropertyId, IResponseInternetPlansDTO } from '../../../contracts/types';

interface ILearnMorePropertyValueProps {
    propertyCode: EnumPropertyId;
    plan: IResponseInternetPlansDTO;
    isValueLong?: boolean;
}

const LearnMorePropertyValue: React.FC<ILearnMorePropertyValueProps> = ({ propertyCode, plan, isValueLong = true }) => {
    const [iGlobals] = useGlobalState('globals');

    const productProperty = iGlobals.productProperties.find(pp => pp.productId === plan.productId && pp.propertyCode === propertyCode);
    const value = productProperty ? ((isValueLong && productProperty.valueLong !== '') ? productProperty.valueLong : productProperty.value) : '';

    return (
        <>{parse(value)}</>
    );
};

export default LearnMorePropertyValue;
