import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IResponseVoiceEquipmentDTO } from "../../contracts/types";

export default function useVoiceEquipments() {

    const api = useServiceApi();

    const doRequest = async (): Promise<IResponseVoiceEquipmentDTO[]> => {
        const { data } = await api.post<IResponseVoiceEquipmentDTO[]>(`${ApiEndPoints.GetEquipmentVoice}`);

        return data;
    };

    const filterData = (data: IResponseVoiceEquipmentDTO[]) => {
		const result = [...data];
		
        return result;
    };

    return useQuery<IResponseVoiceEquipmentDTO[]>(
        ['voiceEquipment'],
        () => doRequest(),{
            select: filterData,
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: 1,
            notifyOnChangeProps: ['data', 'error']
        }
    );
}
