import React, { useEffect, useState } from "react";
import {
  EnumSortBy,
  EnumViewPlansType,
  IResponseInternetPlansDTO,
  ISortBy,
} from "../../../contracts/types";
import { sortByPlansInternet } from "../../../helpers/sortByPlansInternet";
import useInternetPlans from "../../../hooks/api/useInternetPlans";
import { useGlobalState } from "../../../state";
import InternetPlanLarge from "../internet/internetPlanLarge.comp";
import Select from "../../select.comp";

interface ICompareCardsInternetProps {}

const CompareCardsInternet: React.FC<ICompareCardsInternetProps> = () => {
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [plansData, setPlansData] = useState<IResponseInternetPlansDTO[]>([]);

  const { data } = useInternetPlans(EnumViewPlansType.LARGE, "", 0);

  const { data: dataATT_IPBB } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_IPBB",
    0
  );

  // const { data: data_AST } = useInternetPlans(
  //   EnumViewPlansType.LARGE,
  //   "AST_CB",
  //   0
  // );

  const { data: dataATT_ADI_12 } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_ADI",
    12
  );

  const { data: dataATT_ADI_24 } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_ADI",
    24
  );

  const { data: dataATT_ADI_36 } = useInternetPlans(
    EnumViewPlansType.LARGE,
    "AT&T_ADI",
    36
  );

  useEffect(() => {
    if (
      data &&
      dataATT_IPBB &&
      dataATT_ADI_12 &&
      dataATT_ADI_24 &&
      dataATT_ADI_36
    ) {
      let plans: IResponseInternetPlansDTO[] = data.lstResponseInternetPlansDTO;
      // plans = plans.concat(data_AST.lstResponseInternetPlansDTO);
      plans = plans.concat(dataATT_IPBB.lstResponseInternetPlansDTO);
      plans = plans.concat(dataATT_ADI_12.lstResponseInternetPlansDTO);
      plans = plans.concat(dataATT_ADI_24.lstResponseInternetPlansDTO);
      plans = plans.concat(dataATT_ADI_36.lstResponseInternetPlansDTO);

      setPlansData(plans);
    }
  }, [data, dataATT_IPBB, dataATT_ADI_12, dataATT_ADI_24, dataATT_ADI_36]);

  const onChangeSort = (e: any) => {
    const plans = sortByPlansInternet(plansData, e.value);
    setPlansData(plans);

    setIFilters({
      ...iFilters,
      sortByCompare: e.value,
    });
  };

  const INTERNET_SORT_BY: ISortBy[] = [
    { value: EnumSortBy.NONE, label: "Select One" },
    { value: EnumSortBy.LOW_TO_HIGH_PRICE, label: "Price: low to high" },
    { value: EnumSortBy.HIGH_TO_LOW_PRICE, label: "Price: high to low" },
    { value: EnumSortBy.LOW_TO_HIGH_SPEED, label: "Speed: low to high" },
    { value: EnumSortBy.HIGH_TO_LOW_SPEED, label: "Speed: high to low" },
  ];

  let count = 1;

  return (
    <>
      {plansData && iFilters.plansCompareInternet.length < plansData.length && (
        <div className="container">
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-cd94392 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="cd94392"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5629f7e"
                data-id="5629f7e"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-faf724e elementor-widget elementor-widget-html"
                    data-id="faf724e"
                    data-element_type="widget"
                    data-widget_type="html.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="container text-center">
                        <span className="these-available">
                          THESE PLANS ARE ALSO
                          <br />
                          AVAILABLE IN YOUR AREA
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div
              id="filterby-compare-plan"
              className="col-12 col-md-6 align-items-center"
            >
              <span className="filtersortby">Sort by:</span>
              <Select
                optionsSelect={INTERNET_SORT_BY}
                onChange={onChangeSort}
                value={iFilters.sortByCompare.toString()}
              />
            </div>
          </div>
        </div>
      )}
      {plansData &&
        plansData.map((plan, index) => {
          const exists = iFilters.plansCompareInternet.find(
            (pc) =>
              pc.codeProvider === plan.codeProvider && pc.idPlan === plan.planId
          );

          if (count <= 3 && !exists) {
            count++;
            return (
              <InternetPlanLarge key={index} plan={plan} isCompare={true} />
            );
          }

          return <></>;
        })}
    </>
  );
};

export default CompareCardsInternet;
