import React, { useEffect, useState } from 'react';
import { EnumHTTPMessage } from '../../contracts/types';
import useValidCredentials from '../../hooks/api/userValidCredentials';
import useTbiGetHttpMessate from '../../hooks/api/useTbiGetHttpMessage';
import useTbiOrders from '../../hooks/api/useTbiOrders';
import { useGlobalState } from '../../state';

const TBIOrders: React.FC = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [valueUser, setValueUser] = useState('');
    const [valuePassword, setValuePassword] = useState('');
    const [showModal, setShowModal] = useState(iPlanFilterParams.isLocalhost ? false : true);
    const [httpMessate, setHttpMessate] = useState(EnumHTTPMessage.EMPTY);
    const [orderAddressId, setOrderAddressId] = useState(-1);

    const {
        data
    } = useTbiOrders();

    const {
        data: jsonHttpMessage
    } = useTbiGetHttpMessate(httpMessate, orderAddressId);

    useEffect(() => {
        if (jsonHttpMessage && httpMessate !== EnumHTTPMessage.EMPTY && orderAddressId !== -1) {

            const url = window.URL.createObjectURL(
                new Blob([jsonHttpMessage]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${httpMessate}.json`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            if (link && link.parentNode) {
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }

            setHttpMessate(EnumHTTPMessage.EMPTY);
            setOrderAddressId(-1);
        }
    }, [jsonHttpMessage]);

    useValidCredentials(valueUser, valuePassword);

    const onClickLogin = () => {
        if (valueUser === 'TBI' && valuePassword === 'TBIWir3surf3r2021') {
            setShowModal(false);
        }
    };

    const onChangeUser = (e: any) => {
        setValueUser(e.currentTarget.value);
    };

    const onChangePassword = (e: any) => {
        setValuePassword(e.currentTarget.value);
    };

    const onClickDownloadRequest = (pHttpMessate: EnumHTTPMessage, orderAddressId: number) => {
        setHttpMessate(pHttpMessate);
        setOrderAddressId(orderAddressId);
    };

    return (<>
        {
            showModal &&
            <div className="container tbiOrders">
                <div className="align-middle" style={{ height: "100%" }}>
                    <br /><br />
                    <div className="mb-3 row">
                        <label htmlFor="user" className="col-sm-2 col-form-label">User</label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                id="user"
                                onChange={onChangeUser}
                                value={valueUser}
                                className="form-control"
                                placeholder="User" />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                        <div className="col-sm-10">
                            <input
                                type="password"
                                id="password"
                                onChange={onChangePassword}
                                value={valuePassword}
                                className="form-control"
                                placeholder="***" />
                        </div>
                    </div>
                    <div className="col-auto">
                        <button type="button" className="btn btn-primary mb-3" onClick={onClickLogin}>Login</button>
                    </div>
                </div>
            </div>
        }
        {
            !showModal &&
            <div className="container-fluid">
                <table className="table table-bordered table-hover table-sm bootgrid">
                    <thead className="thead-dark">
                        <tr>
                            <th data-column-id="id">Quote #</th>
                            <th data-column-id="date">Date</th>
                            <th data-column-id="company">Company Name</th>
                            <th data-column-id="name">Name</th>
                            <th data-column-id="email">Email</th>
                            <th data-column-id="phone">Phone</th>
                            <th data-column-id="planType">Plan Type</th>
                            <th data-column-id="planName">Plan Name</th>
                            <th data-column-id="planTotal">Total</th>
                            <th data-column-id="status">Lead Status</th>
                            <th data-column-id="referral">Referral</th>
                            <th data-column-id="url">URL</th>
                            <th data-column-id="requestResponse">Request/Response</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map(orderDTO => {
                                return <tr>
                                    <td>{orderDTO.id}</td>
                                    <td>{orderDTO.date}</td>
                                    <td>{orderDTO.companyName}</td>
                                    <td>{orderDTO.name}</td>
                                    <td>{orderDTO.email}</td>
                                    <td>{orderDTO.phone}</td>
                                    <td>{orderDTO.planType}</td>
                                    <td>{orderDTO.planName}</td>
                                    <td>${orderDTO.planTotal?.toFixed(2)}</td>
                                    <td>{orderDTO.status}</td>
                                    <td>{orderDTO.referred}</td>
                                    <td><a target="_blank" href={`${iPlanFilterParams.baseUrl}${orderDTO.url}`}>Quote #{orderDTO.id}</a></td>
                                    <td style={{ textAlign: "center" }}>
                                        {
                                            (orderDTO.orderAddressId && orderDTO.orderAddressId !== -1)
                                                ? <>
                                                    <a target="_blank" href='javascript:void(0)' onClick={() => onClickDownloadRequest(EnumHTTPMessage.REQUEST, orderDTO.orderAddressId)}>Request</a>
                                                    /
                                                    <a target="_blank" href='javascript:void(0)' onClick={() => onClickDownloadRequest(EnumHTTPMessage.RESPONSE, orderDTO.orderAddressId)}>Response</a>
                                                </>
                                                : <></>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }
    </>
    );
};

export default TBIOrders;