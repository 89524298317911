import React, { useEffect, useState } from 'react';
import { IOptionSelect } from '../contracts/types';

interface ISelectProps {
    optionsSelect: IOptionSelect[];
    value?: string | undefined;
    onChange: (optionsSelected: IOptionSelect) => void;
}

const Select: React.FC<ISelectProps> = ({ optionsSelect, onChange, value = undefined }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [optionSelected, setOptionSelected] = useState<IOptionSelect>();

    useEffect(() => {
        if (!optionSelected) {
            const option = optionsSelect.find(o => o.value.toString() === value) ? optionsSelect.find(o => o.value.toString() === value) : optionsSelect[0];

            setOptionSelected(option);
        }
    }, []);

    const onClickShowOptions = (e: any) => {
        setShowOptions(!showOptions);
    };

    const onClickSelectOption = (optionSelected: IOptionSelect) => {
        setOptionSelected(optionSelected);
        setShowOptions(!showOptions);
        onChange(optionSelected);
    };

    return (
        <div className="dropdown">
            <button type="button" className="btn btn-primary dropdown-toggle colordropdownfilter" data-toggle="dropdown" onClick={onClickShowOptions}>
                {
                    optionSelected && optionSelected.label
                }
                <span className="caret"></span>
            </button>
            {
                showOptions &&
                <ul className={`dropdown-menu color-dropdownlist${window.location.pathname.includes('compare') && 'filter-compare-sort'}`}>
                    {
                        optionsSelect.map((option: IOptionSelect) =>
                            <li>
                                <a className="dropdown-item-filter" href="javascript:void(0)" onClick={() => onClickSelectOption(option)}>{option.label}</a>
                            </li>
                        )
                    }
                </ul>
            }
        </div>
    );
};

export default Select;