import React, { useEffect, useState } from "react";
import Wizard from "./wizard.comp";
import WizardPage from "./wizardPage.comp";
import useSurvey from "../../hooks/api/useSurvey";
import { IPageSurveyDTO, IWrSettings } from "../../contracts/types";
import { useGlobalState } from "../../state";
import Loading from "../loading.comp";

interface ISurveyProps {
  wrSettings: IWrSettings;
}

const Survey: React.FC<ISurveyProps> = ({ wrSettings }) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [showContent, setShowContent] = useState(true);
  const [searchOrder, setSearchOrder] = useState(false);
  const [encryptOrder, setEncryptOrder] = useState("");

  const { isLoading, isError, data } = useSurvey(searchOrder, encryptOrder);

  useEffect(() => {
    const path = window.location.pathname;
    console.log("path = ", path);

    if (path.includes("quotenow") && path.includes("quote")) {
      const encryptOrderId = new URLSearchParams(window.location.search).get(
        "quote"
      );

      if (encryptOrderId && encryptOrderId !== "") {
        setSearchOrder(true);
        setEncryptOrder(encryptOrderId);

        let surveyData = iPlanFilterParams.surveyData;
        surveyData.isOpen = true;
        surveyData.orderId = -1;
        surveyData.isPageThanks = false;

        setIPlanFilterParams({
          ...iPlanFilterParams,
          surveyData: surveyData,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (iPlanFilterParams.surveyData.isPageThanks) {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
  }, [iPlanFilterParams.surveyData.isPageThanks]);

  return (
    <>
      {iPlanFilterParams.surveyData.isOpen && (
        <div className="inner">
          {!iPlanFilterParams.surveyData.isPageThanks && (
            <div className="contentLeft">
              {iPlanFilterParams.surveyData.currentPageIndex &&
              iPlanFilterParams.surveyData.currentPageIndex !== 0 ? (
                <div className="divOrderIdFormWizard">
                  QUOTE # {iPlanFilterParams.surveyData.orderId}
                </div>
              ) : (
                <></>
              )}
              <img
                src="/wp-content/wiresresources/images/2021/06/formWizzard.jpg"
                alt=""
              />
            </div>
          )}
          {
            <div className={showContent ? "content" : ""}>
              {isLoading ? (
                <Loading typeName="Get Survey..." />
              ) : isError ? (
                <div>Error fetching data</div>
              ) : (
                <>
                  {iPlanFilterParams.surveyData.isOpen && (
                    <form autoComplete="off">
                      <Wizard>
                        {data?.pages.map((page: IPageSurveyDTO, index) => {
                          return (
                            <WizardPage
                              key={index}
                              wrSettings={wrSettings}
                              pageSurveyDTO={page}
                              isLastPage={data?.pages.length - 1 === index}
                            />
                          );
                        })}
                      </Wizard>
                    </form>
                  )}
                </>
              )}
            </div>
          }
        </div>
      )}
    </>
  );
};

export default Survey;
