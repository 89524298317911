import React, { useEffect, useState } from "react";
import {
  EnumPlanType,
  IPlanFilterParamsInternet,
  IQuestionDTO,
  ISmartyResponseDTO,
  IWrSettings,
} from "../../../contracts/types";
import { useGlobalState } from "../../../state";
import Select from "react-select";
import AddressText from "../../search/addressText.comp";

interface IQuestionBillingProps {
  wrSettings: IWrSettings;
  questionDTO: IQuestionDTO;
  onChange: (
    value: string,
    billingSmarty: ISmartyResponseDTO,
    isClickCalendly: boolean,
    billingAddresSelected: string
  ) => void;
}

const QuestionBilling: React.FC<IQuestionBillingProps> = ({
  wrSettings,
  questionDTO,
  onChange,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iFilters] = useGlobalState("filters");
  const [billingAddresSelected, setBillingAddresSelected] = useState(
    iPlanFilterParams.billingAddresSelected
  );

  const [optionsMultiAddress, setOptionsMultiAddress] = useState<any>([]);
  useEffect(() => {
    let opts = [];

    if (
      iPlanFilterParams.multipleAddresses?.multipleInternetParams?.length >= 1
    ) {
      opts = iPlanFilterParams.multipleAddresses?.multipleInternetParams?.map(
        (addr: IPlanFilterParamsInternet) => ({
          value: addr.idMultiple,
          label: `${addr.streetLine}${
            addr.secondary !== "" ? `, ${addr.secondary}` : ""
          }, ${addr.city}, ${addr.state} ${addr.zipCode}`,
        })
      );

      if (
        iPlanFilterParams.multipleAddresses?.multipleInternetParams.length > 1
      ) {
        opts.unshift({ value: 0, label: "Select" });
      }
    } else {
      if (iPlanFilterParams.internetParams.streetLine !== "") {
        const secondary =
          iPlanFilterParams.internetParams.secondary !== ""
            ? `${iPlanFilterParams.internetParams.secondary}, `
            : "";
        opts.push({
          value: -1,
          label: `${iPlanFilterParams.internetParams.streetLine}, ${secondary}${iPlanFilterParams.internetParams.city}, ${iPlanFilterParams.internetParams.state} ${iPlanFilterParams.internetParams.zipCode}`,
        });
      }
    }

    setOptionsMultiAddress(opts);
  }, []);

  const onChangeBilling = (e: any) => {
    setBillingAddresSelected("SEL");

    if (e.value !== -1 && e.value !== 0) {
      const selectedAddress =
        iPlanFilterParams.multipleAddresses.multipleInternetParams.find(
          (addr: IPlanFilterParamsInternet) => addr.idMultiple === e.value
        );

      if (selectedAddress) {
        const smarty: ISmartyResponseDTO = {
          street_line: selectedAddress.streetLine,
          zipcode: selectedAddress.zipCode,
          city: selectedAddress.city ? selectedAddress.city : "",
          state: selectedAddress.state ? selectedAddress.state : "",
          entries: 0,
          secondary: selectedAddress.secondary ? selectedAddress.secondary : "",
        };

        onChange(optionsMultiAddress[0].label, smarty, false, "SEL");
      }
    }
  };

  const onClickBillingAddress = (useBillingAddress: string) => {
    if (useBillingAddress === billingAddresSelected) {
      return;
    }

    if (useBillingAddress === "SEL" && optionsMultiAddress.length === 0) {
      return;
    }

    setBillingAddresSelected(useBillingAddress);

    if (useBillingAddress === "SEL" && optionsMultiAddress.length === 1) {
      const streetLine =
        optionsMultiAddress[0].value === -1
          ? iPlanFilterParams.internetParams.streetLine
            ? iPlanFilterParams.internetParams.streetLine
            : ""
          : iPlanFilterParams.multipleAddresses.multipleInternetParams[0]
              .streetLine;
      const zipcode =
        optionsMultiAddress[0].value === -1
          ? iPlanFilterParams.internetParams.zipCode
            ? iPlanFilterParams.internetParams.zipCode
            : ""
          : iPlanFilterParams.multipleAddresses.multipleInternetParams[0]
              .zipCode;
      const city =
        optionsMultiAddress[0].value === -1
          ? iPlanFilterParams.internetParams.city
            ? iPlanFilterParams.internetParams.city
            : ""
          : iPlanFilterParams.multipleAddresses.multipleInternetParams[0].city;
      const state =
        optionsMultiAddress[0].value === -1
          ? iPlanFilterParams.internetParams.state
            ? iPlanFilterParams.internetParams.state
            : ""
          : iPlanFilterParams.multipleAddresses.multipleInternetParams[0].state;
      const secondary =
        optionsMultiAddress[0].value === -1
          ? iPlanFilterParams.internetParams.secondary
            ? iPlanFilterParams.internetParams.secondary
            : ""
          : iPlanFilterParams.multipleAddresses.multipleInternetParams[0]
              .secondary;

      const smarty: ISmartyResponseDTO = {
        street_line: streetLine,
        zipcode: zipcode,
        city: city ? city : "",
        state: state ? state : "",
        entries: 0,
        secondary: secondary ? secondary : "",
      };

      onChange(optionsMultiAddress[0].label, smarty, false, useBillingAddress);
    } else {
      const smarty: ISmartyResponseDTO = {
        street_line: "",
        zipcode: "",
        city: "",
        state: "",
        entries: 0,
        secondary: "",
      };

      onChange("", smarty, false, useBillingAddress);
    }
  };

  const onSelectAddressInText = (smartyResponseDTO: ISmartyResponseDTO) => {
    setBillingAddresSelected("INP");
    onChange(
      `${smartyResponseDTO.street_line}, ${smartyResponseDTO.city}, ${smartyResponseDTO.state} ${smartyResponseDTO.zipcode}`,
      smartyResponseDTO,
      false,
      "INP"
    );
  };

  return (
    <>
      <span>
        <strong>Bill to:</strong>
      </span>
      {iFilters.planTypeCode !== EnumPlanType.BUSINESS_VOICE_PHONE ? (
        <div
          className={
            billingAddresSelected === "SEL"
              ? "row billingAddress"
              : "row billingAddressOff"
          }
          onClick={() => onClickBillingAddress("SEL")}
        >
          <div className="holder dropDownBillingOrder">
            <label>Same as service address:</label>
            <Select
              options={optionsMultiAddress}
              onChange={onChangeBilling}
              value={
                optionsMultiAddress.length === 1
                  ? optionsMultiAddress[0]
                  : optionsMultiAddress.find((option: any) => {
                      if (iPlanFilterParams.billingSmarty) {
                        const optSel = `${
                          iPlanFilterParams.billingSmarty.street_line
                        }${
                          iPlanFilterParams.billingSmarty.secondary !== ""
                            ? `, ${iPlanFilterParams.billingSmarty.secondary}`
                            : ""
                        }, ${iPlanFilterParams.billingSmarty.city}, ${
                          iPlanFilterParams.billingSmarty.state
                        } ${iPlanFilterParams.billingSmarty.zipcode}`;

                        if (option.label === optSel) {
                          return option;
                        }
                      }
                    })
              }
              isDisabled={optionsMultiAddress.lentth === 1 ? true : false}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div
        className={
          iFilters.planTypeCode !== EnumPlanType.BUSINESS_VOICE_PHONE
            ? billingAddresSelected === "INP"
              ? "row billingAddress"
              : "row billingAddressOff"
            : "row"
        }
        onClick={() => onClickBillingAddress("INP")}
      >
        <div className="holder">
          <label>Use a different billing address</label>
          <AddressText
            isMultipleAddress={false}
            isBillingAddress={true}
            placeHolder="Enter Address"
            onClickSelectAddressParent={onSelectAddressInText}
          />
        </div>
      </div>
    </>
  );
};

export default QuestionBilling;
