import { useState, useEffect } from 'react';
import { IResponseVoicePlansDTO, EnumViewPlansType } from "../../contracts/types";
import useVoicePlans from '../../hooks/api/useVoicePlans';
import { useGlobalState } from '../../state';

export default function useVoicePlansPagination(viewPlansType: EnumViewPlansType, pageNumber: number) {
    const [iFilters, setIFilters] = useGlobalState('filters');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [plansData, setPlansData] = useState<IResponseVoicePlansDTO[]>([]);
    const [plans, setPlans] = useState<IResponseVoicePlansDTO[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [finishCharge, setFinishCharge] = useState(false);

    const {
        data,
        isLoading,
        isError
    } = useVoicePlans(viewPlansType);

    useEffect(() => {
        setLoading(isLoading);
        setError(isError);
    }, [isLoading, isError]);

    useEffect(() => {
        if (data) {
            const idPlansCompare = iFilters.plansCompareVoice?.filter(id => data.lstResponseVoicePlansDTO.filter(e => e.planId === id).length > 0);

            setIFilters({
                ...iFilters,
                plansCompareVoice: idPlansCompare,
            });

            setPlansData(data.lstResponseVoicePlansDTO);
        }
        else {
            setIFilters({
                ...iFilters,
                plansCompareVoice: [],
            });
        }
    }, [data]);

    useEffect(() => {
        setPlans(plansData.slice(0, pageNumber * 10));
        setHasMore(plansData.length > pageNumber * 10);

        if (data) {
            setFinishCharge(true);
        }
        else {
            setFinishCharge(false);
        }
    }, [plansData, pageNumber]);

    return { loading, error, plans, hasMore, finishCharge };
};