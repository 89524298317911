import { EnumInternetPlanFeatures, EnumPlanTechnologyType, EnumSortBy, IFilters, IResponseBundlePlansDTO } from "../contracts/types";

export const filtersPlansBundles = (arrayPlans: IResponseBundlePlansDTO[], iFilters: IFilters) => {
    if (arrayPlans && arrayPlans.length === 0) {
        return [];
    }

    arrayPlans.forEach((plan: IResponseBundlePlansDTO) => {
        plan.planHidden = false;
    });

    if ((iFilters.filterPrice && (iFilters.filterPrice.min !== 0 || iFilters.filterPrice.max !== 0)) ||
        (iFilters.filterSpeed && (iFilters.filterSpeed.min !== 0 || iFilters.filterSpeed.max !== 0)) ||
        (iFilters.filterTerm && iFilters.filterTerm.length > 0) ||
        (iFilters.filterPlanFeatures && iFilters.filterPlanFeatures.length > 0) ||
        (iFilters.filterProvider && iFilters.filterProvider.length > 0)
    ) {
        arrayPlans.forEach((plan: IResponseBundlePlansDTO) => {
            let visiblePrice = true;
            let visibleSpeed = true;
            let visibleTerm = true;
            let visibleProvider = true;
            let visibleFeature = true;

            if (iFilters.filterPrice && (iFilters.filterPrice.min !== 0 || iFilters.filterPrice.max !== 0)) {
                visiblePrice = (plan.planPrice >= iFilters.filterPrice!.min);
                if (visiblePrice && iFilters.filterPrice!.max !== 0) {
                    visiblePrice = (plan.planPrice <= iFilters.filterPrice!.max);
                }
            }

            if (iFilters.filterSpeed && (iFilters.filterSpeed.min !== 0 || iFilters.filterSpeed.max !== 0)) {
                visibleSpeed = (plan.planDownloadSpeed >= iFilters.filterSpeed!.min);
                if (visibleSpeed && iFilters.filterSpeed!.max !== 0) {
                    visibleSpeed = (plan.planDownloadSpeed <= iFilters.filterSpeed!.max);
                }
            }

            if (iFilters.filterTerm && iFilters.filterTerm.length > 0) {
                visibleTerm = iFilters.filterTerm.includes(plan.planTerm);
            }

            if (iFilters.filterProvider && iFilters.filterProvider.length > 0) {
                visibleProvider = iFilters.filterProvider.includes(plan.providerId);
            }

            if (iFilters.filterPlanFeatures && iFilters.filterPlanFeatures.length > 0) {
                let isVisible = false;
                iFilters.filterPlanFeatures.forEach((feature) => {
                    if (isVisible === false) {
                        switch (feature) {
                            case EnumInternetPlanFeatures.CABLE:
                                isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.CABLE);
                                break;
                            case EnumInternetPlanFeatures.DSL:
                                isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.DSL);
                                break;
                            case EnumInternetPlanFeatures.FIBER:
                                isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.FIBER);
                                break;
                            case EnumInternetPlanFeatures.HFC:
                                isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.HFC);
                                break;
                            case EnumInternetPlanFeatures.METERED:
                                isVisible = (plan.planTechnologyCode === EnumPlanTechnologyType.METERED);
                                break;
                            case EnumInternetPlanFeatures.EQUIPMENT_INCLUDED:
                                isVisible = (plan.planModemIncluded);
                                break;
                        }
                    }
                });

                visibleFeature = isVisible;
            }

            plan.planHidden = !(visiblePrice && visibleSpeed && visibleTerm && visibleProvider && visibleFeature);
        });
    }

    arrayPlans = arrayPlans.sort((a, b) => {
        switch (iFilters.sortBy) {
            case EnumSortBy.LOW_TO_HIGH_PRICE:
                return a.planPrice - b.planPrice;
            case EnumSortBy.HIGH_TO_LOW_PRICE:
                return b.planPrice - a.planPrice;
            case EnumSortBy.LOW_TO_HIGH_SPEED:
                return a.planDownloadSpeed - b.planDownloadSpeed;
            case EnumSortBy.HIGH_TO_LOW_SPEED:
                return b.planDownloadSpeed - a.planDownloadSpeed;
            default:
                return b.planRated - a.planRated;
        }
    });

    return arrayPlans;
}