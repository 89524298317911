import React, { useEffect, useState } from 'react';
import { IRequestClickToDialTBIDTO } from '../../../contracts/types';
import useClickToDialSendOrder from '../../../hooks/api/useClickToDialSendOrder';
import useClickToDialSendTBI from '../../../hooks/api/useClickToDialSendTBI';
import NumberFormat from 'react-number-format';
import { useGlobalState } from '../../../state';

interface IClickToDialLearnMoreProps {
    isMobile?: boolean;
}

const ClickToDialLearnMore: React.FC<IClickToDialLearnMoreProps> = ({ isMobile }) => {
    const [iAuditVisitor] = useGlobalState('auditVisitor');

    const [requestSendOrder, setRequestSendOrder] = useState<IRequestClickToDialTBIDTO>();
    const [txtFirstName, setTxtFirstName] = useState('');
    const [txtPhone, setTxtPhone] = useState('');
    const [showFormToDial, setShowFormToDial] = useState(false);

    const { data: dataSendToDial } = useClickToDialSendOrder(requestSendOrder);

    const { } = useClickToDialSendTBI(requestSendOrder);

    useEffect(() => {
        if (dataSendToDial) {
            onClickRequestCall();
        }
    }, [dataSendToDial]);

    const onChangeFirstName = (e: any) => {
        setTxtFirstName(e.currentTarget.value);
    };

    const onChangePhone = (e: any) => {
        setTxtPhone(e.currentTarget.value);
    };

    const onClickSubmitTBI = (event: any) => {
        const request: IRequestClickToDialTBIDTO = {
            firstName: txtFirstName,
            lastName: txtFirstName,
            phone: txtPhone,
            email: '',
            queryString: window.location.search,
            referred: iAuditVisitor.referred
        };

        setRequestSendOrder(request);
    };

    const onClickRequestCall = () => {
        setShowFormToDial(!showFormToDial);
    };

    return (
        <div className="col-md-12 my-1 rounded-3 learnmore-footer-form">
            {
                showFormToDial &&
                <div className="col-md-12 footer-requestcall" id="requestcall-footer">
                    <form className="frmClickToDial" role="form" name="web2campaign" action="" method="POST">
                        <div className="input-holder">
                            <label className="text-learnmore-footer" htmlFor="first_name">Name</label>
                            <div className="">
                                <input className="form-control input-learnfooter" type="text" name="first_name" id="first_name" value={txtFirstName} onChange={onChangeFirstName} autoComplete="off" />
                            </div>
                        </div>
                        <div className="input-holder">
                            <label className="text-learnmore-footer" htmlFor="number1">Phone Number</label>
                            <div className="">
                                <NumberFormat format="+1 (###) ###-####" mask="_" className={`form-control input-learnfooter`} value={txtPhone} onChange={onChangePhone} title="" required={true} id="number1" name="number1" autoComplete="off" />
                            </div>
                        </div>
                        <div className="divButtonSubmitClickToDial p-3">
                            <a className="btn btn-primary buttonSubmitClickToDial" href="javascript:void(0)" onClick={onClickSubmitTBI}>
                                Submit
                            </a>
                        </div>
                    </form>
                </div>
            }
            {
                isMobile ?
                    <div className="col-12 my-2">
                        <div className="col-12 boton-footer-requestcall-mobile rounded-3 shadow p-2 container-sizemobile-mobile" onClick={onClickRequestCall}>
                            <span className="learn-requestacall-blue-mobile align-middle" onClick={onClickRequestCall}>REQUEST A CALL</span>
                        </div>
                    </div>
                    : <div className="col-md-12 p-3 rounded-3">
                        <div className="boton-footer-requestcall link-url" onClick={onClickRequestCall}>
                            <button className="bg-blue" onClick={onClickRequestCall}>
                                <span className="learn-texttitlepurple" >REQUEST CALL</span>
                            </button>
                        </div>
                    </div>
            }

        </div>
    );
};

export default ClickToDialLearnMore;
