import React, { useEffect, useState } from "react";
import {
  EnumPlanType,
  EnumSearchType,
  EnumViewPlansLargeType,
  EnumViewPlansType,
  IMultipleAddresses,
  ISmartyResponseDTO,
} from "../../contracts/types";
import { IPlanFilterParamsInternet } from "../../contracts/types";
import { useGlobalState } from "../../state";
import AddressText from "./addressText.comp";
import useInternetPlans from "../../hooks/api/useInternetPlans";
import useAuditSaveSearch from "../../hooks/api/useAuditSaveSearch";
import LineMultiple from "./lineMultiple.com";
import { dispatchDataLayer } from "../../helpers/fnGoogleTag";

interface ITabInternetProps {}

const TabInternet: React.FC<ITabInternetProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iAuditVisitor, setIAuditVisitor] = useGlobalState("auditVisitor");

  const [addressValue, setAddressValue] = useState("");
  const [smartyResponse, setSmartyResponse] = useState<ISmartyResponseDTO>();
  const [isGoSearch, setIsGoSearch] = useState(false);

  const {} = useInternetPlans(EnumViewPlansType.SHORT, "", 0);

  const { data: dataSearch } = useAuditSaveSearch();

  useEffect(() => {
    if (dataSearch && isGoSearch) {
      window.open(iPlanFilterParams.baseUrl + "search", "_self");
    }
  }, [dataSearch]);

  const onClickSearchInternet = (e: any) => {
    let dumSmarty = smartyResponse;
    let enterSearch = true;

    if (
      iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
      !iPlanFilterParams.smartyAddressInternet &&
      iPlanFilterParams.internetParams.address === "" &&
      iPlanFilterParams.smartyAddressBundle &&
      iPlanFilterParams.smartyAddressBundle.street_line !== ""
    ) {
      const address = `${iPlanFilterParams.smartyAddressBundle.street_line} ${iPlanFilterParams.smartyAddressBundle.city}`;
      const entries =
        iPlanFilterParams.smartyAddressBundle.entries > 1
          ? ` (${iPlanFilterParams.smartyAddressBundle.entries} more entries)`
          : "";
      const addressDetail = `${
        iPlanFilterParams.smartyAddressBundle.street_line
      }, ${
        iPlanFilterParams.smartyAddressBundle.secondary !== ""
          ? `${iPlanFilterParams.smartyAddressBundle.secondary}${entries}, `
          : ""
      }${iPlanFilterParams.smartyAddressBundle.city}, ${
        iPlanFilterParams.smartyAddressBundle.state
      } ${iPlanFilterParams.smartyAddressBundle.zipcode}`;

      setSmartyResponse(iPlanFilterParams.smartyAddressBundle);

      let newAddress: IPlanFilterParamsInternet = {
        streetLine: iPlanFilterParams.smartyAddressBundle.street_line,
        city: iPlanFilterParams.smartyAddressBundle.city,
        zipCode: iPlanFilterParams.smartyAddressBundle.zipcode,
        address: address,
        addressDetail: addressDetail,
        state: iPlanFilterParams.smartyAddressBundle.state,
        secondary: iPlanFilterParams.smartyAddressBundle.secondary,
        latitude: 0,
        longitude: 0,
        country: "US",
        smartyAddress: null,
        idMultiple: 0,
        plansByCodeProvider: [],
        plan: {
          planId: 0,
          productId: 0,
          internalPlanId: 0,
          planName: "",
          planServiceTypeCode: "",
          planTypeCode: EnumPlanType.BUSINESS_INTERNET,
          planTypeName: "",
          displayName: "",
          providerId: 0,
          providerName: "",
          providerLogo: "",
          planRated: 0,
          planFiberFastQualType: "",
          planTechnologyName: "",
          planDownloadSpeed: 0,
          planUploadSpeed: 0,
          planTerm: 0,
          planModemIncluded: false,
          planPrice: 0,
          planInstallPrice: 0,
          planLatitude: 0,
          planLongitude: 0,
          planNote: "",
          planHidden: false,
          planServiceTypeName: "",
          learnMore: "",
          codeProvider: "",
        },
      };

      setTimeout(function () {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          internetParams: newAddress,
          smartyAddressInternet: iPlanFilterParams.smartyAddressBundle,
          groupByTerms: [],
          groupByProviders: [],
        });
      }, 100);

      dumSmarty = iPlanFilterParams.smartyAddressBundle;
      enterSearch = false;
    }

    if (dumSmarty) {
      if (
        addressValue !== "" &&
        addressValue !== iAuditVisitor.search.address
      ) {
        let type = EnumSearchType.HOME;

        if (window.location.pathname.includes("search")) {
          type = EnumSearchType.SEARCH;
        } else if (window.location.pathname.includes("multiple")) {
          type = EnumSearchType.MULTIPLE;
        }

        setIAuditVisitor({
          ...iAuditVisitor,
          search: {
            ...iAuditVisitor.search,
            id: -1,
            visitorId: iAuditVisitor.id,
            type: type,
            planTypeCode: iFilters.planTypeCode,
            address: `${dumSmarty.street_line}, ${
              dumSmarty.secondary !== ""
                ? `${dumSmarty.secondary}${dumSmarty.entries}, `
                : ""
            }${dumSmarty.city}, ${dumSmarty.state} ${dumSmarty.zipcode}`,
            stateAbbreviation: dumSmarty.state,
            city: dumSmarty.city,
            zipCode: dumSmarty.zipcode,
            seats: 0,
          },
        });
      }

      const addressDetail = `${dumSmarty.street_line}, ${
        dumSmarty.secondary !== ""
          ? `${dumSmarty.secondary}${dumSmarty.entries}, `
          : ""
      }${dumSmarty.city}, ${dumSmarty.state} ${dumSmarty.zipcode}`;
      const objDatLayer = {
        event: "search_bi",
        location: addressDetail,
      };
      dispatchDataLayer(objDatLayer);
    }

    if (!window.location.pathname.includes("search") && addressValue !== "") {
      if (smartyResponse && iPlanFilterParams.internetParams.zipCode === "-1") {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          internetParams: {
            ...iPlanFilterParams.internetParams,
            zipCode: "",
          },
        });
      } else if (smartyResponse) {
        setIPlanFilterParams({
          ...iPlanFilterParams,
          smartyAddressInternet: smartyResponse,
        });
      }

      if (iFilters.showMoreLocations) {
        const intParam =
          iPlanFilterParams.multipleAddresses.multipleInternetParams.find(
            (pi) => pi.addressDetail !== ""
          );

        if (intParam) {
          intParam.openedGroupAddressPlans = true;
          setIPlanFilterParams({
            ...iPlanFilterParams,
            internetParams: intParam,
            idAddressMultiple: intParam.idMultiple,
            removeQuery: true,
          });

          setIFilters({
            ...iFilters,
            viewPlansLargeTypeInternet: EnumViewPlansLargeType.MULTIADDRESS,
          });

          setTimeout(function () {
            window.open(iPlanFilterParams.baseUrl + "search", "_self");
          }, 100);

          return;
        }
      }

      setIsGoSearch(true);
    } else {
      if (iFilters.showMoreLocations) {
        iPlanFilterParams.multipleAddresses?.multipleInternetParams.forEach(
          (internetParams) => {
            setTimeout(function () {
              setIPlanFilterParams({
                ...iPlanFilterParams,
                internetParams: internetParams,
                removeQuery: false,
              });
            }, 100);
          }
        );
      } else {
        if (enterSearch && dumSmarty) {
          setIPlanFilterParams({
            ...iPlanFilterParams,
            smartyAddressInternet: dumSmarty,
            groupByTerms: [],
            groupByProviders: [],
          });
        }
      }
    }
  };

  const onClickLinkMultiple = (e: any) => {
    if (iPlanFilterParams.internetParams.address !== "") {
      const multipleAddresses: IMultipleAddresses =
        iPlanFilterParams.multipleAddresses;
      let arrayMultipleParams: IPlanFilterParamsInternet[] =
        multipleAddresses === undefined ||
        multipleAddresses.multipleInternetParams === null
          ? []
          : multipleAddresses.multipleInternetParams;
      const existsPlanFilterInMultiple: boolean = arrayMultipleParams.find(
        (planFilter) =>
          planFilter.address === iPlanFilterParams.internetParams.address
      )
        ? true
        : false;

      if (!existsPlanFilterInMultiple) {
        let paramsInternet = iPlanFilterParams.internetParams;
        paramsInternet.idMultiple = 1;
        paramsInternet.openedGroupAddressPlans = false;

        arrayMultipleParams.push(paramsInternet);

        setIPlanFilterParams({
          ...iPlanFilterParams,
          multipleAddresses: {
            ...iPlanFilterParams.multipleAddresses,
            multipleInternetParams: arrayMultipleParams,
          },
        });
      }
    }

    //if (!iPlanFilterParams.isLocalhost) {
    window.open(iPlanFilterParams.baseUrl + "multiple", "_self");
    //}
  };

  const onClickMoreLocations = (e: any) => {
    if (
      e.target.checked &&
      iPlanFilterParams.multipleAddresses.multipleInternetParams.length === 0
    ) {
      let smartyAddress = iPlanFilterParams.smartyAddressInternet;

      if (!smartyAddress) {
        smartyAddress = {
          city: "",
          entries: 0,
          secondary: "",
          state: "",
          street_line: "",
          zipcode: "",
        };
      }

      let arrayMultipleParams: IPlanFilterParamsInternet[] = [];

      let newAddress: IPlanFilterParamsInternet = {
        streetLine: "",
        city: "",
        zipCode: "",
        address: "",
        addressDetail: "",
        state: "",
        secondary: "",
        latitude: 0,
        longitude: 0,
        country: "US",
        idMultiple: 1,
        openedGroupAddressPlans: false,
        smartyAddress: null,
        plansByCodeProvider: [],
        plan: {
          planId: 0,
          productId: 0,
          internalPlanId: 0,
          planName: "",
          planServiceTypeCode: "",
          planTypeCode: EnumPlanType.BUSINESS_INTERNET,
          planTypeName: "",
          displayName: "",
          providerId: 0,
          providerName: "",
          providerLogo: "",
          planRated: 0,
          planFiberFastQualType: "",
          planTechnologyName: "",
          planDownloadSpeed: 0,
          planUploadSpeed: 0,
          planTerm: 0,
          planModemIncluded: false,
          planPrice: 0,
          planInstallPrice: 0,
          planLatitude: 0,
          planLongitude: 0,
          planNote: "",
          planHidden: false,
          planServiceTypeName: "",
          learnMore: "",
          codeProvider: "",
        },
      };

      if (iPlanFilterParams.internetParams.address !== "") {
        newAddress = iPlanFilterParams.internetParams;
        newAddress.smartyAddress = iPlanFilterParams.smartyAddressInternet;
        newAddress.idMultiple = 1;
      }

      arrayMultipleParams.push(newAddress);

      setIPlanFilterParams({
        ...iPlanFilterParams,
        multipleAddresses: {
          ...iPlanFilterParams.multipleAddresses,
          multipleInternetParams: arrayMultipleParams,
        },
      });
    }

    setIFilters({
      ...iFilters,
      showMoreLocations: e.target.checked,
      viewPlansLargeTypeInternet: e.target.checked
        ? EnumViewPlansLargeType.MULTIADDRESS
        : EnumViewPlansLargeType.DETAILS,
    });
  };

  const onChangeAddress = (e: any) => {
    setAddressValue(e.currentTarget.value);
  };

  const onClickSelectAddressSuggestions = (
    smartyResponseDTO: ISmartyResponseDTO
  ) => {
    // If is home
    if (window.location.href === iPlanFilterParams.baseUrl) {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        smartyAddressInternet: smartyResponseDTO,
        internetParams: {
          ...iPlanFilterParams.internetParams,
          address: "",
          city: "",
          state: "",
          streetLine: "",
          zipCode: "-1",
        },
      });
    } else if (!window.location.pathname.includes("search")) {
      setIPlanFilterParams({
        ...iPlanFilterParams,
        smartyAddressInternet: smartyResponseDTO,
      });
    }

    setSmartyResponse(smartyResponseDTO);
  };

  return (
    <div
      className={`tab-pane fade ${
        iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET &&
        "show active"
      }`}
      id="searchcontrol-bi"
      role="tabpanel"
      aria-labelledby="searchcontrol-bi-tab"
    >
      <div className="row">
        {iFilters.showMoreLocations ? (
          <>
            {iPlanFilterParams.multipleAddresses.multipleInternetParams
              .sort((a, b) => {
                if (a.idMultiple > b.idMultiple) {
                  return 1;
                }
                return -1;
              })
              .map((interParam, index) => (
                <LineMultiple
                  internetParams={interParam}
                  line={index + 1}
                  onChangeAddress={onChangeAddress}
                  onClickSelectAddressSuggestions={
                    onClickSelectAddressSuggestions
                  }
                  onClickMoreLocations={onClickMoreLocations}
                  onClickSearchInternet={onClickSearchInternet}
                />
              ))}
          </>
        ) : (
          <>
            <div className="col-md-10">
              <div className="input-group">
                <AddressText
                  isMultipleAddress={false}
                  isBillingAddress={false}
                  placeHolder="Enter Address"
                  onChangeAddressParent={onChangeAddress}
                  onClickSelectAddressParent={onClickSelectAddressSuggestions}
                  cssInputAddress="form-control input-address"
                />
              </div>
            </div>
            <div className="col-md-2 col-6 right-alignment">
              <button
                type="button"
                className="btn btn-lg searchButton"
                onClick={onClickSearchInternet}
              >
                Search
              </button>
            </div>
            {/* <div className="col-md-2 col-6">
              <div className="form-check form-switch form-switch-md mt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="LocationSwitch"
                  value="yes"
                  onChange={onClickMoreLocations}
                  checked={iFilters.showMoreLocations}
                />
                <label
                  className="form-check-label morelocation"
                  htmlFor="LocationSwitch"
                >
                  {" "}
                  More locations
                </label>
              </div>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default TabInternet;
