import React from "react";
import {
  EnumViewPlansType,
  IPlanFilterParamsInternet,
  IWrSettings,
} from "../../contracts/types";
import { useGlobalState } from "../../state";
import ItemAddress from "./itemAddress.comp";
import useInternetPlans from "../../hooks/api/useInternetPlans";
import AddressText from "../search/addressText.comp";

interface IMultipleAddressesProps {
  wrSettings: IWrSettings;
}

const MultipleAddresses: React.FC<IMultipleAddressesProps> = ({
  wrSettings,
}) => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");

  const {} = useInternetPlans(EnumViewPlansType.LARGE, "", 0);

  // const {} = useInternetPlans(EnumViewPlansType.LARGE, "AST_CB", 0);

  const {} = useInternetPlans(EnumViewPlansType.LARGE, "AT&T_IPBB", 0);

  const {} = useInternetPlans(EnumViewPlansType.LARGE, "AT&T_ADI", 12);

  const {} = useInternetPlans(EnumViewPlansType.LARGE, "AT&T_ADI", 24);

  const {} = useInternetPlans(EnumViewPlansType.LARGE, "AT&T_ADI", 36);

  const onClickAll = (e: any) => {
    let multipleParams =
      iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(
        (m) => m.selectAddress === e.target.checked
      );
    const multipleParamsContrary =
      iPlanFilterParams.multipleAddresses.multipleInternetParams.filter(
        (m) => m.selectAddress !== e.target.checked
      );

    multipleParamsContrary.forEach((paramIternet) => {
      paramIternet.selectAddress = e.target.checked;
      multipleParams.push(paramIternet);
    });

    setIPlanFilterParams({
      ...iPlanFilterParams,
      multipleAddresses: {
        ...iPlanFilterParams.multipleAddresses,
        multipleInternetParams: [...multipleParams],
      },
      selectedAllAddress: e.target.checked,
    });
  };

  const onClickOrderNow = () => {
    setIPlanFilterParams({
      ...iPlanFilterParams,
      surveyData: {
        ...iPlanFilterParams.surveyData,
        isOpen: true,
        orderId: -1,
        isPageThanks: false,
      },
    });

    if (!iPlanFilterParams.isLocalhost) {
      window.open(iPlanFilterParams.baseUrl + "quotenow", "_self");
    }
  };

  return (
    <div className="divMultipleAddresses">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onClick={(e) => onClickAll(e)}
                checked={
                  iPlanFilterParams.selectedAllAddress
                    ? iPlanFilterParams.selectedAllAddress
                    : false
                }
              />
            </th>
            <th>ID</th>
            <th>Address</th>
            <th>Actions</th>
            <th>Plan</th>
          </tr>
        </thead>
        <tbody>
          {iPlanFilterParams.multipleAddresses.multipleInternetParams
            .sort((a, b) => {
              if (a.idMultiple > b.idMultiple) {
                return 1;
              }
              return -1;
            })
            .map((planIternet: IPlanFilterParamsInternet, index) => (
              <ItemAddress
                key={index}
                planInternet={planIternet}
                index={index + 1}
              />
            ))}
          {/* <tr>
                        <td></td>
                        <td></td>
                        <td colSpan={3}>
                            <AddressText isMultipleAddress={true} isBillingAddress={false} placeHolder='Enter Address' />
                        </td>
                    </tr> */}
        </tbody>
      </table>
      <div className="row">
        <div className="col-md-12">
          {iPlanFilterParams.multipleAddresses.multipleInternetParams.length >=
            1 && (
            <button
              className="buttonOrderGroupAddress"
              onClick={onClickOrderNow}
            >
              Order Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultipleAddresses;
