import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  EnumViewPlansType,
  IResponseInternetPlansDTO,
  IWrSettings,
  EnumViewPlansLargeType,
} from "../../../contracts/types";
import useInternetPlansPagination from "../../../hooks/api/useInternetPlansPagination";
import InternetPlanShort from "./internetPlanShort.comp";
import InternetPlanLarge from "./internetPlanLarge.comp";
import PlanTools from "../../planTools.comp";
import { useGlobalState } from "../../../state";
import GroupAddress from "../../multiple/group/groupAddress.comp";
import Loading from "../../loading.comp";
import LoadingFloating from "../../loadingFloating";
import SeeMorePlans from "../seeMorePlans.comp";
import PlansFilters from "../plansFilters.comp";
import PlanAgentAvailable from "../planAgentAvailable.comp";

interface IInternetPlansProps {
  viewPlansType: EnumViewPlansType;
  wrSettings: IWrSettings;
}

const InternetPlans: React.FC<IInternetPlansProps> = ({
  viewPlansType,
  wrSettings,
}) => {
  const [iPlanFilterParams] = useGlobalState("planFilterParams");
  const [iGlobals] = useGlobalState("globals");
  const [iFilters] = useGlobalState("filters");

  const [pageNumber, setPageNumber] = useState(1);
  const [, setPlanWS] = useState<IResponseInternetPlansDTO>();
  const [showLinkMorePlans, setShowLinkMorePlans] = useState(true);

  useEffect(() => {
    setPageNumber(1);
  }, [iPlanFilterParams.internetParams.zipCode, iFilters]);

  const {
    plans: plansData,
    hasMore,
    loading: isLoading,
    error: isError,
    finishCharge: isFinishCharge,
  } = useInternetPlansPagination(viewPlansType, pageNumber);

  useEffect(() => {
    setPlanWS(undefined);
    if (iPlanFilterParams.internetParams.address !== "") {
      if (isFinishCharge && !hasMore) {
        let result = plansData.reduce(function (r, a) {
          r[a.providerName] = r[a.providerName] || [];
          r[a.providerName].push(a);
          return r;
        }, Object.create(null));
        var groupProviders = Object.keys(result).map(function (key) {
          return key;
        });

        let note =
          "Want additional options? A Wiresurfer telecom expert is standing by to assist from the over 220+ telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";

        if (groupProviders.length === 0) {
          note =
            "A Wiresurfer telecom expert is standing by to assist from the over 220+ business telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";

          if (iFilters.showMoreLocations) {
            note =
              "A Wiresurfer telecom expert is standing by to assist for these locations from the over 220+ telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";
          }
        }

        const planWire: IResponseInternetPlansDTO = {
          planId: -1,
          productId: -1,
          internalPlanId: -1,
          planDownloadSpeed: 0,
          planFiberFastQualType: "",
          planInstallPrice: 0,
          planModemIncluded: false,
          planName: "wiresurfer",
          planNote: note,
          planPrice: 0,
          planRated: 0,
          planServiceTypeCode: "",
          planTechnologyCode: undefined,
          planTechnologyName: "",
          planTerm: 0,
          planTypeCode: undefined,
          planTypeName: "Wiresurfer Agent Available",
          displayName: "Wiresurfer Agent Available",
          planUploadSpeed: 0,
          providerId: -1,
          providerLogo: "logoWiresurferDummy.png",
          providerName: "Wiresurfer",
          planLatitude: 0,
          planLongitude: 0,
        };

        setPlanWS(planWire);
      }
    }
  }, [plansData, isFinishCharge]);

  const observer = useRef<any>(null);
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !showLinkMorePlans) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, showLinkMorePlans]
  );

  const onClickShowLinkMorePlans = () => {
    setShowLinkMorePlans(false);
  };

  return (
    <>
      <div className="container">
        {isLoading &&
        !isFinishCharge &&
        plansData.length === 0 &&
        iPlanFilterParams.idAddressMultiple === -1 ? (
          <>
            {iFilters.viewPlansLargeTypeInternet ===
            EnumViewPlansLargeType.MULTIADDRESS ? (
              <LoadingFloating />
            ) : (
              <Loading typeName="Internet providers nationwide, this may take up to 30 seconds!" />
            )}
          </>
        ) : isError ? (
          <div>Error fetching data</div>
        ) : (
          <>
            {plansData && viewPlansType === EnumViewPlansType.SHORT && (
              <div className="contentThirdListPrices">
                <div className="contentThirdListPrices">
                  <div className="row ThirdListPrices">
                    {plansData && plansData.length === 0 && (
                      <div className="noResultFound">
                        No providers available. But don't fret, we can still
                        help by calling us at (toll-free #). We are busy loading
                        new providers into our platform every week.
                      </div>
                    )}
                    {plansData.map(
                      (plan: IResponseInternetPlansDTO, index: number) => {
                        if (index <= 2) {
                          const providerLogo = iGlobals.carriers.find(
                            (c) => c.id === plan.providerId
                          );
                          plan.providerLogo = providerLogo
                            ? providerLogo.imageBase64
                            : "";

                          return (
                            <InternetPlanShort key={plan.planId} plan={plan} />
                          );
                        }

                        return <></>;
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
            {plansData && viewPlansType === EnumViewPlansType.LARGE && (
              <>
                <PlansFilters />
                <section id="productsWS">
                  <div className="container">
                    <section className="mb-3">
                      <PlanTools />
                    </section>
                    <div className="row resultWSgrid">
                      {plansData &&
                        iFilters.viewPlansLargeTypeInternet !==
                          EnumViewPlansLargeType.MULTIADDRESS &&
                        plansData
                          .slice(
                            0,
                            iPlanFilterParams.idAddressMultiple !== -1 &&
                              iFilters.filterOnlyBestPerAddress
                              ? iPlanFilterParams.numberBestPlansPerAddress
                              : plansData.filter((p) => !p.planHidden).length
                          )
                          .map((plan, index) => {
                            if (
                              iPlanFilterParams.idAddressMultiple !== -1 &&
                              plan.internalPlanId === -1 &&
                              plansData.length > 1
                            ) {
                              return <></>;
                            }

                            if (plan.planHidden) {
                              return <></>;
                            }

                            if (plansData.length === index + 1) {
                              return (
                                <InternetPlanLarge
                                  key={index}
                                  plan={plan}
                                  lastElementRef={lastElementRef}
                                />
                              );
                            } else {
                              return (
                                <InternetPlanLarge key={index} plan={plan} />
                              );
                            }
                          })}
                      {/* {isFinishCharge &&
                        !hasMore &&
                        iFilters.viewPlansLargeTypeInternet !==
                          EnumViewPlansLargeType.MULTIADDRESS && (
                          <PlanAgentAvailable />
                        )} */}
                      {!isFinishCharge && (
                        <>
                          {plansData.length === 0 ? (
                            <>
                              {iFilters.viewPlansLargeTypeInternet ===
                              EnumViewPlansLargeType.MULTIADDRESS ? (
                                <LoadingFloating />
                              ) : (
                                <Loading typeName="Internet providers nationwide, this may take up to 30 seconds!" />
                              )}
                            </>
                          ) : (
                            <LoadingFloating />
                          )}
                        </>
                      )}
                      {window.location.pathname.includes("search") &&
                        iFilters.viewPlansLargeTypeInternet ===
                          EnumViewPlansLargeType.MULTIADDRESS && (
                          <GroupAddress wrSettings={wrSettings} />
                        )}
                      {plansData &&
                        plansData.length > 0 &&
                        hasMore &&
                        pageNumber === 1 &&
                        showLinkMorePlans && (
                          <SeeMorePlans
                            onClickSeeMorePlans={onClickShowLinkMorePlans}
                          />
                        )}
                    </div>
                  </div>
                </section>
              </>
            )}
            <div>{isLoading && "Loading..."}</div>
            <div>{isError && "Error fetching data"}</div>
          </>
        )}
      </div>
    </>
  );
};

export default InternetPlans;
