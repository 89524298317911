import { useState, useEffect } from 'react';
import { EnumViewPlansType, IPlanFilterParamsInternet, IResponseInternetPlansDTO } from "../../contracts/types";
import { sortByPlansInternet } from '../../helpers/sortByPlansInternet';
import useInternetPlans from '../../hooks/api/useInternetPlans';
import { useGlobalState } from '../../state';

export default function useInternetPlansPagination(viewPlansType: EnumViewPlansType, pageNumber: number) {

	const [iPlanFilterParams] = useGlobalState('planFilterParams');
	const [iFilters] = useGlobalState('filters');

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [plans, setPlans] = useState<IResponseInternetPlansDTO[]>([]);
	const [hasMore, setHasMore] = useState(false);
	const [plansData, setPlansData] = useState<IResponseInternetPlansDTO[]>([]);
	const [finishCharge, setFinishCharge] = useState(false);

	const {
		data,
		isLoading,
		isError
	} = useInternetPlans(viewPlansType, '',0);

	const {
		data: dataATT_IPBB
	} = useInternetPlans(viewPlansType, 'AT&T_IPBB',0);

	// const {
	// 	data: data_AST
	// } = useInternetPlans(viewPlansType, 'AST_CB',0);

	const {
		data: dataATT_ADI_12
	} = useInternetPlans(viewPlansType, 'AT&T_ADI' ,12);

	const {
		data: dataATT_ADI_24
	} = useInternetPlans(viewPlansType, 'AT&T_ADI_24' ,24);

	const {
		data: dataATT_ADI_36
	} = useInternetPlans(viewPlansType, 'AT&T_ADI_36' ,36);

	useEffect(() => {
		validData();
	}, [data,dataATT_IPBB,dataATT_ADI_12,dataATT_ADI_24,dataATT_ADI_36]);

	useEffect(() => {
		setLoading(isLoading);
		setError(isError);
	}, [isLoading, isError]);

	useEffect(() => {
		let arrayPlans = plansData.filter(p => !p.planHidden);
		arrayPlans = sortByPlansInternet(arrayPlans, iFilters.sortBy);

		setPlans(arrayPlans.slice(0, pageNumber * 10));
		setHasMore(arrayPlans.length > pageNumber * 10);

		if (data && dataATT_IPBB && dataATT_ADI_12 && dataATT_ADI_24 && dataATT_ADI_36) {
			setFinishCharge(true);
		}
		else {
			setFinishCharge(false);
		}

	}, [plansData, pageNumber]);

	useEffect(() => {
		validData();
	}, [iPlanFilterParams, iFilters]);

	const validData = () => {
		if (data || dataATT_IPBB || dataATT_ADI_12 || dataATT_ADI_24 || dataATT_ADI_36) {
			let multiPlans = data?.lstResponseInternetPlansDTO ? data.lstResponseInternetPlansDTO : [];
			// multiPlans = multiPlans.concat(data_AST?.lstResponseInternetPlansDTO ? data_AST.lstResponseInternetPlansDTO : []);
			multiPlans = multiPlans.concat(dataATT_IPBB?.lstResponseInternetPlansDTO ? dataATT_IPBB.lstResponseInternetPlansDTO : []);
			multiPlans = multiPlans.concat(dataATT_ADI_12?.lstResponseInternetPlansDTO ? dataATT_ADI_12.lstResponseInternetPlansDTO : []);
			multiPlans = multiPlans.concat(dataATT_ADI_24?.lstResponseInternetPlansDTO ? dataATT_ADI_24.lstResponseInternetPlansDTO : []);
			multiPlans = multiPlans.concat(dataATT_ADI_36?.lstResponseInternetPlansDTO ? dataATT_ADI_36.lstResponseInternetPlansDTO : []);
			multiPlans = multiPlans.filter(p => !p.planHidden);
			//console.log(multiPlans);
			if (iFilters.showMoreLocations) {
				const internetParamsCurrent = iPlanFilterParams.multipleAddresses.multipleInternetParams.find((addr: IPlanFilterParamsInternet) => addr.address === iPlanFilterParams.internetParams.address);
				if (internetParamsCurrent) {
					setPlansData(multiPlans);
				}
			}
			else {
				if (data) {
					if (data?.address === '') {
						setPlansData(data.lstResponseInternetPlansDTO);
						return;
					}
					else if (iPlanFilterParams.internetParams.plansByCodeProvider) {
						setPlansData(multiPlans);
					}
				}
			}
		}
	};

	return { loading, error, plans, hasMore, finishCharge };
};
