import React, { useEffect, useState } from 'react';
import useAccountForgotPassword from '../../hooks/api/useAccountForgotPassword';

interface IForgotPasswordProps {

}

const ForgotPassword: React.FC<IForgotPasswordProps> = () => {
    const [valueEmail, setValueEmail] = useState('');
    const [valueSendEmail, setValueSendEmail] = useState('');

    const {
        data
    } = useAccountForgotPassword(valueSendEmail);

    useEffect(() => {
        if (data && data.isOk && data.message) {
            alert(data.message);
        }
    }, [data]);

    const onChangeEmail = (e: any) => {
        setValueEmail(e.currentTarget.value);
    };

    const onClickForgot = () => {
        if (valueEmail !== '') {
            setValueSendEmail(valueEmail);
        }
    };

    return (
        <>
            <div className="mb-3 row">
                <label htmlFor="email" className="col-sm-2 col-form-label">Email</label>
                <div className="col-sm-10">
                    <input
                        type="text"
                        id="email"
                        onChange={onChangeEmail}
                        value={valueEmail}
                        className="form-control"
                        placeholder="Email" />
                </div>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-primary mb-3" onClick={onClickForgot}>Forgot Password</button>
            </div>
            {
                data && !data.isOk && data.message !== '' &&
                <span className="labelQuestionFalse">{data.message}</span>
            }
        </>
    );
};

export default ForgotPassword;
