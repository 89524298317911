import React from 'react';
import parse from 'html-react-parser';
import { useGlobalState } from '../../../state';
import { EnumPropertyId, IResponseBundlePlansDTO, IResponseInternetPlansDTO, IResponseVoicePlansDTO } from './../../../contracts/types';

interface IComparePropertyProps {
    title: string;
    propertyCode: EnumPropertyId;
    plansDataCompare: IResponseInternetPlansDTO[] | IResponseVoicePlansDTO[] | IResponseBundlePlansDTO[];
}

const CompareProperty: React.FC<IComparePropertyProps> = ({ title, propertyCode, plansDataCompare }) => {
    const [iGlobals] = useGlobalState('globals');

    return (
        <tr>
            <th className="headcol-compare label-compareBi">{title}</th>
            {
                plansDataCompare.map((plan: any) => {
                    const productProperty = iGlobals.productProperties.find(pp => pp.productId === plan.productId && pp.propertyCode === propertyCode);
                    const value = productProperty ? (productProperty.valueLong !== '' ? productProperty.valueLong : productProperty.value) : '';

                    return <td className='compare-plan-space-td'>
                        {parse(value)}
                    </td>
                })
            }
        </tr>
    );
};

export default CompareProperty;
