import React, { useEffect } from 'react';
import { useGlobalState } from '../state';
import { GoogleLogout } from 'react-google-login';

interface ITestProps {

}

const Test: React.FC<ITestProps> = () => {
    const [iPlanFilterParams] = useGlobalState('planFilterParams');
    const [iSession, setISession] = useGlobalState('session');

    useEffect(() => {
        if (iSession.access_token === '') {
            window.open(iPlanFilterParams.baseUrl + 'account', "_self");
        }
    }, []);

    const onClickLogout = () => {
        setISession(
            {
                ...iSession,
                "access_token": "",
                "expires_in": 0,
                "refresh_token": "",
                "scope": "",
                "token_type": "",
                "isGoogle": false
            });

        window.open(iPlanFilterParams.baseUrl + 'account', "_self");
    };

    return (
        <div className="container squareOrderConfirmation">
            {
                iSession.access_token === ''
                    ? <></>
                    : <div className="align-middle" style={{ height: "100%" }}>
                        <h3>Welcome Test</h3>
                        {
                            iSession.isGoogle === false
                                ? <a href="javascript:void(0)" onClick={onClickLogout}>Logout</a>
                                : <GoogleLogout
                                    clientId="18658352516-9fmbnp9luhqee9nb7c4728es1glslbfb.apps.googleusercontent.com"
                                    buttonText="Logout"
                                    onLogoutSuccess={onClickLogout}
                                ></GoogleLogout>

                        }

                    </div>
            }
        </div>
    );
};

export default Test;