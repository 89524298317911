import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IResponseAccountDTO } from "../../contracts/types";

export default function useAccountLogin(username: string, password: string) {
	const api = useServiceApi();

	const doRequest = async (): Promise<IResponseAccountDTO> => {
		const params = new URLSearchParams()
		params.append('username', username);
		params.append('password', password);
		params.append('client_id', 'client3');
		params.append('grant_type', 'password');

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}

		const { data } = await api.post<IResponseAccountDTO>(`${ApiEndPoints.GetAccountLogin}`, params, config);
		
		return data;
	};

	return useQuery<IResponseAccountDTO>(
		['login', username, password],
		() => doRequest(), {
			staleTime: Infinity,
			retry: 0,
			enabled: username !== '' && password !== ''
	});
}
