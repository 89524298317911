import { useQuery } from 'react-query';
import { useServiceApi, ApiEndPoints } from '../../api';
import { IRequestClickToDialTBIDTO } from '../../contracts/types';

export default function useClickToDialSendTBI(requestSend?: IRequestClickToDialTBIDTO) {
    const api = useServiceApi();

    const doRequest = async (): Promise<any> => {
        const params = new URLSearchParams();
        params.append('F9domain', 'TBI');
        params.append('WireSurferClickToDialWebform', 'TBI');
        params.append('F9list', 'WireSurferClickToDialWebform');
        params.append('F9CallASAP', '1');
        params.append('F9key', 'number1');
        params.append('first_name', requestSend ? requestSend.firstName : '');
        params.append('last_name', requestSend ? requestSend.lastName : '');
        params.append('number1', requestSend ? requestSend.phone : '');
        // params.append('email', (requestSend ? requestSend.email : ''));

        const { data } = await api.post<any>(`${ApiEndPoints.SendDataTBIClickToDial}`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return data;
    };

    return useQuery<any>(['clickToDialSendTBI', requestSend?.firstName, requestSend?.lastName, requestSend?.phone, requestSend?.email], () => doRequest(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: requestSend !== undefined && requestSend.phone !== '',
    });
}
