import React from 'react';
import parse from 'html-react-parser';
import { EnumPropertyId, IResponseInternetPlansDTO } from '../../../contracts/types';
import LearnMorePropertyValue from './learnMorePropertyValue.com';

interface ILearnMorePropertyProps {
    title: string;
    propertyCode: EnumPropertyId;
    plan: IResponseInternetPlansDTO;
}

const LearnMoreProperty: React.FC<ILearnMorePropertyProps> = ({ title, propertyCode, plan }) => {

    return (
        <div className="col-md-7 my-3 p-5 bg-light rounded-3 shadow space-col-md-8">
            <div className="float-start learn-spacetitle">
                <span className="learn-textthisplan">{parse(title)}</span>
            </div>
            <div className="learn-spaceparagraph">
                <span className="learn-textparagraph">
                    <div className="text-scroll-seccion-light">
                        <LearnMorePropertyValue propertyCode={propertyCode} plan={plan} />
                    </div>
                </span>
            </div>
        </div>
    );
};

export default LearnMoreProperty;
