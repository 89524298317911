import React from 'react';
import { useGlobalState } from '../../../state';
import { EnumPlanType, IResponseBundlePlansDTO, IResponseInternetPlansDTO, IResponseVoicePlansDTO } from './../../../contracts/types';

interface ICompareTitleProps {
    plansDataCompare: IResponseInternetPlansDTO[] | IResponseVoicePlansDTO[] | IResponseBundlePlansDTO[];
}

const CompareTitle: React.FC<ICompareTitleProps> = ({ plansDataCompare }) => {
    const [iFilters, setIFilters] = useGlobalState('filters');

    const onChangeCompareCheckBox = (planId: number, codeProvider: string) => {
        if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET) {
            let plansCompare = iFilters.plansCompareInternet == null ? [] : iFilters.plansCompareInternet;
            plansCompare = plansCompare.filter(pc => {
                if (pc.codeProvider === codeProvider && pc.idPlan === planId) {
                    return 0;
                }

                return 1;
            });

            setIFilters({
                ...iFilters,
                plansCompareInternet: plansCompare,
            });
        }
        else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE) {
            let plansCompare = iFilters.plansCompareVoice == null ? [] : iFilters.plansCompareVoice;
            plansCompare = plansCompare.filter(idPlan => idPlan !== planId);

            setIFilters({
                ...iFilters,
                plansCompareVoice: plansCompare,
            });
        }
        else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
            let plansCompare = iFilters.plansCompareBundle == null ? [] : iFilters.plansCompareBundle;
            plansCompare = plansCompare.filter(pc => pc.idPlan !== planId);

            setIFilters({
                ...iFilters,
                plansCompareBundle: plansCompare,
            });
        }
    };

    return (
        <tr className="icon-closeCompare">
            <th>
                <div className="headcol-compare " />
            </th>
            {
                plansDataCompare.map((plan: any) => {
                    let title;
                    let planId = 0;
                    let codeProvider = '';

                    if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET) {
                        //title = `${plan.providerName} ${plan.planDownloadSpeed}Mbps ${plan.planTechnologyName} ${plan.planTerm}`;
                        title = `${plan.providerName}`;
                        planId = plan.planId;
                        codeProvider = plan.codeProvider;
                    }
                    else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE) {
                        //title = plan.serviceTypeName;
                        title = `${plan.providerName}`;
                        planId = plan.planId;
                    }
                    else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
                        //title = plan.planName;
                        title = `${plan.providerName}`;
                        planId = plan.planId;
                        codeProvider = plan.codeProvider;
                    }

                    return <th>
                        <i className="fa fa-times-circle-o  link-url" aria-hidden="true" onClick={() => onChangeCompareCheckBox(planId, codeProvider)} />
                        <div className="label-compareBiTtitle">{title}</div>
                    </th>
                })
            }
        </tr>
    );
};

export default CompareTitle;
