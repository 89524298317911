import { useQuery } from 'react-query';
import { useServiceApi, ApiEndPoints } from '../../api';
import { IPageSurveyDTO, IQuestionDTO, IRequestSurveyDTO, ISurveyDTO, ISurveyQuestionResponse } from '../../contracts/types';
import { useGlobalState } from '../../state';

export default function useSurvey(searchOrder: boolean, encryptOrder: string) {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters] = useGlobalState('filters');
    const [iAuditVisitor] = useGlobalState('auditVisitor');

    const api = useServiceApi();

    const doRequest = async (): Promise<ISurveyDTO> => {
        let arraySearchId: number[] = [];
        arraySearchId.push(iAuditVisitor.search.id);

        if (iPlanFilterParams?.multipleAddresses && iPlanFilterParams.idAddressMultiple !== -1) {
            arraySearchId = iAuditVisitor.searchMultiple.map((s) => s.id);
        }

        const request: IRequestSurveyDTO = {
            'search': searchOrder,
            'encryptOrderId': encryptOrder,
            'planTypeCode': iFilters.planTypeCode,
            'arrayVisitorSearchId': arraySearchId,
            'visitorId': iAuditVisitor.id
        };

        const { data } = await api.post<ISurveyDTO>(`${ApiEndPoints.CreateSurvey}`, request);

        let surveyData = iPlanFilterParams.surveyData;
        let surveyResponse = iPlanFilterParams.surveyResponse;

        surveyData.orderId = data.orderId;

        if (searchOrder) {
            let pages = data.pages;
            let arraySurveyQuestionResponse: ISurveyQuestionResponse[] = [];

            pages.forEach((page: IPageSurveyDTO) => {
                page.questionsDTO.forEach((questionDTO: IQuestionDTO) => {
                    arraySurveyQuestionResponse.push({ questionId: questionDTO.id, value: questionDTO.value, label: '', isValid: true });
                });
            });

            surveyData.encryptOrderId = data.encryptOrderId;
            surveyData.urlOrderSummary = `${iPlanFilterParams.baseUrl}quotesummary/?quote=${surveyData.encryptOrderId}`;
            surveyResponse.surveyQuestionsResponse = arraySurveyQuestionResponse;
            surveyResponse.orderId = surveyData.orderId;
        }

        surveyData.disabledTBI = data.disabledTBI;

        setIPlanFilterParams({
            ...iPlanFilterParams,
            'surveyData': surveyData,
            'surveyResponse': surveyResponse,
            'isClickCalendly': false,
        });

        return data;
    };

    return useQuery<ISurveyDTO>(['survey', encryptOrder], () => doRequest(), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: 1,
        enabled: iPlanFilterParams.surveyData.orderId === -1 && iPlanFilterParams.surveyData.isOpen && window.location.pathname.includes('quotenow'),
    });
}
