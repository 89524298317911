import React from 'react';
import { useGlobalState } from '../../../state';
import { EnumPlanType, IResponseBundlePlansDTO, IResponseInternetPlansDTO, IResponseVoicePlansDTO } from './../../../contracts/types';

interface ICompareTermProps {
    plansDataCompare: IResponseInternetPlansDTO[] | IResponseVoicePlansDTO[] | IResponseBundlePlansDTO[];
}

const CompareTerm: React.FC<ICompareTermProps> = ({ plansDataCompare }) => {
    const [iFilters] = useGlobalState('filters');

    return (
        <tr>
            <th className="headcol-compare label-compareBi">TERM</th>
            {
                plansDataCompare.map((plan: any) => {
                    let term;
                    if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET || iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
                        term = plan.planTerm;
                    }
                    else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE) {
                        term = plan.term;
                    }

                    return <td className='compare-plan-space-td'>
                        <div>
                            <span className="card-max-term">{term}</span>
                            <div className="voice-bolt">MONTHS</div>
                        </div>
                    </td>
                })
            }
        </tr>
    );
};

export default CompareTerm;
