import React from 'react';
import { useGlobalState } from '../../../state';
import LearnMoreInternet from './learnMoreInternet.comp';
import LearnMoreInternetMobile from './learnMoreInternetMobile.comp';

interface ILearnMoreProps {

}

const LearnMore: React.FC<ILearnMoreProps> = () => {
    const [iGlobals] = useGlobalState('globals');

    return (
        <>
            <div className='hide-desktop-learnmore'>
                {
                    iGlobals.planLearMore && <LearnMoreInternet plan={iGlobals.planLearMore} />
                }
            </div>
            <div className='hide-mobile-learnmore'>
                {
                    iGlobals.planLearMore && <LearnMoreInternetMobile plan={iGlobals.planLearMore} />
                }
            </div>
        </>
    );
};

export default LearnMore;
