import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { IRequestClickToDialTBIDTO } from "../../contracts/types";

export default function useClickToDialSendOrder(requestSend?: IRequestClickToDialTBIDTO) {

	const api = useServiceApi();

	const doRequest = async (): Promise<boolean> => {

		const { data } = await api.post<boolean>(`${ApiEndPoints.SendOrderClickToDial}`, requestSend);

		return data;
	};

	return useQuery<boolean>(
		['clickToDialSendOrder', requestSend?.firstName, requestSend?.lastName, requestSend?.phone, requestSend?.email],
		() => doRequest(), {
			staleTime: Infinity,
			cacheTime: Infinity,
			enabled: requestSend !== undefined && requestSend.phone !== ''
	});
}
