import { useQuery } from "react-query";
import { useServiceApi, ApiEndPoints } from "../../api"
import { EnumSearchType, IAuditVisitorSearchSortBy } from "../../contracts/types";
import { useGlobalState } from "../../state";

export default function useAuditSaveSortBy() {
	const [iAuditVisitor, setIAuditVisitor] = useGlobalState('auditVisitor');
	const api = useServiceApi();

	const doRequest = async (): Promise<IAuditVisitorSearchSortBy> => {
		const sortBy = iAuditVisitor.searchSortBy;
		let arraySearchId: number[] = [];
		arraySearchId.push(sortBy.visitorSearchId);

		if (iAuditVisitor.search.type === EnumSearchType.MULTIPLE) {
			arraySearchId = iAuditVisitor.searchMultiple.map(s => s.id);
		}

		sortBy.arraySearchsId = arraySearchId;
		
		const { data } = await api.post<IAuditVisitorSearchSortBy>(`${ApiEndPoints.GetAuditSaveSortBy}`, sortBy);

		setIAuditVisitor(
			{
				...iAuditVisitor,
				"searchSortBy": {
					...iAuditVisitor.searchSortBy,
					"id": data.id
				},

			});

		return data;
	};

	return useQuery<IAuditVisitorSearchSortBy>(
		['saveAuditSortBy'],
		() => doRequest(), {
			//staleTime: Infinity,
			enabled: iAuditVisitor.searchSortBy.id === -1 && iAuditVisitor.searchSortBy.visitorSearchId !== -1
		}
	);
}
