import React from 'react';
import { useGlobalState } from '../../../state';
import { EnumPlanType, IResponseBundlePlansDTO, IResponseInternetPlansDTO, IResponseVoicePlansDTO } from './../../../contracts/types';

interface IComparePriceProps {
    plansDataCompare: IResponseInternetPlansDTO[] | IResponseVoicePlansDTO[] | IResponseBundlePlansDTO[];
}

const ComparePrice: React.FC<IComparePriceProps> = ({ plansDataCompare }) => {
    const [iFilters] = useGlobalState('filters');

    return (
        <tr>
            <th className="headcol-compare label-compareBi">PRICE</th>
            {
                plansDataCompare.map((plan: any) => {
                    let price;
                    if (iFilters.planTypeCode === EnumPlanType.BUSINESS_INTERNET || iFilters.planTypeCode === EnumPlanType.BUSINESS_BUNDLES) {
                        price = plan.planPrice.toFixed(2);
                    }
                    else if (iFilters.planTypeCode === EnumPlanType.BUSINESS_VOICE_PHONE) {
                        price = plan.calculatedPrice.toFixed(2);
                    }

                    return <td className='compare-plan-space-td'>
                        <div>
                            <span className="card-price">${price}
                            </span>
                            <span className="voice-bolt">mo</span>
                        </div>
                    </td>
                })
            }
        </tr>
    );
};

export default ComparePrice;
