import React, { useEffect, useState } from "react";
import { useGlobalState } from "../../state";
import useCalculatorSend from "../../hooks/api/useCalculatorSend";
import useCalculatorSendOrder from "../../hooks/api/useCalculatorSendOrder";
import {
  EnumPlanTechnologyType,
  EnumPlanType,
  EnumViewPlansType,
  IPlanFilterParamsInternet,
  IRequestCalculatorOrderDTO,
  IRequestCalculatorSendDTO,
  IRequestInternetPlansDTO,
  IResponseInternetPlansDTO,
  ISmartyResponseDTO,
} from "../../contracts/types";
import CalculatorHeader from "./calculatorHeader.comp";
import CalculatorPlan from "./calculatorPlan.comp";
import AddressText from "../search/addressText.comp";

interface ICalculatorProps {}

const Calculator: React.FC<ICalculatorProps> = () => {
  const [iPlanFilterParams, setIPlanFilterParams] =
    useGlobalState("planFilterParams");
  const [iFilters, setIFilters] = useGlobalState("filters");
  const [iGlobals] = useGlobalState("globals");

  const [pageView, setPageView] = useState(0);
  const [planType, setPlanType] = useState("");
  const [numberEmployees, setNumberEmployees] = useState(0);
  const [use, setUse] = useState(0);
  const [service, setService] = useState(0);
  const [activity, setActivity] = useState(0);
  const [smartyResponseDTO, setSmartyResponseDTO] =
    useState<ISmartyResponseDTO>();
  const [requestSend, setRequestSend] = useState<IRequestCalculatorSendDTO>();
  const [requestSendOrder, setRequestSendOrder] =
    useState<IRequestCalculatorOrderDTO>();
  const [txtName, setTxtName] = useState("");
  const [txtEmail, setTxtEmail] = useState("");
  const [txtPhone, setTxtPhone] = useState("");
  const [txtCompany, setTxtCompany] = useState("");
  const [disabledSendAddress, setDisabledSendAddress] = useState(true);
  const [classSendAddress, setClassSendAddress] = useState(
    "cssCalcRectanguleDoneSendAddress"
  );

  const [class001, setClass001] = useState("Grupo-001");
  const [class002, setClass002] = useState("Grupo-002");

  const [class349, setClass349] = useState("Grupo-349");
  const [class350, setClass350] = useState("Grupo-350");
  const [class351, setClass351] = useState("Grupo-351");
  const [class352, setClass352] = useState("Grupo-352");
  const [class353, setClass353] = useState("Grupo-353");

  const [class361, setClass361] = useState("Grupo-361");
  const [class292, setClass292] = useState("Grupo-292");
  const [class589, setClass589] = useState("Grupo-589");

  const [class359, setClass359] = useState("Grupo-359");
  const [class563, setClass563] = useState("Grupo-563");

  const { data: dataSend } = useCalculatorSend(requestSend);

  const { data: dataSendOrder } = useCalculatorSendOrder(requestSendOrder);

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    if (dataSend) {
      if (dataSend.plans && dataSend.plans.length === 0) {
        let note =
          "A Wiresurfer telecom expert is standing by to assist from the over 220+ business telecom providers we represent.<br/>We are adding new telecom providers and Internet & Business Voice offers to the site every week.";

        const planWire: IResponseInternetPlansDTO = {
          planId: -1,
          productId: -1,
          internalPlanId: -1,
          planDownloadSpeed: 0,
          planFiberFastQualType: "",
          planInstallPrice: 0,
          planModemIncluded: false,
          planName: "wiresurfer",
          planNote: note,
          planPrice: 0,
          planRated: 0,
          planServiceTypeCode: "",
          planTechnologyCode: undefined,
          planTechnologyName: "",
          planTerm: 0,
          planTypeCode: undefined,
          planTypeName: "Wiresurfer Agent Available",
          displayName: "Wiresurfer Agent Available",
          planUploadSpeed: 0,
          providerId: -1,
          providerLogo: "logoWiresurferDummy.png",
          providerName: "Wiresurfer",
          planLatitude: 0,
          planLongitude: 0,
        };

        dataSend.plans.push(planWire);
      }

      setPageView(6);
    }
  }, [dataSend]);

  useEffect(() => {
    if (dataSendOrder) {
    }
  }, [dataSendOrder]);

  const openModal = () => {};

  const onClickPlanType = (pPlanType: EnumPlanType) => {
    setPlanType(pPlanType);

    setPageView(1);
  };

  const onClickEmployee = (nEmployees: number) => {
    setNumberEmployees(nEmployees);

    if (planType === EnumPlanType.BUSINESS_VOICE_PHONE) {
      setIFilters({ ...iFilters, planTypeCode: planType });

      setIPlanFilterParams({
        ...iPlanFilterParams,
        voiceParams: {
          ...iPlanFilterParams.voiceParams,
          filterNumber: nEmployees,
          filterTerm: [24],
        },
      });

      setTimeout(function () {
        window.open(iPlanFilterParams.baseUrl + "search", "_self");
      }, 100);
    } else {
      setPageView(2);
    }
  };

  const onClickUse = (pUse: number) => {
    setUse(pUse);

    setPageView(3);
  };

  const onClickService = (pService: number) => {
    setService(pService);

    setPageView(4);
  };

  const onClickActivity = (pActivity: number) => {
    setActivity(pActivity);

    setPageView(5);
  };

  const onClickSelectAddress = (pSmartyResponseDTO: ISmartyResponseDTO) => {
    setSmartyResponseDTO(pSmartyResponseDTO);
    setDisabledSendAddress(false);
    setClassSendAddress("cssCalcRectanguleDoneSendAddress");
  };

  const onClickSendAddress = () => {
    if (smartyResponseDTO && smartyResponseDTO.street_line !== "") {
      const requestPlansDTO: IRequestInternetPlansDTO = {
        codeProvider: "",
        term: 0,
        streetLine: smartyResponseDTO.street_line,
        city: smartyResponseDTO.city,
        zipCode: smartyResponseDTO.zipcode,
        address: "",
        state: smartyResponseDTO.state,
        country: "US",
        viewPlansType: EnumViewPlansType.SHORT,
        lstCarriersId: iGlobals.carriers.map((c) => c.id),
        lstProductsId: iGlobals.productProperties.map((c) => c.productId),
      };

      const request: IRequestCalculatorSendDTO = {
        requestInternetPlans: requestPlansDTO,
        employees: numberEmployees,
        use: use,
        service: service,
        activity: activity,
      };

      setRequestSend(request);
    }
  };

  const onClickSendOrder = () => {
    if (smartyResponseDTO) {
      const requestPlansDTO: IRequestInternetPlansDTO = {
        codeProvider: "",
        term: 0,
        streetLine: smartyResponseDTO.street_line,
        city: smartyResponseDTO.city,
        zipCode: smartyResponseDTO.zipcode,
        address: "",
        state: smartyResponseDTO.state,
        country: "US",
        viewPlansType: EnumViewPlansType.SHORT,
        lstCarriersId: iGlobals.carriers.map((c) => c.id),
        lstProductsId: iGlobals.productProperties.map((c) => c.productId),
      };

      const request: IRequestCalculatorOrderDTO = {
        requestInternetPlans: requestPlansDTO,
        name: txtName,
        email: txtEmail,
        phone: txtPhone,
        company: txtCompany,
        zipCode: smartyResponseDTO.zipcode,
      };

      setRequestSendOrder(request);
    }
  };

  const onClickShowMore = () => {
    if (smartyResponseDTO && dataSend) {
      const entries =
        smartyResponseDTO.entries > 1
          ? ` (${smartyResponseDTO.entries} more entries)`
          : "";
      const addressDetail = `${smartyResponseDTO.street_line}, ${
        smartyResponseDTO.secondary !== ""
          ? `${smartyResponseDTO.secondary}${entries}, `
          : ""
      }${smartyResponseDTO.city}, ${smartyResponseDTO.state} ${
        smartyResponseDTO.zipcode
      }`;

      let newAddress: IPlanFilterParamsInternet = {
        streetLine: smartyResponseDTO.street_line,
        city: smartyResponseDTO.city,
        zipCode: smartyResponseDTO.zipcode,
        address: `${smartyResponseDTO.street_line} ${smartyResponseDTO.city}`,
        addressDetail: addressDetail,
        state: smartyResponseDTO.state,
        secondary: smartyResponseDTO.secondary,
        latitude: 0,
        longitude: 0,
        country: "US",
        idMultiple: 0,
        openedGroupAddressPlans: false,
        smartyAddress: smartyResponseDTO,
        plansByCodeProvider: [],
        plan: {
          planId: 0,
          productId: 0,
          internalPlanId: 0,
          planName: "",
          planServiceTypeCode: "",
          planTypeCode: EnumPlanType.BUSINESS_INTERNET,
          planTypeName: "",
          displayName: "",
          providerId: 0,
          providerName: "",
          providerLogo: "",
          planRated: 0,
          planFiberFastQualType: "",
          planTechnologyCode: EnumPlanTechnologyType.CABLE,
          planTechnologyName: "",
          planDownloadSpeed: 0,
          planUploadSpeed: 0,
          planTerm: 0,
          planModemIncluded: false,
          planPrice: 0,
          planInstallPrice: 0,
          planLatitude: 0,
          planLongitude: 0,
          planNote: "",
          planHidden: false,
          planServiceTypeName: "",
          learnMore: "",
          codeProvider: "",
        },
      };

      setIFilters({
        ...iFilters,
        filterTerm: [24],
        filterSpeed: {
          min: dataSend.downloadSpeed,
          max: dataSend.downloadSpeed * 2,
        },
      });

      setIPlanFilterParams({
        ...iPlanFilterParams,
        smartyAddressInternet: smartyResponseDTO,
        smartyAddressBundle: null,
        internetParams: newAddress,
      });

      setTimeout(function () {
        window.open(iPlanFilterParams.baseUrl + "search", "_self");
      }, 100);
    }
  };

  const onChangeName = (e: any) => {
    setTxtName(e.currentTarget.value);
  };

  const onChangeEmail = (e: any) => {
    setTxtEmail(e.currentTarget.value);
  };

  const onChangePhone = (e: any) => {
    setTxtPhone(e.currentTarget.value);
  };

  const onChangeCompany = (e: any) => {
    setTxtCompany(e.currentTarget.value);
  };

  const onMouseOver = (className: string) => {
    if (className === "Grupo-001") {
      setClass001(`${className}-on`);
    } else if (className === "Grupo-002") {
      setClass002(`${className}-on`);
    } else if (className === "Grupo-349") {
      setClass349(`${className}-on`);
    } else if (className === "Grupo-350") {
      setClass350(`${className}-on`);
    } else if (className === "Grupo-351") {
      setClass351(`${className}-on`);
    } else if (className === "Grupo-352") {
      setClass352(`${className}-on`);
    } else if (className === "Grupo-353") {
      setClass353(`${className}-on`);
    } else if (className === "Grupo-361") {
      setClass361(`${className}-on`);
    } else if (className === "Grupo-292") {
      setClass292(`${className}-on`);
    } else if (className === "Grupo-589") {
      setClass589(`${className}-on`);
    } else if (className === "Grupo-359") {
      setClass359(`${className}-on`);
    } else if (className === "Grupo-563") {
      setClass563(`${className}-on`);
    }
  };

  const onMouseOut = (className: string) => {
    if (className === "Grupo-001") {
      setClass001(`${className}`);
    } else if (className === "Grupo-002") {
      setClass002(`${className}`);
    } else if (className === "Grupo-349") {
      setClass349(className);
    } else if (className === "Grupo-350") {
      setClass350(`${className}`);
    } else if (className === "Grupo-351") {
      setClass351(`${className}`);
    } else if (className === "Grupo-352") {
      setClass352(`${className}`);
    } else if (className === "Grupo-353") {
      setClass353(`${className}`);
    } else if (className === "Grupo-361") {
      setClass361(`${className}`);
    } else if (className === "Grupo-292") {
      setClass292(`${className}`);
    } else if (className === "Grupo-589") {
      setClass589(`${className}`);
    } else if (className === "Grupo-359") {
      setClass359(`${className}`);
    } else if (className === "Grupo-563") {
      setClass563(`${className}`);
    }
  };

  return (
    <>
      <div className="formulary">
        {pageView === 0 && (
          <div className="page-0">
            <CalculatorHeader currentPage={pageView} />

            <p>
              <span className="cssCalculatorSubtitlePage">
                WHAT TYPE OF SERVICE ARE YOU LOOKING FOR?
              </span>
            </p>

            <div className="cssRectangulesPlanType">
              <div
                className="cssRectangulePlanType"
                onClick={() => onClickPlanType(EnumPlanType.BUSINESS_INTERNET)}
                onMouseOver={() => onMouseOver("Grupo-001")}
                onMouseOut={() => onMouseOut("Grupo-001")}
              >
                <div className={class001}></div>
                <span className="cssPlanType">Internet</span>
              </div>

              <div
                className="cssRectangulePlanType"
                onClick={() =>
                  onClickPlanType(EnumPlanType.BUSINESS_VOICE_PHONE)
                }
                onMouseOver={() => onMouseOver("Grupo-002")}
                onMouseOut={() => onMouseOut("Grupo-002")}
              >
                <div className={class002}></div>
                <span className="cssPlanType">Voice</span>
              </div>
            </div>
          </div>
        )}
        {pageView === 1 && (
          <div className="page-1">
            <CalculatorHeader currentPage={pageView} />

            <span className="cssCalculatorTitlePage">
              {planType === EnumPlanType.BUSINESS_VOICE_PHONE ? (
                <>How many seats do you need?</>
              ) : (
                <>How many employees will be using your business internet?</>
              )}
            </span>

            <p>
              <span className="cssCalculatorSubtitlePage">
                {planType === EnumPlanType.BUSINESS_INTERNET && (
                  <>
                    <span className="text-style-1">Our Wiresurfer Tip: </span>
                    Remember to consider lesser used devices like security
                    systems, mesh networks, etc., in your business — every
                    connection counts.
                  </>
                )}
              </span>
            </p>

            <div className="cssRectangulesEmployees">
              <div
                className="cssRectanguleEmployee"
                onClick={() => onClickEmployee(10)}
                onMouseOver={() => onMouseOver("Grupo-349")}
                onMouseOut={() => onMouseOut("Grupo-349")}
              >
                <div className={class349}></div>
                <span className="cssNumberEmployees">1 - 10</span>
              </div>

              <div
                className="cssRectanguleEmployee"
                onClick={() => onClickEmployee(50)}
                onMouseOver={() => onMouseOver("Grupo-350")}
                onMouseOut={() => onMouseOut("Grupo-350")}
              >
                <div className={class350}></div>
                <span className="cssNumberEmployees">11 - 50</span>
              </div>

              <div
                className="cssRectanguleEmployee"
                onClick={() => onClickEmployee(200)}
                onMouseOver={() => onMouseOver("Grupo-351")}
                onMouseOut={() => onMouseOut("Grupo-351")}
              >
                <div className={class351}></div>
                <span className="cssNumberEmployees">51 - 200</span>
              </div>

              <div
                className="cssRectanguleEmployee"
                onClick={() => onClickEmployee(500)}
                onMouseOver={() => onMouseOver("Grupo-352")}
                onMouseOut={() => onMouseOut("Grupo-352")}
              >
                <div className={class352}></div>
                <span className="cssNumberEmployees">201 - 500</span>
              </div>

              <div
                className="cssRectanguleEmployee"
                onClick={() => onClickEmployee(1000)}
                onMouseOver={() => onMouseOver("Grupo-353")}
                onMouseOut={() => onMouseOut("Grupo-353")}
              >
                <div className={class353}></div>
                <span className="cssNumberEmployees">+ 501</span>
              </div>
            </div>
          </div>
        )}
        {pageView === 2 && (
          <div className="page-2">
            <CalculatorHeader currentPage={pageView} />

            <span className="cssCalculatorTitlePage">
              How often do you video conference and use VoIP?
            </span>
            <p>
              <span className="cssCalculatorSubtitlePage">
                Think about the time you and your teams spend on platforms such
                as
                <span className="text-style-1">
                  {" "}
                  Facetime, Zoom, Microsoft Teams or Google Hangouts.
                </span>
              </span>
            </p>

            <div className="cssRectangulesUses">
              <div
                className="cssRectanguleUse"
                onClick={() => onClickUse(1)}
                onMouseOver={() => onMouseOver("Grupo-361")}
                onMouseOut={() => onMouseOut("Grupo-361")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-361.svg`}
                  className={class361}
                />
                <span className="cssCalcUseText">Daily</span>
              </div>

              <div
                className="cssRectanguleUse"
                onClick={() => onClickUse(0.5)}
                onMouseOver={() => onMouseOver("Grupo-292")}
                onMouseOut={() => onMouseOut("Grupo-292")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-292.svg`}
                  className={class292}
                />
                <span className="cssCalcUseText">Weekly</span>
              </div>

              <div
                className="cssRectanguleUse"
                onClick={() => onClickUse(0.25)}
                onMouseOver={() => onMouseOver("Grupo-589")}
                onMouseOut={() => onMouseOut("Grupo-589")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-589.svg`}
                  className={class589}
                />
                <span className="cssCalcUseText">Rarely</span>
              </div>
            </div>
          </div>
        )}
        {pageView === 3 && (
          <div className="page-3">
            <CalculatorHeader currentPage={pageView} />

            <span className="cssCalculatorTitlePage">
              How often do you use cloud services?
            </span>
            <p>
              <span className="cssCalculatorSubtitlePage">
                Think about the frequency in which you use platforms such as
                <span className="text-style-1">
                  {" "}
                  OneDrive, Google Drive, ICloud.
                </span>
              </span>
            </p>

            <div className="cssRectangulesUses">
              <div
                className="cssRectanguleUse"
                onClick={() => onClickService(1)}
                onMouseOver={() => onMouseOver("Grupo-361")}
                onMouseOut={() => onMouseOut("Grupo-361")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-361.svg`}
                  className={class361}
                />
                <span className="cssCalcUseText">Daily</span>
              </div>

              <div
                className="cssRectanguleUse"
                onClick={() => onClickService(0.5)}
                onMouseOver={() => onMouseOver("Grupo-292")}
                onMouseOut={() => onMouseOut("Grupo-292")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-292.svg`}
                  className={class292}
                />
                <span className="cssCalcUseText">Weekly</span>
              </div>

              <div
                className="cssRectanguleUse"
                onClick={() => onClickService(0.25)}
                onMouseOver={() => onMouseOver("Grupo-589")}
                onMouseOut={() => onMouseOut("Grupo-589")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-589.svg`}
                  className={class589}
                />
                <span className="cssCalcUseText">Rarely</span>
              </div>
            </div>
          </div>
        )}
        {pageView === 4 && (
          <div className="page-4">
            <CalculatorHeader currentPage={pageView} />

            <span className="cssCalculatorTitlePage">
              What is most important for your business activities?
            </span>
            <p>
              <span className="cssCalculatorSubtitlePage">
                Some people prefer to set apart a bigger budget for higher
                speeds, while others might have a tighter budget to stick to.
              </span>
            </p>

            <div className="cssRectangulesActivities">
              <div
                className="cssRectanguleActivity"
                onClick={() => onClickActivity(1)}
                onMouseOver={() => onMouseOver("Grupo-359")}
                onMouseOut={() => onMouseOut("Grupo-359")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-359.svg`}
                  className={class359}
                />
                <span className="cssCalcActivityText">Price</span>
              </div>

              <div
                className="cssRectanguleActivity"
                onClick={() => onClickActivity(3)}
                onMouseOver={() => onMouseOver("Grupo-563")}
                onMouseOut={() => onMouseOut("Grupo-563")}
              >
                <img
                  src={`${iPlanFilterParams.imagesUrl}/calculator/grupo-563.svg`}
                  className={class563}
                />
                <span className="cssCalcActivityText">Speed</span>
              </div>
            </div>
          </div>
        )}
        {pageView === 5 && (
          <div className="page-5">
            <div className="cssCalcRectanguleDone">
              <span className="cssCalculatorTitlePage">We’re almost done!</span>

              <p>
                <span className="cssCalcRectanguleDoneSubTitle">
                  We’ll just need one last detail so we can calculate
                  <span className="text-style-1"> the best options </span>
                  for your area.
                </span>
              </p>

              <div className="cssCalcRectanguleDoneDivAddress">
                <AddressText
                  isMultipleAddress={false}
                  placeHolder="Business Address"
                  isBillingAddress={true}
                  onClickSelectAddressParent={onClickSelectAddress}
                />
                <button
                  type="button"
                  className={classSendAddress}
                  onClick={onClickSendAddress}
                  disabled={disabledSendAddress}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}
        {pageView === 6 && (
          <div className="page-6">
            <div className="cssCalcResult">
              <span className="cssCalcResultTitle">Here are your results!</span>

              <p>
                <span className="cssCalcResultSubtitle1">
                  Which bandwidth works best for your business?
                </span>
              </p>

              <p>
                <span className="cssCalcResultSubtitle2">We recommend:</span>
                &nbsp;
                <span className="cssCalcResultSubtitle2-2">
                  {dataSend &&
                    (dataSend.downloadSpeed < 1000
                      ? dataSend.downloadSpeed
                      : dataSend.downloadSpeed / 1000)}
                  {dataSend &&
                    (dataSend.downloadSpeed < 1000 ? "Mbps" : "Gbps")}
                </span>
              </p>

              <div className="container">
                <div className="contentThirdListPrices">
                  <div className="contentThirdListPrices">
                    <div className="row ThirdListPrices">
                      {dataSend && dataSend.plans.length === 0 && (
                        <div className="noResultFound">
                          No providers available. But don't fret, we can still
                          help by calling us at (toll-free #). We are busy
                          loading new providers into our platform every week.
                        </div>
                      )}
                      {dataSend &&
                        dataSend.plans.map(
                          (plan: IResponseInternetPlansDTO, index: number) => {
                            if (index >= 3) {
                              return <></>;
                            }

                            return (
                              <CalculatorPlan key={plan.planId} plan={plan} />
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="cssCalcThanksShowMore">
              <button
                type="button"
                className="cssCalcThanksShowMoreButton"
                onClick={onClickShowMore}
              >
                Show more
              </button>
            </div>

            <div className="cssCalcThanks">
              <span className="cssCalculatorTitlePage">
                What is most important for your business activities?
              </span>

              <p>
                <span className="cssCalcThanksSubtitle">
                  If you'd like to know which telecom plan works best for your
                  business bandwidth requirements,
                  <span className="text-style-1"> we’ll gladly help. </span>
                  <p>We´ll just need the following details:</p>
                </span>
              </p>

              <div className="cssCalcThanksDiv">
                <input
                  type="text"
                  placeholder="Name"
                  className="cssCalcThanksText"
                  value={txtName}
                  onChange={onChangeName}
                />
                <input
                  type="email"
                  placeholder="Email"
                  className="cssCalcThanksText"
                  value={txtEmail}
                  onChange={onChangeEmail}
                />
                <input
                  type="phone"
                  placeholder="Phone"
                  className="cssCalcThanksText"
                  value={txtPhone}
                  onChange={onChangePhone}
                />
                <input
                  type="text"
                  placeholder="Company"
                  className="cssCalcThanksText"
                  value={txtCompany}
                  onChange={onChangeCompany}
                />
                <button
                  type="button"
                  className="cssCalcThanksSend"
                  onClick={onClickSendOrder}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Calculator;
