import React, { useEffect } from 'react';
import { IPlanFilterParamsInternet, IPlansCompare, IResponseInternetPlansDTO, IPlansCompareMultiple } from '../../../contracts/types';
import { redirectCompare } from '../../../helpers/redirectPages';
import { useGlobalState } from '../../../state';

interface IGroupPlanItemProps {
    idMultiple: number;
    plan: IResponseInternetPlansDTO;
    internetParams: IPlanFilterParamsInternet;
    onChangeTotalPrice: (price: number) => void;
}

const GroupPlanItem: React.FC<IGroupPlanItemProps> = ({ idMultiple, plan, internetParams, onChangeTotalPrice }) => {
    const [iPlanFilterParams, setIPlanFilterParams] = useGlobalState('planFilterParams');
    const [iFilters, setIFilters] = useGlobalState('filters');

    useEffect(() => {
        calculateTotals();
    }, [iPlanFilterParams.multipleAddresses.multipleInternetParams]);

    const onChangeCompareCheckBox = (e: any, internetParams: IPlanFilterParamsInternet, plan: IResponseInternetPlansDTO) => {
        let addressCompare = iFilters.plansCompareMultiple.find(p => p.address === internetParams.address);

        if (!addressCompare) {
            addressCompare = {
                address: internetParams.address,
                plansCompare: []
            };
        }

        if (addressCompare) {
            let plansCompare: IPlansCompare[] = addressCompare?.plansCompare == null ? [] : addressCompare?.plansCompare;

            if (e.target.checked) {
                const planComp: IPlansCompare = {
                    idPlan: plan.planId,
                    codeProvider: plan.codeProvider,
                    planName: `${plan.providerName} ${plan.planDownloadSpeed} ${plan.planTechnologyName} ${plan.planTerm}mo`,
                    planPrice: plan.planPrice
                };

                plansCompare.push(planComp);
            }
            else {
                plansCompare = plansCompare.filter(pc => {
                    if (pc.codeProvider === plan.codeProvider && pc.idPlan === plan.planId) {
                        return 0;
                    }

                    return 1;
                });
            }

            addressCompare.plansCompare = plansCompare ? plansCompare : [];
            const addressCompareMinus = iFilters.plansCompareMultiple.filter(p => p.address !== internetParams.address);

            if (addressCompare.plansCompare.length > 0) {
                addressCompareMinus.push(addressCompare);
            }

            setIFilters({
                ...iFilters,
                plansCompareMultiple: addressCompareMinus,
            });

        }
    }

    const onClickPlan = (plan: IResponseInternetPlansDTO, pIdMultiple: number) => {
        const arrayMultiple = iPlanFilterParams.multipleAddresses.multipleInternetParams;

        let paramsInternetSelect = arrayMultiple.find(paramsInternet => paramsInternet.idMultiple === pIdMultiple);
        if (paramsInternetSelect) {
            paramsInternetSelect.plan = plan;
        }

        setIPlanFilterParams({
            ...iPlanFilterParams,
            multipleAddresses: {
                ...iPlanFilterParams.multipleAddresses,
                multipleInternetParams: arrayMultiple
            }
        });

        calculateTotals();
    }

    const onClickCompareLink = (e: any, internetParams: IPlanFilterParamsInternet) => {
        const plansCompareMulti = iFilters.plansCompareMultiple.find(pc => pc.address === internetParams.address);

        if (plansCompareMulti) {
            setIPlanFilterParams({
                ...iPlanFilterParams,
                'internetParams': { ...internetParams },
                idAddressMultiple: internetParams.idMultiple
            });

            setIFilters({
                ...iFilters,
                plansCompareInternet: plansCompareMulti.plansCompare,
            });

            redirectCompare(iPlanFilterParams.baseUrl);
        }
    };

    const calculateTotals = () => {
        let price = 0;
        iPlanFilterParams.multipleAddresses.multipleInternetParams.forEach(paramInternet => {
            if (paramInternet.plan) {
                price += paramInternet.plan.planPrice;
            }
        });

        onChangeTotalPrice(price);
    };

    return (
        <tr key={idMultiple} className={internetParams.plan ? (internetParams.plan.internalPlanId === plan.internalPlanId && internetParams.plan.providerId === plan.providerId ? 'cssPlanSelectedMultiple' : '') : ''}>
            <td>
                <button className="buttonSelectPlanGroupAddress" onClick={() => onClickPlan(plan, idMultiple)}>Choose this</button>
            </td>
            <td>
                {
                    plan.providerLogo === 'logoWiresurferDummy.png'
                        ? <img className="logoGropAddress" src={`${iPlanFilterParams.imagesUrl}/${plan.providerLogo}`} alt="" />
                        : <img className="logoGropAddress" src={`data:image/jpeg;base64,${plan.providerLogo}`} alt=""></img>


                }
            </td>
            {
                plan.internalPlanId !== -1
                    ? <>
                        <td>{plan.planDownloadSpeed < 1000 ? plan.planDownloadSpeed : plan.planDownloadSpeed / 1000} {plan.planDownloadSpeed < 1000 ? 'Mbps' : 'Gbps'}</td>
                        <td>{plan.planTerm} Months</td>
                        <td><span>$</span> {plan.planPrice.toFixed(2)} {plan.planTechnologyCode}</td>
                        <td>
                            {
                                plan.learnMore && plan.learnMore !== '' &&
                                <a
                                    className="hrefViewLearnCardGroup"
                                    href={plan.learnMore}
                                    target="_blank"
                                >Learn More</a>
                            }
                        </td>
                        <td>
                            <input type="checkbox" value="compare" checked={iFilters.plansCompareMultiple?.find(p => p.address === internetParams.address)?.plansCompare?.find(pc => pc.idPlan === plan.planId && pc.codeProvider === plan.codeProvider) ? true : false} onChange={(e: any) => onChangeCompareCheckBox(e, internetParams, plan)} />
                            <span> </span>
                            {iFilters.plansCompareMultiple.filter(p => p.address === internetParams.address).length >= 1 ? <a className="aCompareInternet" href="javascript:void(0)" onClick={(e: any) => onClickCompareLink(e, internetParams)}>compare offers</a> : <>compare offers</>}
                        </td>
                    </>
                    : <td colSpan={5}>{plan.planTypeName}</td>
            }
        </tr>
    );
};

export default GroupPlanItem;